import { ForwardsTransactionsFilters, InstructionsTransactionsFilters } from '@grain/liquidity-service-types';
import { OrganizationType } from '@grain/core-types';
import { BaseService } from '@grain/web-utils';

export default class RiskService extends BaseService {
  private static instance: RiskService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new RiskService(baseUrl, handleError);
  }

  public static getInstance(): RiskService {
    return this.instance;
  }

  async getForwardsTransactionsFilters(organizationType: OrganizationType = OrganizationType.Live): Promise<ForwardsTransactionsFilters> {
    return this.authenticatedCall({
      url: `risk/forwards-transactions-filters`,
      method: 'GET',
      params: { organizationType }
    });
  }

  async getInstructionsTransactionsFilters(
    organizationType: OrganizationType = OrganizationType.Live,
    partnerId: string
  ): Promise<InstructionsTransactionsFilters> {
    return this.authenticatedCall({
      url: `risk/forwards-instruction-transactions-filters`,
      method: 'GET',
      params: { partnerId, organizationType }
    });
  }
}
