import { useQuery } from '@tanstack/react-query';
import { queries } from '../queries';
import { PaymentBeneficiaryType } from '@grain/core-types';

export const usePaymentBeneficiaries = (partnerId: string, types?: PaymentBeneficiaryType[]) => {
  const {
    data: paymentBeneficiaries,
    isLoading: isLoadingPaymentBeneficiaries,
    isError: isErrorLoadingPaymentBeneficiaries
  } = useQuery(queries.paymentBeneficiaries.list(partnerId, types));

  return {
    paymentBeneficiaries,
    isLoadingPaymentBeneficiaries,
    isErrorLoadingPaymentBeneficiaries
  };
};
