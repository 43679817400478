const EMAIL_VALIDATION_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;

export function isEmailValid(email: string): boolean {
  return EMAIL_VALIDATION_REGEXP.test(email);
}

export function parseEmail(email: string): string {
  return email.toLowerCase().trim();
}

export function stringifyEmail(email: string): string {
  return encodeURIComponent(email);
}
