import { createColumnHelper, Table, usePagination, useTable } from '@grain/web-components/table-v2';
import { useWallets } from '../hooks';
import { AdminConsoleApiPartner, AdminConsoleApiWallet } from '@grain/admin-console-api-types';
import { useState } from 'react';
import { PartnerWalletDetailsDialog } from './PartnerWalletDetailsDialog';
import { FunctionalCurrencyChip } from './FunctionalCurrencyChip';
import { PartnerWalletsSectionProps } from './PartnerWalletsSection';
import { Tag } from '@grain/web-components/Tag/Tag';
import { paymentTypeDisplayName } from '@grain/web-utils';
import { asAmount } from '@grain/web-components/utils/formatting';
import { isNil } from 'lodash';

export const PartnerWalletsTable = (props: PartnerWalletsTableProps) => {
  const { wallets, isLoadingWallets } = useWallets(props.partner.id);
  const { paginationProps } = usePagination({
    initialPageSize: 5
  });

  const [walletDetailsDialogState, setWalletDetailsDialogState] = useState<WalletDetailsDialogState>({
    activeWallet: null,
    isRowDialogOpen: false
  });

  const { table } = useTable(
    {
      data: wallets || [],
      columns: getColumns(props)
    },
    paginationProps
  );

  return (
    <>
      <Table
        table={table}
        loading={isLoadingWallets}
        onRowClick={(row) =>
          setWalletDetailsDialogState({
            activeWallet: row.original,
            isRowDialogOpen: true
          })
        }
      />

      <PartnerWalletDetailsDialog
        wallet={walletDetailsDialogState.activeWallet}
        open={walletDetailsDialogState.isRowDialogOpen}
        onCancel={() =>
          setWalletDetailsDialogState({
            activeWallet: null,
            isRowDialogOpen: false
          })
        }
      />
    </>
  );
};

export type PartnerWalletsTableProps = {
  partner: AdminConsoleApiPartner;
};

interface WalletDetailsDialogState {
  activeWallet: AdminConsoleApiWallet | null;
  isRowDialogOpen: boolean;
}

const columnHelper = createColumnHelper<AdminConsoleApiWallet>();

const getColumns = (params: PartnerWalletsSectionProps) => {
  return [
    columnHelper.accessor('currency', {
      header: 'Currency',
      cell: (info) => {
        const currencyToDisplay = info.getValue();

        return (
          <div className="flex flex-row gap-1.5 items-center w-10">
            <span>{currencyToDisplay}</span>

            {currencyToDisplay === params.partner.config.functionalCurrency && <FunctionalCurrencyChip />}
          </div>
        );
      }
    }),
    columnHelper.accessor('accountNumber', {
      header: 'Account number'
    }),
    columnHelper.accessor('withdrawConfigured', {
      header: 'Withdraw beneficiary',
      cell: (info) => (info.getValue() ? 'Configured' : '-')
    }),
    columnHelper.accessor('paymentTypes', {
      header: 'Payment methods',
      cell: (info) => (
        <div className="flex flex-row flex-wrap gap-1.5">
          {info.getValue().map((paymentType) => (
            <Tag key={paymentType} value={paymentTypeDisplayName(paymentType)} />
          ))}
        </div>
      )
    }),
    columnHelper.accessor('balance', {
      header: 'Balance',
      cell: (info) => (isNil(info.getValue()) ? 'N/A' : asAmount(info.getValue()!))
    })
  ];
};
