import { notifyError } from '@grain/web-components/notifications/toasters';
import { BaseService } from '@grain/web-utils';

export default class ToolsService extends BaseService {
  private static instance: ToolsService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new ToolsService(baseUrl, handleError);
  }

  public static getInstance(): ToolsService {
    return this.instance;
  }

  async getTransactionsFile(partnerId: string): Promise<void> {
    try {
      await this.downloadableFileCall({
        url: `tools/download-transactions-file`,
        method: 'GET',
        params: { partnerId }
      });
    } catch (e) {
      notifyError('No transactions file found for this partner');
    }
  }
}
