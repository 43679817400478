import { BaseDialogProps } from '@grain/web-components/dialogs/BaseDialog';
import React, { ReactElement } from 'react';
import { GrainCurrencyPairWithProviders, Provider } from './types';
import './CurrencyPairDetailsDialog.scss';
import { GrainCurrencyPairProviderDetails, Partner } from '@grain/admin-console-api-types';
import { HealthStatusChip } from '@grain/web-components/health-status-chip/HealthStatusChip';
import { prettifyProviderName } from './prettify-provider-name';
import { useGrainCurrencyPairPartners } from 'hooks';
import { Separator } from '@grain/web-components/separator/Separator';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@grain/web-components/dialogs/Dialog';

export interface CurrencyPairDetailsDialogProps extends Pick<BaseDialogProps, 'onCancel' | 'open'> {
  currencyPair?: GrainCurrencyPairWithProviders | null;
}

export function CurrencyPairDetailsDialog(props: CurrencyPairDetailsDialogProps): ReactElement {
  const { currencyPair, open, onCancel } = props;

  const { currencyPairPartners } = useGrainCurrencyPairPartners(currencyPair?.id);

  const { providers } = currencyPair || {};

  return (
    <Dialog open={open} onOpenChange={onCancel}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{`From ${currencyPair?.fromCurrency} to ${currencyPair?.toCurrency}`}</DialogTitle>
        </DialogHeader>
        <div className="currency-pair-details-dialog-content">
          <Providers providers={providers?.hedgeSpotRate || []} title="Quote Spot rate" />
          <Providers providers={providers?.hedgeForwardPoint || []} title="Quote Forward points" />
          <Providers providers={providers?.hedgeForwardOrder || []} title="Forward order" />
          <Separator />
          <Partners partners={currencyPairPartners || []} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

interface ProvidersProps<P extends Provider> {
  providers: GrainCurrencyPairProviderDetails<P>[];
  title: string;
}

function Providers<P extends Provider>(props: ProvidersProps<P>): ReactElement {
  return (
    <div className="providers-container">
      <span className="providers-title">{props.title}</span>
      <div className="providers-row">
        {props.providers.map((provider) => (
          <HealthStatusChip key={provider.provider} status={provider.status} text={prettifyProviderName(provider.provider)} />
        ))}
      </div>
    </div>
  );
}

interface PartnersProps {
  partners: Partner[];
}

function Partners(props: PartnersProps): ReactElement {
  return (
    <div className="partners-container">
      <span className="partners-title">Partners</span>
      <div className="partners-row">{props.partners.map((partner) => partner.name).join(', ')}</div>
    </div>
  );
}
