import { createColumnHelper, Table, usePagination, useTable } from '@grain/web-components/table-v2';
import { AdminConsoleApiPartner } from '@grain/admin-console-api-types';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Partner } from '@grain/core-types';

type PartnersTableProps = {
  partners: AdminConsoleApiPartner[];
  loading?: boolean;
};

const columnHelper = createColumnHelper<Partner>();

const columns = [columnHelper.accessor('name', { header: 'Name' }), columnHelper.accessor('id', { header: 'ID' })];

export default function PartnersTable({ partners }: PartnersTableProps) {
  const navigate = useNavigate();

  const { paginationProps } = usePagination();

  const { table } = useTable(
    {
      columns,
      data: partners
    },
    paginationProps
  );

  useEffect(() => {
    table.resetPageIndex(true);
  }, []);

  return (
    <div className="partners-table-container">
      <Table onRowClick={(row) => navigate(`/partners/${row.getValue('id')}`)} table={table} />
    </div>
  );
}
