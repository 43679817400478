import './DetailsCard.scss';
import { Link } from 'react-router-dom';
import './DetailsCardValues.scss';
import React, { ReactElement } from 'react';

export default function DetailsCardValues({ values }: DetailsCardValuesProps) {
  return (
    <div className="details-card-values-container">
      {values.map((value) => (
        <div className="detail" key={`detail-card-value-${value.key || value.title}`}>
          {value.title && <div className="title">{value.title}</div>}
          {value.link ? (
            <Link className="value link" to={value.link}>
              {value.content}
            </Link>
          ) : (
            <div className="value">{value.content}</div>
          )}
        </div>
      ))}
    </div>
  );
}

export type DetailsCardValuesProps = {
  values: DetailsCardValue[];
};

export type DetailsCardValue = DetailsCardValueWithTitle | DetailsCardValueWithKey;

interface DetailsCardValueWithKey extends DetailsCardValueBase {
  key: string;
  title?: never;
}

interface DetailsCardValueWithTitle extends DetailsCardValueBase {
  key?: never;
  title: string;
}

interface DetailsCardValueBase {
  content?: string | number | ReactElement | null;
  link?: string;
}
