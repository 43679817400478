import React, { useCallback, useEffect, useState } from 'react';
import Page from '../layout/Page';
import SectionCard from '@grain/web-components/layout/SectionCard';
import { RepeatCircle } from 'iconsax-react';
import colors from '@grain/web-components/styles/colors.scss';
import SchedulerJobsTable from './SchedulerJobsTable';
import JobSchedulerService from '../../services/JobSchedulerService';
import { AdminConsoleBasicJob, AdminConsoleExtendedJob } from '@grain/job-scheduler-types';
import ManagedFiltersForm from '@grain/web-components/filters/ManagedFiltersForm';

interface SchedulerJobsFiltersState {
  name?: string;
}

const INITIAL_FILTERS_STATE: SchedulerJobsFiltersState = {};

export default function SchedulerJobsPage() {
  const [jobs, setJobs] = useState<AdminConsoleExtendedJob[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [filtersState, setFiltersState] = useState<SchedulerJobsFiltersState>(INITIAL_FILTERS_STATE);
  const [jobDropdownOptions, setJobDropdownOptions] = useState<AdminConsoleBasicJob[]>([]);

  const getJobs = useCallback(async (init?: boolean, { name }: SchedulerJobsFiltersState = INITIAL_FILTERS_STATE) => {
    const loadingSetterFn = init ? setIsLoading : setIsTableLoading;

    loadingSetterFn(true);

    try {
      const jobs = await JobSchedulerService.getInstance().getAllJobs();

      const filteredJobs = name ? jobs.filter((job) => job.name === name) : jobs;

      setJobs(filteredJobs);

      if (init) {
        setJobDropdownOptions(jobs);
        setFiltersState(INITIAL_FILTERS_STATE);
      }
    } finally {
      loadingSetterFn(false);
    }
  }, []);

  useEffect(() => {
    (async function () {
      await getJobs(true);
    })();
  }, [getJobs]);

  return (
    <Page className="scheduler-jobs-page" title="Scheduler Jobs" subtitle="Manage Grain Scheduler Jobs">
      <SectionCard title="Scheduled Jobs" icon={<RepeatCircle />} iconBackgroundColor={colors.secondaryYellow} loading={isLoading}>
        <ManagedFiltersForm
          filtersData={filtersState}
          setFiltersData={setFiltersState}
          onFilterClicked={getJobs}
          options={jobDropdownOptions}
          filters={[{ label: 'Name', key: 'name' }]}
        />
        <SchedulerJobsTable jobs={jobs} multipleJobs loading={isTableLoading} />
      </SectionCard>
    </Page>
  );
}
