import React, { ChangeEvent, useState } from 'react';
import BaseInput from '@grain/web-components/forms/BaseInput';
import './CreateCounterpartyDialog.scss';
import FormDialog from '@grain/web-components/dialogs/FormDialog';
import { ChangePartnerTransferMatePasswordPayload } from '@grain/payments-service-types';

export default function ChangeTransferMatePasswordDialog(props: CreateCounterpartyDialogProps) {
  const { dialogTitle, open, onChangeTransferMatePasswordRequested, onClose } = props;
  const [data, setData] = useState<ChangePartnerTransferMatePasswordPayload>({
    transferMatePassword: ''
  });
  const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => setData({ ...data, [name]: value });

  const canSubmit = !!data.transferMatePassword;

  const onSubmit = async () => {
    await onChangeTransferMatePasswordRequested(data);
  };

  return (
    <FormDialog
      title={dialogTitle}
      open={open}
      canSubmit={canSubmit}
      onSubmit={onSubmit}
      className="change-transfer-mate-password-dialog-container"
      onClose={onClose}
    >
      <BaseInput name="transferMatePassword" labelText="TransferMate password" onChange={onChange} hideErrorText type="password" small />
    </FormDialog>
  );
}

type CreateCounterpartyDialogProps = {
  dialogTitle: string;
  open: boolean;
  onChangeTransferMatePasswordRequested: (payload: ChangePartnerTransferMatePasswordPayload) => Promise<void>;
  onClose?: () => void;
};
