import BaseDialog, { BaseDialogHandle } from '@grain/web-components/dialogs/BaseDialog';
import React, { useRef } from 'react';
import './ReportManualForwardsDialog.scss';
import UploadSection from '@grain/web-components/sections/UploadSection';
import ForwardsService from '../../services/ForwardsService';
import CTAButton from '@grain/web-components/buttons/CTAButton';

export default function ReportManualForwardsDialog() {
  const dialogRef = useRef<BaseDialogHandle>(null);
  const hedgesFileChosen = () => {
    dialogRef.current?.hideDialog();
  };

  return (
    <BaseDialog
      ref={dialogRef}
      trigger={<CTAButton text="Report Manual Forwards booked" />}
      contentClassName="report-manual-forwards-dialog-container"
    >
      <div className="report-manual-forwards-dialog-content-container">
        <UploadSection
          onFileUploaded={hedgesFileChosen}
          uploadFile={(file) => ForwardsService.getInstance().uploadManualForwardsBooked(file)}
        />
      </div>
    </BaseDialog>
  );
}
