import { PaymentBeneficiary, PaymentBeneficiaryType } from '@grain/core-types';
import { BaseService } from '@grain/web-utils';

export default class PaymentBeneficiariesService extends BaseService {
  private static instance: PaymentBeneficiariesService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new PaymentBeneficiariesService(baseUrl, handleError);
  }

  public static getInstance(): PaymentBeneficiariesService {
    return this.instance;
  }

  async syncPaymentBeneficiaries(params: { partnerId: string }): Promise<void> {
    await this.authenticatedCall({
      url: 'payment-beneficiaries/sync',
      params,
      method: 'GET'
    });
  }

  async getPaymentBeneficiaries(params: { partnerId: string; type?: PaymentBeneficiaryType[] }): Promise<PaymentBeneficiary[]> {
    const res: { paymentBeneficiaries: PaymentBeneficiary[] } = await this.authenticatedCall({
      url: 'payment-beneficiaries/list',
      params,
      method: 'GET'
    });

    return res.paymentBeneficiaries;
  }
}
