import { HedgeSettlementType } from '@grain/admin-console-api-types';
import BaseDialog, { BaseDialogHandle } from '@grain/web-components/dialogs/BaseDialog';
import React, { useRef, useState } from 'react';
import BaseDropdown, { BaseDropdownOption } from '@grain/web-components/forms/BaseDropdown';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { isNil } from 'lodash';
import { Separator } from '@grain/web-components/separator/Separator';

export const AddCurrencyPairDialog = (props: AddCurrencyPairDialogProps) => {
  const { onPairAddition, pairOptions, triggerComponent } = props;
  const dialogRef = useRef<BaseDialogHandle>(null);
  const [selectedPair, setSelectedPair] = useState<string>();
  const [selectedSettlementType, setSelectedSettlementType] = useState<HedgeSettlementType>(HedgeSettlementType.Offset);
  const canSave = !isNil(selectedPair) && !isNil(selectedSettlementType);

  const handlePairAdditionRequest = () => {
    if (!canSave) {
      return;
    }

    onPairAddition(selectedPair, selectedSettlementType);
    resetDialog();
    dialogRef.current?.hideDialog();
  };

  const resetDialog = () => {
    setSelectedPair(undefined);
    setSelectedSettlementType(HedgeSettlementType.Offset);
  };

  return (
    <BaseDialog ref={dialogRef} trigger={triggerComponent}>
      <div className="w-96 h-80 flex flex-col items-center justify-between gap-4">
        <span className="mr-auto -mt-7 text-light-grey">Add Currency Pair</span>

        <Separator />

        <BaseDropdown
          labelText="Currency Pair"
          options={pairOptions}
          value={selectedPair || ''}
          hideErrorText
          small
          onChange={(e) => setSelectedPair(e.target.value)}
        />

        <BaseDropdown
          labelText="Settlement Type"
          value={selectedSettlementType || ''}
          options={Object.entries(HedgeSettlementType)
            .filter(([_key, value]) => value !== HedgeSettlementType.Cycle)
            .map(([key, value]) => ({ text: key, value }))}
          onChange={(e) => setSelectedSettlementType(e.target.value as HedgeSettlementType)}
          hideErrorText
          small
        />

        <CTAButton className="mr-auto" text="Save" disabled={!canSave} onClick={handlePairAdditionRequest} />
      </div>
    </BaseDialog>
  );
};

type AddCurrencyPairDialogProps = {
  triggerComponent: React.ReactNode;
  pairOptions: BaseDropdownOption[];
  onPairAddition: (pair: string, settlementType: HedgeSettlementType) => void;
};
