import { BaseFundTransfer } from '@grain/core-types';
import { convertISOStringsToDates } from '@grain/formatters';
import { BaseService } from '@grain/web-utils';

export default class FundTransfersService extends BaseService {
  private static instance: FundTransfersService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new FundTransfersService(baseUrl, handleError);
  }

  public static getInstance(): FundTransfersService {
    return this.instance;
  }

  async getFundTransfers(): Promise<BaseFundTransfer[]> {
    const { fundTransfers }: FundTransfersResponse = await this.authenticatedCall({
      url: 'fund-transfers',
      method: 'GET'
    });
    return convertISOStringsToDates(fundTransfers);
  }

  async persistFundTransfers(): Promise<void> {
    await this.authenticatedCall({
      url: 'fund-transfers/persist',
      method: 'POST'
    });
  }
}

interface FundTransfersResponse {
  fundTransfers: BaseFundTransfer[];
}
