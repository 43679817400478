import { useMutation, useQueryClient } from '@tanstack/react-query';
import PartnersService, { EnableTransferMateParams } from 'services/PartnersService';
import { queries } from '../../../../queries';
import { notifySuccess } from '@grain/web-components/notifications/toasters';

export const useEnableTransferMate = () => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, isSuccess, mutateAsync } = useMutation<void, Error, EnableTransferMateParams>({
    mutationFn: (params) => PartnersService.getInstance().enableTransferMateForPartner(params),
    onSuccess: async () => {
      notifySuccess('TransferMate enabled successfully');
      await queryClient.refetchQueries(queries.partner.detail._def);
    },
    retry: 1
  });

  return {
    enabledTransferMateSuccessfully: isSuccess,
    failedToEnableTransferMate: isError,
    enableTransferMate: mutateAsync,
    isEnablingTransferMate: isLoading,
    enableTransferMateError: error
  };
};
