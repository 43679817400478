import React, { PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';
import './Chip.scss';

export type ChipProps = PropsWithChildren<{
  className?: string;
  disabled?: boolean;
  text?: string;
  textClassName?: string;
}>;

export function Chip(props: ChipProps): ReactElement {
  const { text } = props;

  return (
    <div
      className={classNames(
        'chip',
        {
          'chip-disabled': props.disabled
        },
        props.className
      )}
    >
      {props.children || (text && <ChipText className={props.textClassName} text={text} />)}
    </div>
  );
}

export interface ChipTextProps {
  className?: string;
  text: string;
}

export function ChipText(props: ChipTextProps): ReactElement {
  return <span className={classNames('chip-text', props.className)}>{props.text}</span>;
}
