import { AUTH_TOKEN_INDICATOR, BaseService, clearSession } from './BaseService';
import { SignupParams } from '../store';

function setAuthToken(authToken: string): void {
  return localStorage.setItem(AUTH_TOKEN_INDICATOR, authToken);
}

export default class AuthenticationService extends BaseService {
  private static instance: AuthenticationService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new AuthenticationService(baseUrl, handleError);
  }

  public static getInstance(): AuthenticationService {
    return this.instance;
  }

  async signUpRequest(params: SignupParams): Promise<void> {
    const res: TokenResponse = await this.call({
      url: 'auth/basic/signup',
      data: params,
      method: 'POST'
    });
    setAuthToken(res.token);
  }

  // Endpoints
  async loginRequest(email: string, password: string, recoveryToken?: string): Promise<void> {
    const res: TokenResponse = await this.call({
      url: 'auth/basic/login',
      data: { email, password, token: recoveryToken },
      method: 'POST'
    });
    setAuthToken(res.token);
  }

  async forgotPasswordRequest(email: string): Promise<string> {
    const res: { message: string } = await this.call({
      url: 'auth/basic/password/forgot',
      data: { email },
      method: 'POST'
    });
    return res.message;
  }

  async finishGoogleLoginRequest(oauthQueryString: string): Promise<void> {
    const res: TokenResponse = await this.call({
      url: `auth/google/callback?${oauthQueryString}`,
      method: 'GET',
      withCredentials: true
    });
    setAuthToken(res.token);
  }

  async logoutRequest(): Promise<void> {
    await this.authenticatedCall({ url: 'auth/logout' });
    clearSession();
  }
}

interface TokenResponse {
  token: string;
}
