import { useQuery } from '@tanstack/react-query';
import { queries } from 'queries';

export function useGrainCurrencyPairPartners(id: string | undefined) {
  const { data, isError, isLoading } = useQuery({
    ...queries.settings.grainCurrencyPairs.partners(id),
    enabled: Boolean(id)
  });

  return {
    currencyPairPartners: data,
    isErrorLoadingCurrencyPairPartners: isError,
    isLoadingCurrencyPairPartners: isLoading
  };
}
