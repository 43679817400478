import CTAButton from '@grain/web-components/buttons/CTAButton';
import React, { ChangeEvent, useRef, useState } from 'react';
import BaseDialog, { BaseDialogHandle } from '@grain/web-components/dialogs/BaseDialog';
import { Separator } from '@grain/web-components/separator/Separator';
import BaseInput from '@grain/web-components/forms/BaseInput';
import { DashboardRole } from '@grain/core-types';
import BaseDropdown from '@grain/web-components/forms/BaseDropdown';
import './InvitePartnerUserDialog.scss';
import { getDashboardRoleNameByRole } from '@grain/web-utils';

export default function EditPartnerUserDialog(props: EditUserDialogParams) {
  const [editUserState, setEditUserState] = useState<EditUserData>(props.userData);
  const [selectedRole, setSelectedRole] = useState<DashboardRole>(props.userData.role);
  const [error, setError] = useState<string>('');
  const dialogRef = useRef<BaseDialogHandle>(null);

  const onDialogClose = () => {
    setError('');
    props.onClose();
  };

  const onSubmit = async () => {
    try {
      await props.onEdit(editUserState.id, editUserState.firstName, editUserState.lastName, selectedRole, editUserState.partnerId);
      onDialogClose();
    } catch (e) {
      setError(e.message);
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditUserState({ ...editUserState, [name]: value });
  };

  const canSubmit = !!editUserState.firstName && !!editUserState.lastName && !!editUserState.role;

  return (
    <BaseDialog ref={dialogRef} open={props.open} onCancel={onDialogClose} contentClassName={'align-all-fields'}>
      <div className="w-[28rem] h-96 flex flex-col items-center justify-between gap-4">
        <span className="mr-auto -mt-7 text-light-grey">Edit User</span>

        <Separator />

        <BaseInput
          name="firstName"
          labelText="First Name"
          value={editUserState.firstName}
          onChange={onInputChange}
          sideLabel={true}
          placeholder={'Enter First Name'}
          hideErrorText
          small
        />

        <BaseInput
          name="lastName"
          labelText="Last Name"
          value={editUserState.lastName}
          onChange={onInputChange}
          sideLabel={true}
          placeholder={'Enter Last Name'}
          hideErrorText
          small
        />

        <BaseDropdown
          labelText="Role"
          value={selectedRole}
          options={Object.values(DashboardRole).map((value) => ({ text: getDashboardRoleNameByRole(value), value }))}
          onChange={(e) => setSelectedRole(e.target.value as DashboardRole)}
          sideLabel={true}
          hideErrorText
          small
        />

        <BaseInput
          name="email"
          labelText="Email"
          value={editUserState.email}
          onChange={onInputChange}
          sideLabel={true}
          placeholder={'example@email.com'}
          disabled
          hideErrorText
          small
        />

        <Separator />

        <div className={'flex flex-row items-center w-full'}>
          <CTAButton className="mr-auto" text="Save" disabled={!canSubmit} onClick={onSubmit} loading={props.isLoading} size={'small'} />
          <div className="text-sm text-red">{error}</div>
        </div>
      </div>
    </BaseDialog>
  );
}

type EditUserData = {
  id: string;
  firstName: string;
  lastName: string;
  role: DashboardRole;
  partnerId: string;
  email: string;
};

type EditUserDialogParams = {
  userData: EditUserData;
  onEdit: (id: string, firstName: string, lastName: string, role: DashboardRole, partnerId: string) => void;
  onClose: () => void;
  isLoading: boolean;
  open: boolean;
};
