import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { format, parse } from 'date-fns';
import { DateWithoutTime } from '@grain/core-types';
import { HttpError, RequestCancelledError } from './exceptions';

export async function httpCall<ResponsePayloadType = unknown, RequestPayloadType = unknown>(
  params: AxiosRequestConfig<RequestPayloadType>,
  errorCallback?: (error: Error) => void
): Promise<AxiosResponse<ResponsePayloadType, RequestPayloadType>> {
  const { url, headers, ...payload } = params;
  try {
    return await axios({
      url,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      ...payload
    });
  } catch (error) {
    const isRequestCancelledError = axios.isCancel(error);
    if (isRequestCancelledError) {
      throw new RequestCancelledError();
    }

    if (axios.isAxiosError(error)) {
      const errorData = error.response?.data;
      if (errorData) {
        const { message, ...extraParams } = errorData;
        throw new HttpError(error.response?.status, message, extraParams);
      }
    }

    console.log('Error in httpCall', {
      error,
      stringifiedError: JSON.stringify(error)
    });
    errorCallback?.(error);

    throw new HttpError(-1, 'An error has occurred');
  }
}

export function queryParamAsBool(queryParam?: string): boolean {
  return queryParam?.toLowerCase() === 'true';
}

/**
 * Converts a date to the API format used in our Partners-API
 * @param date
 * @return GrainDateFormat - 'YYYY-MM-DD' (can be found in partner-api types)
 */
export function convertToApiDate(date?: Date): string | null {
  if (!date) {
    return null;
  }

  return format(toUTCDate(date), 'yyyy-MM-dd');
}

export function parseApiDate(apiDate: string | null | undefined): DateWithoutTime | undefined {
  if (!apiDate) {
    return undefined;
  }

  try {
    return parse(`${apiDate}Z`, 'yyyy-MM-ddX', new Date());
  } catch {
    return undefined;
  }
}

export function parseApiDateOrThrow(apiDate: string | null | undefined): DateWithoutTime | undefined {
  const parsedApiDate = parseApiDate(apiDate);

  if (!parsedApiDate) {
    return undefined;
  }

  if (isNaN(parsedApiDate.getTime())) {
    throw new HttpError(400, `Invalid date: ${apiDate}`);
  }

  return parsedApiDate;
}

function toUTCDate(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );
}

export function dateToEpochSeconds(date?: Date): number | undefined {
  if (!date) {
    return undefined;
  }
  return Math.floor(date.getTime() / 1000);
}

export function filterNullValues<T>(dict: Partial<T>): Partial<T> {
  return Object.fromEntries(Object.entries(dict).filter(([, value]) => value != null)) as Partial<T>;
}

export * from './mapping';

export function parseIntOrThrow(numberString: string, paramName?: string): number {
  const num = parseInt(numberString, 10);
  if (Number.isNaN(num)) {
    throw new HttpError(400, `${paramName || 'parameter'} must be a number`);
  }
  return num;
}

export function encodeConnectionStringPassword(url: string): string {
  const passwordStart = getNthIndexOf(url, ':', 2) + 1;
  const passwordEnd = url.lastIndexOf('@');
  const encodedPassword = encodeURIComponent(url.substring(passwordStart, passwordEnd));

  return url.substring(0, passwordStart) + encodedPassword + url.substring(passwordEnd);
}

function getNthIndexOf(string: string, subString: string, n: number): number {
  return string.split(subString, n).join(subString).length;
}

export function validateCurrencyString(currencyString: string) {
  const currencyRegExp = /^[a-zA-Z][a-zA-Z][a-zA-Z]$/;
  const valid = currencyRegExp.test(currencyString);
  if (!valid) {
    throw new HttpError(400, `Received invalid currency: ${currencyString}`);
  }
}
