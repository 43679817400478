import { Logout } from 'iconsax-react';
import React from 'react';
import './BaseSideBar.scss';
import './SignOutItem.scss';

export default function SignOutItem(props: SignOutItemProps) {
  const { onSignOut } = props;

  return (
    <div className="sign-out-container" onClick={onSignOut}>
      <Logout size="16" />
      <div className="sign-out-button">Sign Out</div>
    </div>
  );
}

type SignOutItemProps = {
  onSignOut: () => void;
};
