import React from 'react';
import './HedgeStatusBadge.scss';
import type { HedgeStatus } from '@grain/web-api-types';
import StatusBadge from './StatusBadge';
import { getStatusDisplayName } from './utils';

export default function HedgeStatusBadge({ status }: HedgeStatusBadgeProps) {
  return status ? <StatusBadge className="hedge-status-badge-container" status={getStatusDisplayName(status)} /> : <></>;
}

type HedgeStatusBadgeProps = {
  status: HedgeStatus;
};
