import React from 'react';
import BasePage from '@grain/web-components/layout/BasePage';
import TopBar from './TopBar';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../store/userStore';

export default function Page(props: PageProps) {
  const { title, subtitle, className, loading, children } = props;
  const isAuthenticated = useSelector(userSelectors.isAuthenticated);

  return (
    <BasePage className={className} loading={loading} topBar={isAuthenticated && <TopBar title={title} subtitle={subtitle} />}>
      {children}
    </BasePage>
  );
}

export type PageProps = {
  title?: string;
  subtitle?: string;
  className?: string;
  loading?: boolean;
  children: React.ReactNode;
};
