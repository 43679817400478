import React from 'react';
import './RowExpansion.scss';
import { Row } from '@tanstack/react-table';

export default function RowExpansion<D>(props: RowExpansionProps<D>) {
  const { row, renderSubComponent } = props;
  return (
    <>
      {/* We need to add two trs because a single tr will cause all rows to be changed from even to odd, which causes color change */}
      <tr className="empty-expansion-table-row" />
      <tr className="expansion-table-row">
        <td colSpan={row.getVisibleCells().length}>{renderSubComponent?.(row.original)}</td>
      </tr>
    </>
  );
}

type RowExpansionProps<D> = {
  row: Row<D>;
  renderSubComponent?: (row: D) => React.ReactNode;
};
