import { HttpError } from './http-error';

export abstract class BaseError extends Error {
  constructor(
    message: string,
    public loggedErrorMessage?: string
  ) {
    super(message);
    this.loggedErrorMessage = loggedErrorMessage;
  }

  /** returns a detailed error message that will be logged in the application logs */
  get loggedError(): string | undefined {
    return this.loggedErrorMessage;
  }
}

export abstract class BaseHttpError extends BaseError {
  constructor(message: string) {
    super(message);
  }
  abstract asHttpError(): HttpError;
}
