import { AdminConsoleApiWallet } from '@grain/admin-console-api-types';
import { KeyValuePair } from '@grain/web-components/key-value-pair/KeyValuePair';
import { Separator } from '@grain/web-components/separator/Separator';
import { camelCaseToSentenceCase } from '@grain/formatters';
import { paymentTypeDisplayName } from '@grain/web-utils';

export const PartnerWalletDetailsGeneralSection = (props: PartnerWalletDetailsGeneralSectionProps) => {
  const { wallet } = props;
  const detailsToDisplaySortedByKeys = [['currency', wallet.currency], ...Object.entries(wallet.routingInfo)]
    .map(([key, value]) => [camelCaseToSentenceCase(key), value])
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

  return (
    <>
      <p className="text-light-grey text-sm font-bold mr-auto mb-5">General</p>

      <div className="grid grid-flow-col grid-rows-[repeat(6,min-content)] grid-cols-2 gap-y-6 gap-x-8">
        {detailsToDisplaySortedByKeys.map(([key, value]) => (
          <KeyValuePair key={key} title={key} value={value} />
        ))}
      </div>

      <Separator className="my-6" />

      <KeyValuePair className="mr-auto" title="Supported payment types" value={wallet.paymentTypes.map(paymentTypeDisplayName)} />
    </>
  );
};

export type PartnerWalletDetailsGeneralSectionProps = {
  wallet: AdminConsoleApiWallet;
};
