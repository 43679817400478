import './FundTransferStatusBadge.scss';
import StatusBadge from '@grain/web-components/badges/StatusBadge';
import { BaseFundTransfer } from '@grain/core-types';
import React from 'react';
import { capitalizeFirstLetter } from '@grain/formatters';

export default function FundTransferStatusBadge(props: FundTransferStatusBadgeProps) {
  const { fundTransfer } = props;

  return <StatusBadge className="fund-transfer-status-badge-container" status={capitalizeFirstLetter(fundTransfer.status)} />;
}

type FundTransferStatusBadgeProps = {
  fundTransfer: BaseFundTransfer;
};
