import React, { useCallback, useEffect, useState } from 'react';
import HedgesReportsByStartDateDialog from './HedgesReportsByStartDateDialog';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import ReportsService from '../../services/ReportsService';
import ReportsTable, { ReportFile } from './ReportsTable';
import Page from '../layout/Page';
import { Profile2User } from 'iconsax-react';
import colors from '@grain/web-components/styles/colors.scss';
import SectionCard from '@grain/web-components/layout/SectionCard';
import './ReportsManagementPage.scss';
import { notifyError, notifySuccess } from '@grain/web-components/notifications/toasters';

export type LoadingPerRow = { [rowNumber: number]: boolean };

export default function ReportsManagementPage() {
  const [reportFiles, setReportFiles] = useState<ReportFile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingUpcomingHedges, setLoadingUpcomingHedges] = useState<boolean>(false);
  const [loadingDownloadReportFile, setLoadingDownloadReportFile] = useState<LoadingPerRow>({} as LoadingPerRow);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const refreshReports = useCallback(async () => {
    setLoading(true);
    try {
      const { reports } = await ReportsService.getInstance().getReportsRequest();
      setReportFiles(reports);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await refreshReports();
    })();
  }, [refreshReports]);

  const downloadReportFile = useCallback(async (reportFile: ReportFile, rowNumber: number) => {
    setLoadingDownloadReportFile((loadingDownloadReportFile) => ({
      ...loadingDownloadReportFile,
      [rowNumber]: true
    }));
    try {
      await ReportsService.getInstance().downloadReportFile(reportFile.filename);
    } catch (err) {
      setErrorMessage(`Failed download report file: ${err.message}`);
    } finally {
      setLoadingDownloadReportFile((loadingDownloadReportFile) => ({
        ...loadingDownloadReportFile,
        [rowNumber]: false
      }));
    }
  }, []);

  const generateUpcomingHedgesReport = async () => {
    setLoadingUpcomingHedges(true);
    try {
      await ReportsService.getInstance().generateUpcomingHedgesReport();
      notifySuccess('Upcoming hedges report is being generated. You will receive a slack message when the report is ready.');
    } catch (e) {
      notifyError(`Failed to generate upcoming hedges report: ${e.message}`);
    } finally {
      setLoadingUpcomingHedges(false);
      await refreshReports();
    }
  };

  return (
    <Page className="reports-management-page-container" title="Reports Management">
      <div className="reports-generations-buttons-container">
        <HedgesReportsByStartDateDialog onSubmit={refreshReports} />
        <CTAButton
          text="Generate upcoming hedges reports"
          disabled
          onClick={generateUpcomingHedgesReport}
          loading={loadingUpcomingHedges}
        />
      </div>
      <SectionCard
        className="reports-section"
        title="Reports"
        icon={<Profile2User />}
        iconBackgroundColor={colors.secondaryPurple}
        loading={loading}
      >
        <ReportsTable
          reportsFiles={reportFiles}
          onDownloadClicked={downloadReportFile}
          loading={loading}
          onDownloadLoading={loadingDownloadReportFile}
        />
      </SectionCard>
      {errorMessage && <label className="error-label">{errorMessage}</label>}
    </Page>
  );
}
