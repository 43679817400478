import React, { ComponentType, ReactElement } from 'react';
import classNames from 'classnames';
import { IconProps } from 'iconsax-react';
import colors from '../styles/colors.scss';
import './IconicButton.scss';

export interface IconicButtonProps {
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  icon: ComponentType<IconProps>;
  onClick?: () => void;
  text: string;
  iconSize?: string;
}

export function IconicButton(props: IconicButtonProps): ReactElement {
  const { iconSize = '14px' } = props;

  return (
    <button className={classNames('iconic-button', props.className)} onClick={props.onClick} type="button">
      <span className="iconic-button-icon">
        <props.icon color={colors.mainBlue} size={iconSize} />
      </span>
      <span className="iconic-button-text">{props.text}</span>
    </button>
  );
}
