import React, { InputHTMLAttributes } from 'react';
import { RowSelectionRow, Table } from '@tanstack/react-table';

export const HeaderCheckbox = React.memo(function HeaderCheckbox({ table }: { table: Table<any> }) {
  return (
    <IndeterminateCheckbox
      checked={table.getIsAllRowsSelected()}
      indeterminate={table.getIsAllRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
    />
  );
});

export const RowCheckbox = React.memo(function RowCheckbox({ row }: { row: RowSelectionRow }) {
  return (
    <IndeterminateCheckbox
      checked={row.getIsSelected()}
      indeterminate={row.getIsSomeSelected()}
      onChange={row.getToggleSelectedHandler()}
    />
  );
});

function IndeterminateCheckbox(props: IndeterminateCheckboxProps) {
  const { indeterminate, ...otherProps } = props;
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    ref.current.indeterminate = indeterminate;
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} {...otherProps} />;
}

interface IndeterminateCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  indeterminate: boolean;
}
