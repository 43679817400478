import React from 'react';
import './BaseDatePicker.scss';
import BaseField from './BaseField';
import BaseInput, { BaseInputProps } from './BaseInput';
import classNames from 'classnames';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function BaseDatePicker(props: BaseDatePickerProps) {
  const { name, value, minDate, maxDate, onValueChange, small, allowPastDates, ...baseFieldProps } = props;

  const onChange = (newText: string | null) => {
    onValueChange(name, newText || '');
  };

  return (
    <div className={classNames('base-date-picker-container')}>
      <BaseField name={name} {...baseFieldProps}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={value}
            minDate={minDate?.toDateString()}
            maxDate={maxDate?.toDateString()}
            disablePast={!allowPastDates}
            closeOnSelect
            onChange={onChange}
            className={classNames('field-element base-date-picker', { small })}
            renderInput={(params) => <BaseInput hideErrorText internalTextFieldParams={params} small={small} />}
          />
        </LocalizationProvider>
      </BaseField>
    </div>
  );
}

interface BaseDatePickerProps extends BaseInputProps {
  name: string;
  minDate?: Date;
  maxDate?: Date;
  onValueChange: (name: string, value: string) => void;
  allowPastDates?: boolean;
}
