import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import PartnersService from '../services/PartnersService';

export const partnersQueries = createQueryKeyStore({
  wallets: {
    list: (partnerId: string) => {
      return {
        queryKey: ['partnerId', partnerId],
        queryFn: () => PartnersService.getInstance().getWallets(partnerId)
      };
    }
  },
  currencyPairs: {
    list: (partnerId: string) => {
      return {
        queryKey: ['partnerId', partnerId],
        queryFn: () => PartnersService.getInstance().getCurrencyPairs(partnerId)
      };
    }
  },
  users: {
    list: (partnerId: string) => {
      return {
        queryKey: [partnerId],
        queryFn: () => PartnersService.getInstance().getPartnerUsers(partnerId)
      };
    }
  }
});
