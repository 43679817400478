import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import { AddGrainCurrencyPairRequestBody } from '@grain/admin-console-api-types';
import GrainSettingsService from '../../../services/GrainSettingsService';
import { queries } from '../../../queries';

export const useAddGrainCurrencyPair = () => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, isSuccess, mutateAsync } = useMutation<void, Error, AddGrainCurrencyPairRequestBody>({
    retry: false,
    mutationFn: (params) => {
      return GrainSettingsService.getInstance().addNewCurrencyPair(params);
    },
    onSuccess: async () => {
      notifySuccess('Grain currency pair added successfully');
      await Promise.all([queryClient.refetchQueries(queries.settings.grainCurrencyPairs.all._def)]);
    }
  });

  return {
    isSuccessAddGrainCurrencyPair: isSuccess,
    isErrorAddGrainCurrencyPair: isError,
    addGrainCurrencyPairAsync: mutateAsync,
    isLoadingAddGrainCurrencyPair: isLoading,
    errorAddGrainCurrencyPair: error
  };
};
