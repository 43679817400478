import StatusBadge from '@grain/web-components/badges/StatusBadge';
import React from 'react';
import './CountryRestrictionStatusBadge.scss';

export default function CountryRestrictionStatusBadge({ isRestricted }: CountryRestrictionStatusBadgeProps) {
  const status = isRestricted ? 'blocked' : 'approved';
  return <StatusBadge className="customer-status-badge-container" status={status} />;
}

type CountryRestrictionStatusBadgeProps = {
  isRestricted: boolean;
};
