import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Page from '../../layout/Page';
import MapTransactionsData from './MapTransactionsData';

export default function PartnerUploadTransactionsPage() {
  const [transactionsFile, setTransactionsFile] = useState<File>();
  const [uploadedTransactionsFileUrl, setUploadedTransactionsFileUrl] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { partnerId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const transactionsFileChosen = (file: File, fileUrl: string) => {
    setUploadedTransactionsFileUrl(fileUrl);
    setTransactionsFile(file);
  };

  const transactionsFileParseFailed = () => {
    setLoading(false);
    clearFiles();
  };

  const clearFiles = () => {
    setTransactionsFile(undefined);
    setUploadedTransactionsFileUrl('');
  };

  useEffect(() => {
    (async function () {
      if (location.state) {
        const { uploadedFile, uploadedFileUrl }: FileUploadedState = location.state as FileUploadedState;
        if (uploadedFile && uploadedFileUrl) {
          transactionsFileChosen(uploadedFile, uploadedFileUrl);
        }
      } else {
        navigate(`/partners/${partnerId}`);
      }
    })();
  }, [location, navigate, partnerId]);

  const onMappingComplete = () => {
    navigate(`/partners/${partnerId}`);
  };

  return (
    <Page className="upload-transactions-page" title="Upload Transactions Page" loading={loading} subtitle={location.state?.partnerName}>
      {transactionsFile && (
        <MapTransactionsData
          partnerId={partnerId!}
          transactionsFileUrl={uploadedTransactionsFileUrl}
          transactionsFile={transactionsFile}
          onParseFailed={transactionsFileParseFailed}
          onMappingComplete={onMappingComplete}
        />
      )}
    </Page>
  );
}

type FileUploadedState = {
  uploadedFile: File;
  uploadedFileUrl: string;
};
