import React from 'react';
import './BaseTableEmptyState.scss';
import { Warning2 } from 'iconsax-react';
import colors from '../styles/colors.scss';

export function BaseTableEmptyState(props: BaseTableEmptyStateProps) {
  const { text } = props;
  return (
    <div className="base-table-empty-state-container">
      <div className="warning-icon-empty-state">
        <Warning2 variant="Bold" color={colors.textGrey} size={32} />
      </div>
      <div className="text">{text}</div>
    </div>
  );
}

interface BaseTableEmptyStateProps {
  text: string;
}
