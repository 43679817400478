import './FundTransfersTable.scss';
import {
  BaseFundTransfer,
  CollectionFundTransfer,
  FundTransferType,
  ManagingPlatform,
  TreasuryInboundFundTransfer,
  TreasuryOutboundFundTransfer,
  WithdrawalFundTransfer
} from '@grain/core-types';
import BaseTable, { BaseTableColumnDef } from '@grain/web-components/table/BaseTable';
import { asAmount, asDateString } from '@grain/web-components/utils/formatting';
import React from 'react';
import StatusBadge from '@grain/web-components/badges/StatusBadge';
import FundTransferStatusBadge from './FundTransferStatusBadge';
import { numericSortByColumnId, sortByDates } from '@grain/web-components/table/sorting';
import classNames from 'classnames';
import { isTabSelected } from './get-fund-transfer-table-tabs';
import { getFundTransferPartnerName } from './get-fund-transfer-partner-name';

type FundTransfersTableProps = {
  fundTransfers: BaseFundTransfer[];
  paginated?: boolean;
  pageSize?: number;
  noHeader?: boolean;
  renderSubComponent?: (row: BaseFundTransfer) => React.ReactNode;
  selectedTab?: string;
};

const PLATFORM_LOGOS: { [featureName in ManagingPlatform]: string } = {
  [ManagingPlatform.TransferMate]: '/images/transfer-mate.png',
  [ManagingPlatform.Sandbox]: '/images/web-components/logo.png'
};

export default function FundTransfersTable(props: FundTransfersTableProps) {
  const { fundTransfers, selectedTab, ...baseTableProps } = props;

  const columns: BaseTableColumnDef<BaseFundTransfer>[] = [
    {
      cell: React.useCallback(
        ({
          row: {
            original: { managedBy }
          }
        }) => (
          <img className={classNames('platform-logo', managedBy)} alt="platform-logo" src={PLATFORM_LOGOS[managedBy as ManagingPlatform]} />
        ),
        []
      ),
      id: 'managedBy',
      header: 'Platform'
    },
    {
      accessorFn: (fundTransfer) => asDateString(fundTransfer.completedAt),
      id: 'completedAt',
      header: 'Completed',
      sortingFn: sortByDates
    },
    {
      accessorFn: (fundTransfer) => fundTransfer.externalId,
      id: 'externalId',
      header: 'External ID'
    },
    {
      accessorFn: (fundTransfer) => fundTransfer.type,
      id: 'type',
      header: 'Type'
    },
    {
      accessorFn: getFundTransferPartnerName,
      id: 'partnerName',
      header: 'Partner Name'
    },
    {
      accessorFn: (fundTransfer) => (isTreasuryOutboundFundTransfer(fundTransfer) ? fundTransfer.referenceId : ''),
      id: 'referenceId',
      header: 'REF'
    },
    {
      accessorFn: (fundTransfer) => `${fundTransfer.currency} ${asAmount(fundTransfer.amount)}`,
      id: 'amount',
      header: 'Amount',
      sortingFn: numericSortByColumnId
    },
    {
      cell: React.useCallback(({ row: { original: fundTransfer } }) => {
        if (isCollectionFundTransfer(fundTransfer)) {
          return fundTransfer.reconciledAt ? (
            <StatusBadge status={fundTransfer.fromCounterparty.name || 'Reconciled'} />
          ) : (
            <StatusBadge status="Unreconciled" />
          );
        }

        if (isWithdrawalFundTransfer(fundTransfer) || isTreasuryInboundFundTransfer(fundTransfer)) {
          return <FundTransferStatusBadge fundTransfer={fundTransfer} />;
        }

        return <div />;
      }, []),
      id: 'reconciledStatus',
      header: 'Status'
    }
  ];

  return (
    <BaseTable
      className="fund-transfers-table-container"
      columns={columns}
      data={fundTransfers}
      {...baseTableProps}
      columnVisibility={{
        referenceId: isTabSelected(selectedTab, FundTransferType.TreasuryOutbound)
      }}
    />
  );
}

function isCollectionFundTransfer(fundTransfer: BaseFundTransfer): fundTransfer is CollectionFundTransfer {
  return fundTransfer.type === FundTransferType.Collection;
}

function isWithdrawalFundTransfer(fundTransfer: BaseFundTransfer): fundTransfer is WithdrawalFundTransfer {
  return fundTransfer.type === FundTransferType.Withdraw;
}

function isTreasuryInboundFundTransfer(fundTransfer: BaseFundTransfer): fundTransfer is TreasuryInboundFundTransfer {
  return fundTransfer.type === FundTransferType.TreasuryInbound;
}

function isTreasuryOutboundFundTransfer(fundTransfer: BaseFundTransfer): fundTransfer is TreasuryOutboundFundTransfer {
  return fundTransfer.type === FundTransferType.TreasuryOutbound;
}
