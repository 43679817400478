import { CurrencyPair, CurrencyPairWithSettlement, ForexDirection, ForexResponse, HedgeSettlementType } from '@grain/core-types';
import { getCountryDataList } from 'countries-list';

export const POALIM_SUPPORTED_CURRENCIES = [
  'AED',
  'AUD',
  'CAD',
  'CHF',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'ILS',
  'JPY',
  'MXN',
  'NOK',
  'NZD',
  'PLN',
  'RON',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'USD',
  'ZAR'
];

export const TRANSFER_MATE_SUPPORTED_CURRENCIES = [
  'AED',
  'AUD',
  'BGN',
  'BHD',
  'BWP',
  'CAD',
  'CHF',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'ILS',
  'MYR',
  'MXN',
  'NOK',
  'OMR',
  'PLN',
  'QAR',
  'SAR',
  'SEK',
  'SGD',
  'TND',
  'UGX',
  'USD',
  'ZAR'
];

export const PERIODIC_RATES_CURRENCIES_USING_SPREADS = ['BRL', 'CNY'];

export const MAJOR_CURRENCIES = ['EUR', 'GBP', 'USD'];

export const demoCurrencies = [...MAJOR_CURRENCIES, 'MXN'];

export const currencies = ['USD', 'EUR', 'GBP', 'JPY', 'CHF', 'AUD', 'CNY', 'HKD', 'NOK', 'PLN', 'MXN'];

// Subset of the list found in our current exchange-rate API: https://oxr.readme.io/docs/supported-currencies
export const allCurrencies = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTC',
  'BTN',
  'BWP',
  'BYN',
  'BYR',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLF',
  'CLP',
  'CNH',
  'CNY',
  'COP',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EEK',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MRU',
  'MTL',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STD',
  'STN',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XCD',
  'XDR',
  'XOF',
  'XPD',
  'XPF',
  'XPT',
  'YER',
  'ZAR',
  'ZMK',
  'ZMW'
];

export const forexSymbols: string[] = [
  'AUD/CAD',
  'AUD/CHF',
  'AUD/CNH',
  'AUD/CZK',
  'AUD/DKK',
  'AUD/HKD',
  'AUD/HUF',
  'AUD/JPY',
  'AUD/MXN',
  'AUD/NOK',
  'AUD/NZD',
  'AUD/PLN',
  'AUD/SEK',
  'AUD/SGD',
  'AUD/USD',
  'AUD/ZAR',
  'CAD/CHF',
  'CAD/CNH',
  'CAD/CZK',
  'CAD/DKK',
  'CAD/HKD',
  'CAD/HUF',
  'CAD/JPY',
  'CAD/MXN',
  'CAD/NOK',
  'CAD/PLN',
  'CAD/SEK',
  'CAD/SGD',
  'CAD/ZAR',
  'CHF/CNH',
  'CHF/CZK',
  'CHF/DKK',
  'CHF/HKD',
  'CHF/HUF',
  'CHF/JPY',
  'CHF/MXN',
  'CHF/NOK',
  'CHF/PLN',
  'USD/SAR',
  'CAD/THB',
  'CHF/SEK',
  'CHF/SGD',
  'CHF/TRY',
  'CHF/ZAR',
  'DKK/CZK',
  'DKK/HKD',
  'DKK/HUF',
  'DKK/MXN',
  'DKK/NOK',
  'DKK/PLN',
  'DKK/SEK',
  'DKK/SGD',
  'DKK/ZAR',
  'EUR/AUD',
  'EUR/CAD',
  'EUR/CHF',
  'EUR/CNH',
  'EUR/CZK',
  'EUR/DKK',
  'EUR/GBP',
  'EUR/HKD',
  'EUR/HUF',
  'EUR/ILS',
  'EUR/JPY',
  'EUR/MXN',
  'EUR/NOK',
  'EUR/NZD',
  'EUR/PLN',
  'EUR/SAR',
  'GBP/SAR',
  'EUR/SEK',
  'EUR/SGD',
  'EUR/TRY',
  'EUR/USD',
  'EUR/ZAR',
  'GBP/AUD',
  'GBP/CAD',
  'GBP/CHF',
  'GBP/CNH',
  'GBP/CZK',
  'GBP/DKK',
  'GBP/HKD',
  'GBP/HUF',
  'GBP/JPY',
  'GBP/MXN',
  'GBP/NOK',
  'GBP/NZD',
  'GBP/PLN',
  'GBP/SEK',
  'GBP/SGD',
  'GBP/USD',
  'GBP/ZAR',
  'JPY/CZK',
  'JPY/DKK',
  'JPY/HKD',
  'JPY/HUF',
  'JPY/MXN',
  'JPY/NOK',
  'EUR/THB',
  'GBP/THB',
  'JPY/PLN',
  'JPY/SEK',
  'JPY/SGD',
  'JPY/ZAR',
  'NOK/CZK',
  'NOK/HKD',
  'NOK/HUF',
  'NOK/MXN',
  'NOK/PLN',
  'NOK/SEK',
  'NOK/SGD',
  'NOK/ZAR',
  'NZD/CAD',
  'NZD/CHF',
  'NZD/CZK',
  'NZD/DKK',
  'NZD/HKD',
  'NZD/HUF',
  'NZD/JPY',
  'NZD/MXN',
  'NZD/NOK',
  'NZD/PLN',
  'NZD/SEK',
  'NZD/SGD',
  'NZD/USD',
  'NZD/ZAR',
  'USD/CAD',
  'USD/CHF',
  'USD/THB',
  'CAD/TRY',
  'USD/CNH',
  'USD/CZK',
  'USD/DKK',
  'USD/HKD',
  'USD/HUF',
  'USD/ILS',
  'USD/JPY',
  'USD/MXN',
  'USD/NOK',
  'USD/PLN',
  'USD/SEK',
  'USD/SGD',
  'USD/TRY',
  'USD/ZAR',
  'CAD/AED',
  'EUR/AED',
  'GBP/AED',
  'USD/AED',
  'CAD/BGN',
  'EUR/BGN',
  'GBP/BGN',
  'USD/BGN',
  'CAD/BHD',
  'EUR/BHD',
  'GBP/BHD',
  'USD/BHD',
  'CAD/CNY',
  'EUR/CNY',
  'GBP/CNY',
  'USD/CNY',
  'CAD/ILS',
  'GBP/ILS',
  'CAD/KWD',
  'EUR/KWD',
  'GBP/KWD',
  'USD/KWD',
  'CAD/OMR',
  'EUR/OMR',
  'CAD/OMR',
  'GBP/OMR',
  'USD/OMR',
  'CAD/QAR',
  'EUR/QAR',
  'GBP/QAR',
  'USD/QAR',
  'CAD/RON',
  'EUR/RON',
  'GBP/RON',
  'USD/RON',
  'CAD/SAR',
  'GBP/TRY',
  'EUR/BRL',
  'USD/BRL',
  'GBP/BRL',
  'EUR/KRW',
  'USD/KRW',
  'GBP/KRW',
  'USD/IDR',
  'EUR/IDR',
  'GBP/IDR',
  'USD/INR',
  'EUR/INR',
  'GBP/INR',
  'USD/PHP',
  'EUR/PHP',
  'GBP/PHP'
];

const forexSymbolsSet = new Set(forexSymbols);

export enum CurrencyHolidaySource {
  MANUAL = 'manual', // from manual-defined-holidays.ts
  HOLIDAYS = 'holidays' // from date-holidays package
}

export interface CurrencyMapping {
  [currencyCode: string]: { countryCode: string; type: CurrencyHolidaySource };
}

export const currencyToCountryMapping: CurrencyMapping = {
  AED: { countryCode: 'AE', type: CurrencyHolidaySource.MANUAL },
  AUD: { countryCode: 'AU', type: CurrencyHolidaySource.MANUAL },
  BGN: { countryCode: 'BG', type: CurrencyHolidaySource.MANUAL },
  BHD: { countryCode: 'BH', type: CurrencyHolidaySource.MANUAL },
  BRL: { countryCode: 'BR', type: CurrencyHolidaySource.MANUAL },
  BWP: { countryCode: 'BW', type: CurrencyHolidaySource.MANUAL },
  CAD: { countryCode: 'CA', type: CurrencyHolidaySource.MANUAL },
  CHF: { countryCode: 'CH', type: CurrencyHolidaySource.MANUAL },
  CNY: { countryCode: 'CN', type: CurrencyHolidaySource.MANUAL },
  CZK: { countryCode: 'CZ', type: CurrencyHolidaySource.MANUAL },
  DKK: { countryCode: 'DK', type: CurrencyHolidaySource.MANUAL },
  EUR: { countryCode: 'EU', type: CurrencyHolidaySource.MANUAL },
  GBP: { countryCode: 'GB', type: CurrencyHolidaySource.MANUAL },
  HKD: { countryCode: 'HK', type: CurrencyHolidaySource.MANUAL },
  HUF: { countryCode: 'HU', type: CurrencyHolidaySource.MANUAL },
  IDR: { countryCode: 'ID', type: CurrencyHolidaySource.MANUAL },
  ILS: { countryCode: 'IL', type: CurrencyHolidaySource.MANUAL },
  JPY: { countryCode: 'JP', type: CurrencyHolidaySource.MANUAL },
  KRW: { countryCode: 'KR', type: CurrencyHolidaySource.MANUAL },
  MYR: { countryCode: 'MY', type: CurrencyHolidaySource.MANUAL },
  MXN: { countryCode: 'MX', type: CurrencyHolidaySource.MANUAL },
  NOK: { countryCode: 'NO', type: CurrencyHolidaySource.MANUAL },
  NZD: { countryCode: 'NZ', type: CurrencyHolidaySource.MANUAL },
  OMR: { countryCode: 'OM', type: CurrencyHolidaySource.MANUAL },
  PHP: { countryCode: 'PH', type: CurrencyHolidaySource.MANUAL },
  PLN: { countryCode: 'PL', type: CurrencyHolidaySource.MANUAL },
  QAR: { countryCode: 'QA', type: CurrencyHolidaySource.MANUAL },
  RON: { countryCode: 'RO', type: CurrencyHolidaySource.MANUAL },
  SAR: { countryCode: 'SA', type: CurrencyHolidaySource.MANUAL },
  SEK: { countryCode: 'SE', type: CurrencyHolidaySource.MANUAL },
  SGD: { countryCode: 'SG', type: CurrencyHolidaySource.MANUAL },
  THB: { countryCode: 'TH', type: CurrencyHolidaySource.MANUAL },
  TND: { countryCode: 'TN', type: CurrencyHolidaySource.MANUAL },
  TRY: { countryCode: 'TR', type: CurrencyHolidaySource.MANUAL },
  UGX: { countryCode: 'UG', type: CurrencyHolidaySource.MANUAL },
  USD: { countryCode: 'US', type: CurrencyHolidaySource.MANUAL },
  ZAR: { countryCode: 'ZA', type: CurrencyHolidaySource.MANUAL }
};

export function convertCurrencyPairToPairString(currencyPair: CurrencyPair): string {
  return `${currencyPair.fromCurrency}${currencyPair.toCurrency}`;
}

export function convertCurrencyStringToCurrencyPair(pairString: string): CurrencyPair {
  return {
    fromCurrency: pairString.substring(0, 3),
    toCurrency: pairString.substring(3, 6)
  };
}

export const CURRENCY_ALIASES: { [currency: string]: string } = {
  CNH: 'CNY',
  MEX: 'MXN'
};

function normalizeCurrency(currency: string): string {
  return CURRENCY_ALIASES[currency] || currency;
}

export function makeCurrenciesUnique(currencies: CurrencyPair[]): CurrencyPair[] {
  return Array.from(new Set(currencies.map(convertCurrencyPairToPairString))).map(convertCurrencyStringToCurrencyPair);
}

export function normalizeCurrencies(fromCurrency: string, toCurrency: string): [string, string] {
  return [normalizeCurrency(fromCurrency), normalizeCurrency(toCurrency)];
}

export function normalizeCurrencyPair({ fromCurrency, toCurrency }: CurrencyPair): CurrencyPair {
  return {
    fromCurrency: normalizeCurrency(fromCurrency),
    toCurrency: normalizeCurrency(toCurrency)
  };
}

export function convertForexSymbolStringToCurrencyPair(symbol: string): CurrencyPair {
  return {
    toCurrency: symbol.substring(0, 3),
    fromCurrency: symbol.substring(4, 7)
  };
}

export function convertCurrencyPairToForexSymbolString(currencyPair: CurrencyPair): string {
  return `${currencyPair.toCurrency}/${currencyPair.fromCurrency}`;
}

export function getForexCurrencyParams(fromCurrency: string, toCurrency: string): ForexResponse {
  let direction = ForexDirection.Buy;
  let symbol = convertCurrencyPairToForexSymbolString({
    fromCurrency,
    toCurrency
  });
  if (!forexSymbolsSet.has(symbol)) {
    symbol = convertCurrencyPairToForexSymbolString({
      fromCurrency: toCurrency,
      toCurrency: fromCurrency
    });
    if (!forexSymbolsSet.has(symbol)) {
      throw new Error(`${symbol} is not a valid forex symbol`);
    }
    direction = ForexDirection.Sell;
  }
  return {
    symbol,
    direction
  };
}

export function isDealtEqualLeadingCurrency(dealtCurrency: string, symbol: string): boolean {
  const leadingCurrency = symbol.split('/')[0];
  return dealtCurrency === leadingCurrency;
}

// Get all possible pairs from the list of currencies
export const DEMO_CURRENCY_PAIRS: CurrencyPairWithSettlement[] = demoCurrencies.flatMap((fromCurrency) =>
  demoCurrencies.flatMap((toCurrency) =>
    fromCurrency !== toCurrency
      ? {
          fromCurrency,
          toCurrency,
          settlementType: HedgeSettlementType.Collection
        }
      : []
  )
);

export const PERIODIC_RATES_USING_SPREADS_WITH_MAJOR_CURRENCIES_PAIRS: CurrencyPair[] = PERIODIC_RATES_CURRENCIES_USING_SPREADS.flatMap(
  (fromCurrency) =>
    MAJOR_CURRENCIES.flatMap((toCurrency) =>
      fromCurrency !== toCurrency
        ? {
            fromCurrency,
            toCurrency
          }
        : []
    )
);

export const manualForwardCurrencies = ['BRL', 'IDR', 'KRW', 'INR', 'CNY', 'PHP'];

export function calcRateByDealtCurrency(params: { isDealtAsLeadingCurrency: boolean; price: number }): number {
  const { isDealtAsLeadingCurrency, price } = params;
  return isDealtAsLeadingCurrency ? price : 1 / price;
}

export function normalizeBidPriceToRate(params: { fromCurrency: string; toCurrency: string; price: number }): number {
  const { fromCurrency, toCurrency, price } = params;
  const { symbol } = getForexCurrencyParams(fromCurrency, toCurrency);
  const dealtCurrency = toCurrency.toUpperCase();
  const isDealtAsLeadingCurrency = isDealtEqualLeadingCurrency(dealtCurrency, symbol);
  return calcRateByDealtCurrency({ isDealtAsLeadingCurrency, price });
}

export function convertSymbolToDoubleDirectionCurrencyPair(symbol: string): [CurrencyPair, CurrencyPair] {
  const [fromCurrency, toCurrency] = symbol.split('/');
  return [
    { fromCurrency, toCurrency },
    { fromCurrency: toCurrency, toCurrency: fromCurrency }
  ];
}

export function toSymbolNaive(fromCurrency: string, toCurrency: string): string {
  return `${fromCurrency}${toCurrency}`;
}

export const CURRENCIES_WITHOUT_DECIMALS = [
  'CLP',
  'DJF',
  'GNF',
  'HUF',
  'IDR',
  'ISK',
  'JPY',
  'KRW',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF'
];

export function isCurrencyWithoutDecimals(currency: string): boolean {
  return CURRENCIES_WITHOUT_DECIMALS.includes(currency);
}

export const SETTLEMENT_READY_TRANSFER_MATE_CURRENCIES = [
  'AED',
  'AUD',
  'BGN',
  'CAD',
  'CHF',
  'CZK',
  'DKK',
  'EUR',
  'HKD',
  'ILS',
  'GBP',
  'MYR',
  'NOK',
  'PLN',
  'SAR',
  'SEK',
  'SGD',
  'USD',
  'ZAR'
];

const CURRENCIES_USED_IN_MULTIPLE_COUNTRIES_TO_MAIN_COUNTRY_CODE: Record<string, string> = {
  AUD: 'AU',
  BTN: 'BT',
  BWP: 'BW',
  DKK: 'DK',
  DZD: 'DZ',
  EUR: 'EU',
  GBP: 'GB',
  INR: 'IN',
  MAD: 'MA',
  MRU: 'MR',
  NOK: 'NO',
  NZD: 'NZ',
  USD: 'US',
  UYW: 'UY',
  ZAR: 'ZA'
};

const LEGACY_CURRENCIES_TO_COUNTRY_CODE: Record<string, string> = {
  HRK: 'HR' // croatian kuna, until 2023.
};

export const CURRENCY_TO_COUNTRY_CODE_MAPPING = getCountryDataList().reduce(
  (countriesByCurrency, country) => ({
    ...countriesByCurrency,
    ...country.currency.reduce(
      (countryByLocalCurrencies, currency) => ({
        ...countryByLocalCurrencies,
        [currency]: countriesByCurrency[currency] || country.iso2
      }),
      {}
    )
  }),
  {
    ...CURRENCIES_USED_IN_MULTIPLE_COUNTRIES_TO_MAIN_COUNTRY_CODE,
    ...LEGACY_CURRENCIES_TO_COUNTRY_CODE
  }
);

export const EXCHANGE_RATES_BASE_TO_CURRENCIES: Record<string, string[]> = {
  AED: [
    'SAR',
    'DKK',
    'ILS',
    'UYU',
    'PEN',
    'CNY',
    'CRC',
    'ALL',
    'UAH',
    'JMD',
    'MUR',
    'BDT',
    'BOB',
    'XOF',
    'NOK',
    'PKR',
    'KES',
    'TZS',
    'EGP',
    'OMR',
    'CLP',
    'BYN',
    'ISK',
    'ARS',
    'KWD',
    'GTQ',
    'RUB',
    'NGN',
    'RON',
    'LKR',
    'PHP',
    'VND',
    'JPY',
    'TWD',
    'XCD',
    'BHD',
    'GEL',
    'SGD',
    'TND',
    'NZD',
    'CHF',
    'DOP',
    'INR',
    'HKD',
    'MAD',
    'COP',
    'KRW',
    'LBP',
    'GHS',
    'NPR',
    'QAR',
    'JOD',
    'XAF',
    'BGN',
    'BRL',
    'SEK',
    'DZD',
    'XPF',
    'FJD',
    'SRD',
    'ETB',
    'MKD',
    'LAK',
    'AZN',
    'KYD',
    'RWF',
    'DJF',
    'VUV',
    'RSD',
    'MGA',
    'MZN',
    'BBD',
    'BND',
    'KZT',
    'HNL',
    'MDL',
    'MOP',
    'PYG',
    'ZMW',
    'ANG',
    'SCR',
    'GNF',
    'BWP',
    'MNT',
    'CVE',
    'BAM',
    'MWK',
    'NAD',
    'AMD',
    'PGK',
    'BZD',
    'BSD',
    'KGS',
    'AUD',
    'IDR',
    'GBP',
    'CZK',
    'MYR',
    'MXN',
    'HUF',
    'ZAR',
    'THB',
    'CAD',
    'EUR',
    'USD',
    'TRY',
    'PLN'
  ],
  ALL: [
    'HRK',
    'MUR',
    'PEN',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'BDT',
    'PKR',
    'BOB',
    'KES',
    'DOP',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'KZT',
    'AED',
    'TZS',
    'AUD',
    'ISK',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'KWD',
    'JMD',
    'RUB',
    'NGN',
    'CZK',
    'BYN',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'BAM',
    'HUF',
    'NZD',
    'LKR',
    'CHF',
    'FJD',
    'BWP',
    'INR',
    'HKD',
    'XCD',
    'MAD',
    'KRW',
    'NAD',
    'QAR',
    'LBP',
    'PGK',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'DZD',
    'XPF',
    'CAD',
    'SAR',
    'GHS',
    'NPR',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'UAH',
    'VUV',
    'SCR',
    'KGS',
    'MZN',
    'MDL',
    'RWF',
    'DJF',
    'ZMW',
    'MGA',
    'KYD',
    'ANG',
    'MNT',
    'GNF',
    'RSD',
    'CVE',
    'MWK',
    'AMD',
    'SRD',
    'BZD',
    'BSD',
    'MKD',
    'AZN'
  ],
  AMD: [
    'HRK',
    'BRL',
    'SAR',
    'CNY',
    'GBP',
    'AUD',
    'KES',
    'JPY',
    'MYR',
    'IDR',
    'EGP',
    'SGD',
    'ZAR',
    'NZD',
    'THB',
    'CHF',
    'CAD',
    'INR',
    'EUR',
    'HKD',
    'KWD',
    'RUB',
    'USD',
    'PHP',
    'MXN',
    'HUF',
    'SEK',
    'KRW',
    'AED',
    'TRY',
    'NOK',
    'TND',
    'XAF',
    'BGN',
    'ANG',
    'PLN',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SRD',
    'ILS',
    'ETB',
    'UYU',
    'CVE',
    'MKD',
    'CRC',
    'BAM',
    'MWK',
    'UAH',
    'AZN',
    'MUR',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'PKR',
    'MZN',
    'TWD',
    'PEN',
    'JMD',
    'LAK',
    'OMR',
    'ALL',
    'CLP',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'ARS',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'NGN',
    'CZK',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'BHD',
    'ZMW',
    'BYN',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'COP',
    'MNT',
    'GTQ',
    'NAD',
    'LKR',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'XCD',
    'GEL',
    'PYG'
  ],
  ANG: [
    'HRK',
    'CZK',
    'RON',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'BDT',
    'CHF',
    'XOF',
    'INR',
    'HKD',
    'KRW',
    'NGN',
    'VND',
    'QAR',
    'JOD',
    'MXN',
    'XAF',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'FJD',
    'CAD',
    'SAR',
    'DKK',
    'MAD',
    'ILS',
    'CNY',
    'UAH',
    'LKR',
    'TRY',
    'GEL',
    'NOK',
    'TND',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'COP',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'AED',
    'AUD',
    'ARS',
    'KWD',
    'RUB',
    'VUV',
    'KGS',
    'MZN',
    'ZMW',
    'JMD',
    'BOB',
    'ISK',
    'HNL',
    'KYD',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'AMD',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'BGN',
    'BYN',
    'XPF',
    'MDL',
    'CVE',
    'BWP',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'MUR',
    'XCD',
    'PYG',
    'GNF',
    'DZD',
    'SCR',
    'DOP',
    'SRD',
    'ETB',
    'LAK',
    'ALL',
    'MKD',
    'BAM',
    'MWK',
    'KZT',
    'MOP',
    'GHS',
    'NPR',
    'BZD',
    'BSD'
  ],
  ARS: [
    'AED',
    'LKR',
    'AUD',
    'QAR',
    'JOD',
    'TRY',
    'GEL',
    'NOK',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'FJD',
    'DOP',
    'SAR',
    'CRC',
    'UAH',
    'GBP',
    'PKR',
    'KES',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'IDR',
    'EGP',
    'SGD',
    'OMR',
    'CLP',
    'ZAR',
    'NZD',
    'THB',
    'BDT',
    'CHF',
    'ISK',
    'CAD',
    'XOF',
    'INR',
    'EUR',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'USD',
    'CZK',
    'RON',
    'PHP',
    'VND',
    'MXN',
    'BHD',
    'HUF',
    'SEK',
    'BYN',
    'DKK',
    'MAD',
    'ILS',
    'COP',
    'KRW',
    'CNY',
    'NAD',
    'LBP',
    'AMD',
    'PGK',
    'XCD',
    'PYG',
    'ANG',
    'XPF',
    'SCR',
    'GNF',
    'SRD',
    'ETB',
    'UYU',
    'CVE',
    'MKD',
    'BAM',
    'MWK',
    'AZN',
    'MUR',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'MZN',
    'JMD',
    'LAK',
    'ALL',
    'BOB',
    'KZT',
    'HNL',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'BND',
    'ZMW',
    'MDL',
    'BWP',
    'MNT',
    'GTQ'
  ],
  AUD: [
    'JPY',
    'SGD',
    'NZD',
    'CHF',
    'INR',
    'CNY',
    'MWK',
    'UAH',
    'AZN',
    'MUR',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'PKR',
    'KES',
    'MZN',
    'TWD',
    'PEN',
    'EGP',
    'JMD',
    'LAK',
    'OMR',
    'ALL',
    'CLP',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'ARS',
    'KYD',
    'MOP',
    'HKD',
    'RWF',
    'KWD',
    'DJF',
    'RUB',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'BHD',
    'ZMW',
    'SEK',
    'BYN',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'COP',
    'MNT',
    'GTQ',
    'KRW',
    'NAD',
    'LKR',
    'LBP',
    'AMD',
    'PGK',
    'QAR',
    'JOD',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'ANG',
    'BRL',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SAR',
    'SRD',
    'ETB',
    'UYU',
    'CVE',
    'MKD',
    'CRC',
    'BAM',
    'GBP',
    'MYR',
    'IDR',
    'ZAR',
    'THB',
    'CAD',
    'EUR',
    'USD',
    'CZK',
    'MXN',
    'HUF',
    'ILS',
    'AED',
    'TRY',
    'PLN'
  ],
  AZN: [
    'BYN',
    'THB',
    'MDL',
    'EUR',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'KRW',
    'CNY',
    'USD',
    'NAD',
    'LKR',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SAR',
    'ETB',
    'MKD',
    'CRC',
    'BAM',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'AED',
    'AUD',
    'AMD',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'ANG',
    'VUV',
    'KGS',
    'PKR',
    'KES',
    'MZN',
    'JPY',
    'TWD',
    'MYR',
    'CVE',
    'PEN',
    'IDR',
    'EGP',
    'SGD',
    'JMD',
    'OMR',
    'GBP',
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'KZT',
    'CAD',
    'HNL',
    'XOF',
    'INR',
    'KYD',
    'MOP',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'RSD',
    'MGA',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'MXN',
    'BHD',
    'LAK',
    'ALL',
    'HUF',
    'ZAR',
    'SEK',
    'SRD',
    'BSD',
    'RWF',
    'DJF',
    'ZMW'
  ],
  BAM: [
    'LKR',
    'AMD',
    'XCD',
    'GNF',
    'CVE',
    'MWK',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'KGS',
    'MZN',
    'LAK',
    'ALL',
    'PEN',
    'JMD',
    'BDT',
    'BOB',
    'XOF',
    'RWF',
    'DJF',
    'MGA',
    'TZS',
    'BBD',
    'BND',
    'ANG',
    'BYN',
    'MDL',
    'AED',
    'INR',
    'CAD',
    'CHF',
    'ZAR',
    'DKK',
    'GBP',
    'HKD',
    'JPY',
    'COP',
    'MYR',
    'NOK',
    'NZD',
    'SEK',
    'SGD',
    'THB',
    'TWD',
    'USD',
    'CNY',
    'ILS',
    'AUD',
    'ARS',
    'CLP',
    'MXN',
    'UYU',
    'AZN',
    'BHD',
    'CRC',
    'CZK',
    'EGP',
    'FJD',
    'IDR',
    'JOD',
    'KES',
    'KRW',
    'KWD',
    'KZT',
    'LBP',
    'MOP',
    'OMR',
    'PGK',
    'PKR',
    'QAR',
    'RUB',
    'SAR',
    'ZMW',
    'XAF',
    'XPF',
    'RON',
    'BGN',
    'PHP',
    'UAH',
    'PLN',
    'HUF',
    'MUR',
    'DZD',
    'ETB',
    'HNL',
    'ISK',
    'MKD',
    'NGN',
    'SRD',
    'VND',
    'MAD',
    'BWP',
    'MNT',
    'TRY',
    'VUV',
    'NAD',
    'TND',
    'DOP',
    'GEL',
    'RSD',
    'SCR',
    'PYG',
    'GTQ',
    'KYD',
    'EUR'
  ],
  BBD: [
    'DJF',
    'MGA',
    'TZS',
    'BND',
    'XCD',
    'ANG',
    'BYN',
    'MDL',
    'LKR',
    'AMD',
    'GNF',
    'CVE',
    'MWK',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'KGS',
    'MZN',
    'LAK',
    'ALL',
    'PEN',
    'JMD',
    'BDT',
    'BOB',
    'XOF',
    'RWF',
    'AED',
    'INR',
    'CAD',
    'CHF',
    'ZAR',
    'DKK',
    'GBP',
    'HKD',
    'JPY',
    'COP',
    'MYR',
    'NOK',
    'NZD',
    'SEK',
    'SGD',
    'THB',
    'TWD',
    'USD',
    'CNY',
    'ILS',
    'AUD',
    'ARS',
    'CLP',
    'MXN',
    'UYU',
    'AZN',
    'BHD',
    'CRC',
    'CZK',
    'EGP',
    'FJD',
    'IDR',
    'JOD',
    'KES',
    'KRW',
    'KWD',
    'KZT',
    'LBP',
    'MOP',
    'OMR',
    'PGK',
    'PKR',
    'QAR',
    'RUB',
    'SAR',
    'ZMW',
    'XAF',
    'XPF',
    'RON',
    'BGN',
    'PHP',
    'UAH',
    'PLN',
    'HUF',
    'MUR',
    'DZD',
    'ETB',
    'HNL',
    'ISK',
    'MKD',
    'NGN',
    'SRD',
    'VND',
    'MAD',
    'BWP',
    'MNT',
    'TRY',
    'VUV',
    'NAD',
    'TND',
    'DOP',
    'GEL',
    'RSD',
    'SCR',
    'PYG',
    'GTQ',
    'KYD',
    'BAM',
    'EUR'
  ],
  BDT: [
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'QAR',
    'TRY',
    'MXN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'CNY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'KZT',
    'MZN',
    'TZS',
    'ISK',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'JMD',
    'NGN',
    'CZK',
    'BYN',
    'VND',
    'RON',
    'XOF',
    'MDL',
    'RWF',
    'TWD',
    'DJF',
    'BHD',
    'ZMW',
    'BAM',
    'MGA',
    'LKR',
    'FJD',
    'BWP',
    'KYD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'GNF',
    'RSD',
    'NAD',
    'CVE',
    'LBP',
    'PGK',
    'JOD',
    'XAF',
    'MWK',
    'BGN',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'GHS',
    'NPR',
    'BZD',
    'ETB',
    'BSD',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'UAH',
    'AZN',
    'MUR',
    'PEN',
    'GEL',
    'PYG',
    'TND',
    'VUV',
    'BOB',
    'SCR',
    'DOP',
    'KGS'
  ],
  BGN: [
    'AUD',
    'JPY',
    'MYR',
    'SGD',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'THB',
    'EUR',
    'CNY',
    'USD',
    'LAK',
    'TND',
    'ALL',
    'PLN',
    'BRL',
    'GNF',
    'DZD',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'SRD',
    'ETB',
    'IDR',
    'EGP',
    'MKD',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'AED',
    'KZT',
    'MOP',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'KWD',
    'BSD',
    'RUB',
    'CZK',
    'VUV',
    'KGS',
    'RON',
    'MZN',
    'TWD',
    'PEN',
    'BHD',
    'ZMW',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'AMD',
    'HNL',
    'XOF',
    'KYD',
    'HKD',
    'RWF',
    'DJF',
    'ANG',
    'KRW',
    'NGN',
    'RSD',
    'MGA',
    'VND',
    'PHP',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'CVE',
    'QAR',
    'JOD',
    'MXN',
    'XAF',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'XPF',
    'FJD',
    'SAR',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG'
  ],
  BHD: [
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'HKD',
    'KWD',
    'RUB',
    'CZK',
    'PHP',
    'VND',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'THB',
    'EUR',
    'DKK',
    'MAD',
    'ILS',
    'KRW',
    'CNY',
    'USD',
    'QAR',
    'TRY',
    'NOK',
    'PLN',
    'BRL',
    'SAR',
    'CLP',
    'AED',
    'AUD',
    'ARS',
    'PKR',
    'KES',
    'JPY',
    'TWD',
    'MYR',
    'IDR',
    'EGP',
    'SGD',
    'OMR',
    'GBP',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'BBD',
    'BND',
    'ZMW',
    'LAK',
    'ALL',
    'BYN',
    'MDL',
    'BWP',
    'UYU',
    'MNT',
    'GTQ',
    'NAD',
    'AZN',
    'LKR',
    'LBP',
    'PGK',
    'JOD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SRD',
    'ETB',
    'MKD',
    'CRC',
    'BAM',
    'MWK',
    'UAH',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'MZN',
    'CVE',
    'PEN',
    'JMD'
  ],
  BND: [
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'AUD',
    'ARS',
    'PEN',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'BDT',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'FJD',
    'INR',
    'HKD',
    'MAD',
    'KRW',
    'BYN',
    'QAR',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'LKR',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'CNY',
    'UAH',
    'GEL',
    'NOK',
    'TND',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'KGS',
    'MZN',
    'ISK',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'LAK',
    'ALL',
    'JMD',
    'BOB',
    'RWF',
    'DJF',
    'ZMW',
    'BAM',
    'MGA',
    'TZS',
    'BWP',
    'KYD',
    'XCD',
    'ANG',
    'MNT',
    'RSD',
    'NAD',
    'MDL',
    'LBP',
    'PGK',
    'BGN',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'ETB',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'GNF',
    'AZN',
    'MUR',
    'CVE',
    'PYG',
    'MWK',
    'VUV',
    'SCR',
    'DOP',
    'GHS',
    'NPR',
    'BZD',
    'BSD'
  ],
  BOB: [
    'TRY',
    'MXN',
    'GBP',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'CNY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'RSD',
    'NAD',
    'QAR',
    'LBP',
    'PGK',
    'JOD',
    'XAF',
    'BGN',
    'PLN',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'GHS',
    'BZD',
    'ILS',
    'ETB',
    'BSD',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'GNF',
    'UAH',
    'AZN',
    'CVE',
    'MUR',
    'PEN',
    'GEL',
    'PYG',
    'TND',
    'MWK',
    'VUV',
    'SCR',
    'DOP',
    'NPR',
    'OMR',
    'KGS',
    'CLP',
    'COP',
    'MZN',
    'TZS',
    'ISK',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'JMD',
    'NGN',
    'CZK',
    'BYN',
    'BDT',
    'VND',
    'RON',
    'XOF',
    'MDL',
    'RWF',
    'TWD',
    'DJF',
    'BHD',
    'ZMW',
    'BAM',
    'MGA',
    'LKR',
    'FJD',
    'BWP',
    'KYD',
    'XCD',
    'MAD',
    'ANG',
    'MNT'
  ],
  BRL: [
    'ANG',
    'AMD',
    'LAK',
    'CVE',
    'ALL',
    'MXN',
    'AED',
    'AUD',
    'ZAR',
    'THB',
    'GBP',
    'USD',
    'EUR',
    'INR',
    'CAD',
    'CHF',
    'DKK',
    'HKD',
    'JPY',
    'MYR',
    'NOK',
    'NZD',
    'SEK',
    'SGD',
    'TWD',
    'CNY',
    'ILS',
    'ARS',
    'CLP',
    'UYU',
    'AZN',
    'BHD',
    'CRC',
    'CZK',
    'EGP',
    'FJD',
    'IDR',
    'JOD',
    'KES',
    'KRW',
    'KWD',
    'KZT',
    'LBP',
    'MOP',
    'OMR',
    'PGK',
    'PKR',
    'QAR',
    'RUB',
    'SAR',
    'ZMW',
    'XAF',
    'XPF',
    'RON',
    'BGN',
    'PHP',
    'UAH',
    'PLN',
    'MUR',
    'DZD',
    'ETB',
    'HNL',
    'ISK',
    'MKD',
    'NGN',
    'SRD',
    'VND',
    'MAD',
    'BWP',
    'MNT',
    'TRY',
    'VUV',
    'NAD',
    'DOP',
    'GEL',
    'RSD',
    'SCR',
    'PYG',
    'GTQ',
    'KYD',
    'BND',
    'LKR',
    'PEN',
    'BZD',
    'BSD',
    'GHS',
    'MDL',
    'TZS',
    'BYN',
    'BOB',
    'NPR',
    'GNF',
    'MWK',
    'BDT',
    'MGA',
    'JMD',
    'MZN',
    'XOF',
    'RWF',
    'DJF',
    'XCD',
    'KGS',
    'COP',
    'HUF'
  ],
  BSD: [
    'EUR',
    'USD',
    'AED',
    'AUD',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'MXN',
    'GBP',
    'ZAR',
    'THB',
    'CAD',
    'CNY',
    'TND',
    'MWK',
    'BRL',
    'VUV',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'GHS',
    'NPR',
    'IDR',
    'EGP',
    'OMR',
    'KGS',
    'CLP',
    'COP',
    'MZN',
    'ISK',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'KWD',
    'JMD',
    'RUB',
    'NGN',
    'CZK',
    'BDT',
    'BOB',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'RWF',
    'TWD',
    'DJF',
    'BHD',
    'ZMW',
    'BAM',
    'MGA',
    'LKR',
    'TZS',
    'FJD',
    'BWP',
    'KYD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'KRW',
    'BYN',
    'RSD',
    'NAD',
    'MDL',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'TRY',
    'XAF',
    'BGN',
    'PLN',
    'SEK',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'SAR',
    'BZD',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'GNF',
    'UAH',
    'AZN',
    'MUR',
    'CVE',
    'PEN',
    'GEL',
    'NOK',
    'PYG'
  ],
  BWP: [
    'XOF',
    'INR',
    'KYD',
    'HKD',
    'MAD',
    'KRW',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'DZD',
    'XPF',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'NAD',
    'UAH',
    'AZN',
    'MUR',
    'LKR',
    'AMD',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'BAM',
    'OMR',
    'CLP',
    'AED',
    'AUD',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'VND',
    'RON',
    'MZN',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'HUF',
    'NZD',
    'BDT',
    'CHF',
    'FJD',
    'BOB',
    'RWF',
    'DJF',
    'ANG',
    'SRD',
    'MDL',
    'MNT',
    'MKD',
    'GNF',
    'CVE',
    'MWK',
    'BSD',
    'VUV',
    'KGS',
    'ZMW'
  ],
  BYN: [
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'QAR',
    'TRY',
    'MXN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'CNY',
    'GNF',
    'UAH',
    'AZN',
    'CVE',
    'MUR',
    'PEN',
    'GEL',
    'PYG',
    'TND',
    'MWK',
    'VUV',
    'SCR',
    'DOP',
    'NPR',
    'KGS',
    'CLP',
    'COP',
    'MZN',
    'TZS',
    'ISK',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'JMD',
    'NGN',
    'CZK',
    'BDT',
    'BOB',
    'VND',
    'RON',
    'XOF',
    'MDL',
    'RWF',
    'TWD',
    'DJF',
    'BHD',
    'ZMW',
    'BAM',
    'MGA',
    'LKR',
    'FJD',
    'BWP',
    'KYD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'RSD',
    'NAD',
    'LBP',
    'PGK',
    'JOD',
    'XAF',
    'BGN',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'GHS',
    'BZD',
    'ETB',
    'BSD',
    'UYU',
    'GTQ',
    'MKD',
    'CRC'
  ],
  BZD: [
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'TRY',
    'MXN',
    'GBP',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'CNY',
    'BRL',
    'EUR',
    'IDR',
    'USD',
    'AED',
    'AUD',
    'JMD',
    'RUB',
    'NGN',
    'CZK',
    'BDT',
    'BOB',
    'VND',
    'RON',
    'XOF',
    'RWF',
    'TWD',
    'DJF',
    'BHD',
    'ZMW',
    'BAM',
    'MGA',
    'LKR',
    'TZS',
    'FJD',
    'BWP',
    'KYD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'KRW',
    'BYN',
    'RSD',
    'NAD',
    'MDL',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'XAF',
    'BGN',
    'PLN',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'GNF',
    'UAH',
    'AZN',
    'MUR',
    'CVE',
    'PEN',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'MWK',
    'VUV',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'GHS',
    'NPR',
    'BSD',
    'EGP',
    'OMR',
    'KGS',
    'CLP',
    'COP',
    'MZN',
    'ISK',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'KWD'
  ],
  CAD: [
    'ARS',
    'INR',
    'KWD',
    'RUB',
    'NGN',
    'LKR',
    'PHP',
    'VND',
    'JPY',
    'TWD',
    'BHD',
    'SGD',
    'NZD',
    'CHF',
    'HKD',
    'MAD',
    'COP',
    'KRW',
    'QAR',
    'JOD',
    'BRL',
    'SEK',
    'SAR',
    'DKK',
    'CNY',
    'UAH',
    'XOF',
    'NOK',
    'PKR',
    'KES',
    'EGP',
    'OMR',
    'CLP',
    'HNL',
    'MDL',
    'MOP',
    'GTQ',
    'XCD',
    'GEL',
    'PYG',
    'ZMW',
    'TND',
    'ANG',
    'SCR',
    'GNF',
    'DOP',
    'BWP',
    'MNT',
    'CVE',
    'BAM',
    'MWK',
    'NAD',
    'LBP',
    'AMD',
    'PGK',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'XAF',
    'BGN',
    'KGS',
    'DZD',
    'XPF',
    'FJD',
    'SRD',
    'ETB',
    'UYU',
    'PEN',
    'MKD',
    'CRC',
    'LAK',
    'ALL',
    'AZN',
    'JMD',
    'MUR',
    'BDT',
    'BOB',
    'KYD',
    'RWF',
    'DJF',
    'VUV',
    'RSD',
    'MGA',
    'TZS',
    'MZN',
    'BBD',
    'BND',
    'BYN',
    'ISK',
    'KZT',
    'CZK',
    'RON',
    'HRK',
    'MYR',
    'MXN',
    'HUF',
    'ZAR',
    'THB',
    'EUR',
    'USD',
    'AED',
    'TRY',
    'PLN',
    'ILS',
    'AUD',
    'IDR',
    'GBP'
  ],
  CHF: [
    'XOF',
    'NOK',
    'PKR',
    'KES',
    'TZS',
    'BBD',
    'BND',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'GBP',
    'BYN',
    'ISK',
    'CAD',
    'HNL',
    'ARS',
    'INR',
    'KWD',
    'GTQ',
    'RUB',
    'NGN',
    'CZK',
    'RON',
    'LKR',
    'PHP',
    'VND',
    'JPY',
    'TWD',
    'MYR',
    'XCD',
    'MXN',
    'BHD',
    'GEL',
    'PYG',
    'SGD',
    'TND',
    'HUF',
    'ZAR',
    'NZD',
    'THB',
    'DOP',
    'EUR',
    'BWP',
    'HKD',
    'MAD',
    'COP',
    'KRW',
    'USD',
    'AED',
    'LBP',
    'GHS',
    'NPR',
    'QAR',
    'JOD',
    'TRY',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'SEK',
    'DZD',
    'XPF',
    'FJD',
    'SAR',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'PEN',
    'CNY',
    'CRC',
    'LAK',
    'ALL',
    'UAH',
    'JMD',
    'MUR',
    'BDT',
    'BOB',
    'AUD',
    'KYD',
    'RWF',
    'DJF',
    'VUV',
    'RSD',
    'MGA',
    'MZN',
    'KZT',
    'MDL',
    'MOP',
    'ZMW',
    'ANG',
    'SCR',
    'GNF',
    'MNT',
    'CVE',
    'BAM',
    'MWK',
    'NAD',
    'AMD',
    'PGK',
    'BZD',
    'BSD',
    'KGS',
    'SRD',
    'MKD',
    'AZN'
  ],
  CLP: [
    'CZK',
    'RSD',
    'MGA',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'BHD',
    'LAK',
    'ALL',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'EUR',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'COP',
    'MNT',
    'GTQ',
    'KRW',
    'CNY',
    'USD',
    'NAD',
    'LKR',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'TRY',
    'XCD',
    'MXN',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SAR',
    'ETB',
    'UYU',
    'CRC',
    'BAM',
    'MWK',
    'UAH',
    'AZN',
    'MUR',
    'AED',
    'AUD',
    'AMD',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'KGS',
    'PKR',
    'KES',
    'MZN',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'IDR',
    'EGP',
    'SGD',
    'JMD',
    'OMR',
    'GBP',
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'KZT',
    'CAD',
    'HNL',
    'XOF',
    'INR',
    'KYD',
    'MOP',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'ZMW',
    'MDL',
    'SRD',
    'MKD',
    'BSD',
    'ANG',
    'VUV',
    'CVE',
    'RWF',
    'DJF'
  ],
  CNY: [
    'HUF',
    'SEK',
    'BYN',
    'BWP',
    'DKK',
    'MAD',
    'COP',
    'GTQ',
    'KRW',
    'NAD',
    'AED',
    'LKR',
    'LBP',
    'QAR',
    'JOD',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'XPF',
    'FJD',
    'DOP',
    'SAR',
    'ILS',
    'ETB',
    'UYU',
    'CRC',
    'UAH',
    'GBP',
    'MUR',
    'AUD',
    'GHS',
    'NPR',
    'PKR',
    'KES',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'IDR',
    'EGP',
    'SGD',
    'JMD',
    'LAK',
    'OMR',
    'ALL',
    'CLP',
    'ZAR',
    'NZD',
    'THB',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'CAD',
    'HNL',
    'XOF',
    'ARS',
    'INR',
    'EUR',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'USD',
    'CZK',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'MXN',
    'BHD',
    'MDL',
    'MNT',
    'AMD',
    'PGK',
    'ANG',
    'SCR',
    'GNF',
    'SRD',
    'CVE',
    'MKD',
    'BAM',
    'MWK',
    'AZN',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'MZN',
    'KZT',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'ZMW'
  ],
  COP: [
    'TZS',
    'BBD',
    'BND',
    'BYN',
    'GTQ',
    'LKR',
    'XCD',
    'GEL',
    'TND',
    'DOP',
    'GHS',
    'NPR',
    'PEN',
    'JMD',
    'LAK',
    'ALL',
    'BDT',
    'BOB',
    'XOF',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'MDL',
    'PYG',
    'ANG',
    'SCR',
    'GNF',
    'CVE',
    'BAM',
    'MWK',
    'AMD',
    'BZD',
    'BSD',
    'KGS',
    'MZN',
    'KYD',
    'AED',
    'INR',
    'CAD',
    'CHF',
    'ZAR',
    'DKK',
    'GBP',
    'HKD',
    'JPY',
    'MYR',
    'NOK',
    'NZD',
    'SEK',
    'SGD',
    'THB',
    'TWD',
    'CNY',
    'ILS',
    'AUD',
    'ARS',
    'CLP',
    'MXN',
    'USD',
    'EUR',
    'BRL'
  ],
  CRC: [
    'MUR',
    'AED',
    'AUD',
    'ARS',
    'GHS',
    'NPR',
    'PKR',
    'KES',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'BHD',
    'IDR',
    'EGP',
    'SGD',
    'JMD',
    'OMR',
    'GBP',
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'CAD',
    'XOF',
    'INR',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'MXN',
    'ALL',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'EUR',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'GTQ',
    'KRW',
    'CNY',
    'USD',
    'LKR',
    'LBP',
    'QAR',
    'JOD',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'XPF',
    'FJD',
    'DOP',
    'SAR',
    'CLP',
    'UAH',
    'AMD',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'MZN',
    'CVE',
    'KZT',
    'HNL',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'BND',
    'ZMW',
    'LAK',
    'MDL',
    'BWP',
    'MNT',
    'NAD',
    'AZN',
    'PGK',
    'PYG',
    'SCR',
    'GNF',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK'
  ],
  CVE: [
    'HRK',
    'CZK',
    'BYN',
    'BDT',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'LKR',
    'CHF',
    'FJD',
    'BWP',
    'INR',
    'HKD',
    'XCD',
    'MAD',
    'KRW',
    'QAR',
    'LBP',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'DZD',
    'XPF',
    'CAD',
    'SAR',
    'GHS',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'UAH',
    'MUR',
    'PEN',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'PKR',
    'BOB',
    'KES',
    'DOP',
    'EUR',
    'NPR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'TZS',
    'AUD',
    'ISK',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'KWD',
    'JMD',
    'RUB',
    'NGN',
    'MDL',
    'RWF',
    'DJF',
    'ZMW',
    'BAM',
    'MGA',
    'KYD',
    'ANG',
    'MNT',
    'RSD',
    'NAD',
    'PGK',
    'AMD',
    'SRD',
    'BZD',
    'BSD',
    'MKD',
    'GNF',
    'AZN',
    'MWK',
    'VUV',
    'SCR',
    'KGS',
    'MZN',
    'KZT',
    'MOP'
  ],
  CZK: [
    'BRL',
    'JPY',
    'MYR',
    'IDR',
    'SGD',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'PHP',
    'MXN',
    'SEK',
    'THB',
    'CNY',
    'LKR',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'DOP',
    'SAR',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'AMD',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'PKR',
    'KES',
    'MZN',
    'TWD',
    'CVE',
    'PEN',
    'BHD',
    'EGP',
    'JMD',
    'OMR',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'KYD',
    'MOP',
    'RWF',
    'KWD',
    'DJF',
    'RUB',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'ZMW',
    'LAK',
    'ALL',
    'BYN',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'KRW',
    'CRC',
    'NAD',
    'AZN',
    'PLN',
    'AED',
    'AUD',
    'GBP',
    'CAD',
    'HUF',
    'ZAR',
    'EUR',
    'USD'
  ],
  DJF: [
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'TRY',
    'MXN',
    'GBP',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'CNY',
    'LAK',
    'ALL',
    'PEN',
    'GEL',
    'PYG',
    'JMD',
    'TND',
    'VUV',
    'BDT',
    'BOB',
    'SCR',
    'DOP',
    'XOF',
    'RWF',
    'OMR',
    'KGS',
    'CLP',
    'MGA',
    'COP',
    'KZT',
    'TZS',
    'ISK',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'NGN',
    'CZK',
    'BYN',
    'VND',
    'RON',
    'MDL',
    'TWD',
    'BHD',
    'ZMW',
    'BAM',
    'LKR',
    'FJD',
    'BWP',
    'KYD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'GNF',
    'RSD',
    'NAD',
    'CVE',
    'QAR',
    'LBP',
    'PGK',
    'JOD',
    'XAF',
    'MWK',
    'BGN',
    'PLN',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'GHS',
    'NPR',
    'BZD',
    'ILS',
    'ETB',
    'BSD',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'UAH',
    'AZN',
    'MUR',
    'MZN'
  ],
  DKK: [
    'JPY',
    'MYR',
    'MXN',
    'SGD',
    'HUF',
    'NZD',
    'THB',
    'EUR',
    'HKD',
    'KRW',
    'USD',
    'AED',
    'TRY',
    'BRL',
    'SEK',
    'SAR',
    'CNY',
    'AUD',
    'NOK',
    'PKR',
    'KES',
    'IDR',
    'EGP',
    'GBP',
    'ZAR',
    'CHF',
    'CAD',
    'INR',
    'KWD',
    'RUB',
    'PHP',
    'TWD',
    'XCD',
    'BHD',
    'GEL',
    'PYG',
    'ZMW',
    'TND',
    'ANG',
    'SCR',
    'GNF',
    'DOP',
    'BWP',
    'MAD',
    'COP',
    'MNT',
    'CVE',
    'BAM',
    'MWK',
    'NAD',
    'LBP',
    'AMD',
    'PGK',
    'GHS',
    'NPR',
    'QAR',
    'JOD',
    'BZD',
    'BSD',
    'XAF',
    'BGN',
    'PLN',
    'KGS',
    'DZD',
    'XPF',
    'FJD',
    'MZN',
    'SRD',
    'ILS',
    'ETB',
    'UYU',
    'PEN',
    'MKD',
    'CRC',
    'LAK',
    'ALL',
    'UAH',
    'AZN',
    'JMD',
    'MUR',
    'BDT',
    'BOB',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'VUV',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'BND',
    'OMR',
    'CLP',
    'BYN',
    'ISK',
    'KZT',
    'HNL',
    'MDL',
    'ARS',
    'MOP',
    'GTQ',
    'NGN',
    'CZK',
    'RON',
    'LKR',
    'VND'
  ],
  DOP: [
    'IDR',
    'USD',
    'AED',
    'AUD',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'TRY',
    'MXN',
    'GBP',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'CNY',
    'BRL',
    'EUR',
    'GTQ',
    'EGP',
    'OMR',
    'CLP',
    'COP',
    'LKR',
    'AMD',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'XCD',
    'KWD',
    'GEL',
    'PYG',
    'RUB',
    'NGN',
    'GNF',
    'CZK',
    'SCR',
    'VND',
    'RON',
    'CVE',
    'TWD',
    'BHD',
    'ZMW',
    'BAM',
    'MWK',
    'FJD',
    'GHS',
    'NPR',
    'BWP',
    'BZD',
    'MAD',
    'BSD',
    'MNT',
    'KRW',
    'KGS',
    'NAD',
    'MZN',
    'LBP',
    'PGK',
    'QAR',
    'LAK',
    'JOD',
    'ALL',
    'PEN',
    'JMD',
    'XAF',
    'BGN',
    'PLN',
    'BDT',
    'DZD',
    'BOB',
    'XPF',
    'SRD',
    'XOF',
    'KYD',
    'DKK',
    'RWF',
    'ILS',
    'ETB',
    'UYU',
    'DJF',
    'MKD',
    'CRC',
    'RSD',
    'UAH',
    'MGA',
    'AZN',
    'MUR',
    'TZS',
    'BBD',
    'BND',
    'ANG',
    'NOK',
    'TND',
    'BYN',
    'VUV',
    'PKR',
    'KES',
    'MDL'
  ],
  DZD: [
    'PKR',
    'KES',
    'DOP',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'AED',
    'AUD',
    'ARS',
    'GHS',
    'NPR',
    'KWD',
    'RUB',
    'CZK',
    'RON',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'ALL',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'HUF',
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'XOF',
    'INR',
    'HKD',
    'KRW',
    'NGN',
    'VND',
    'TZS',
    'LBP',
    'QAR',
    'JOD',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'XPF',
    'FJD',
    'CAD',
    'SAR',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'UAH',
    'MUR',
    'LKR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'TND',
    'BRL',
    'GNF',
    'SCR',
    'CVE',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK',
    'KZT',
    'MOP',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'MZN',
    'LAK',
    'ZMW',
    'HNL',
    'KYD',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'BBD',
    'PGK',
    'BND',
    'ANG',
    'MDL',
    'BWP',
    'MNT',
    'NAD',
    'AZN',
    'AMD',
    'PYG'
  ],
  EGP: [
    'PHP',
    'VND',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'THB',
    'EUR',
    'DKK',
    'ILS',
    'KRW',
    'CNY',
    'USD',
    'QAR',
    'TRY',
    'NOK',
    'PLN',
    'BRL',
    'SAR',
    'CLP',
    'AED',
    'AUD',
    'ARS',
    'CZK',
    'PKR',
    'KES',
    'JPY',
    'TWD',
    'MYR',
    'IDR',
    'SGD',
    'OMR',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'HKD',
    'KWD',
    'RUB',
    'RON',
    'TZS',
    'BBD',
    'BND',
    'ZMW',
    'LAK',
    'ALL',
    'BYN',
    'MDL',
    'BWP',
    'MAD',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'LBP',
    'PGK',
    'JOD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'DOP',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK',
    'UAH',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'MZN',
    'CVE',
    'PEN',
    'BHD',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'NGN',
    'RSD',
    'MGA'
  ],
  ETB: [
    'CAD',
    'CNY',
    'EUR',
    'USD',
    'AUD',
    'JPY',
    'MYR',
    'SGD',
    'CHF',
    'INR',
    'GBP',
    'BYN',
    'THB',
    'DZD',
    'XPF',
    'FJD',
    'SAR',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'UAH',
    'AZN',
    'MUR',
    'LKR',
    'AMD',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'GNF',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'CVE',
    'SRD',
    'IDR',
    'EGP',
    'MKD',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'AED',
    'KZT',
    'MOP',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'KWD',
    'BSD',
    'RUB',
    'CZK',
    'VUV',
    'KGS',
    'RON',
    'MZN',
    'PHP',
    'TWD',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'ZMW',
    'JMD',
    'HUF',
    'NZD',
    'BDT',
    'BOB',
    'ISK',
    'HNL',
    'XOF',
    'KYD',
    'HKD',
    'RWF',
    'DJF',
    'KRW',
    'NGN',
    'RSD',
    'MGA',
    'VND',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'MXN',
    'ANG',
    'XAF',
    'BGN',
    'PLN',
    'ZAR',
    'SEK'
  ],
  EUR: [
    'EGP',
    'OMR',
    'CLP',
    'BYN',
    'CHF',
    'ARS',
    'INR',
    'KWD',
    'RUB',
    'NGN',
    'LKR',
    'PHP',
    'VND',
    'JPY',
    'TWD',
    'BHD',
    'GEL',
    'SGD',
    'TND',
    'NZD',
    'HKD',
    'DKK',
    'MAD',
    'COP',
    'KRW',
    'QAR',
    'JOD',
    'XAF',
    'BRL',
    'SEK',
    'FJD',
    'SAR',
    'UZS',
    'PEN',
    'CNY',
    'UAH',
    'BDT',
    'XOF',
    'NOK',
    'PKR',
    'KES',
    'BBD',
    'BND',
    'ISK',
    'KZT',
    'HNL',
    'MDL',
    'MOP',
    'GTQ',
    'XCD',
    'PYG',
    'ZMW',
    'ANG',
    'SCR',
    'GNF',
    'DOP',
    'BWP',
    'MNT',
    'CVE',
    'BAM',
    'MWK',
    'NAD',
    'LBP',
    'AMD',
    'PGK',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'BGN',
    'KGS',
    'DZD',
    'XPF',
    'MZN',
    'SRD',
    'ETB',
    'UYU',
    'MKD',
    'CRC',
    'LAK',
    'ALL',
    'AZN',
    'JMD',
    'MUR',
    'BOB',
    'KYD',
    'RWF',
    'DJF',
    'VUV',
    'RSD',
    'MGA',
    'TZS',
    'IDR',
    'GBP',
    'ZAR',
    'CAD',
    'CZK',
    'RON',
    'MYR',
    'MXN',
    'HUF',
    'THB',
    'HRK',
    'USD',
    'AED',
    'TRY',
    'PLN',
    'ILS',
    'AUD',
    'CYP',
    'VES'
  ],
  FJD: [
    'KES',
    'JPY',
    'TWD',
    'MYR',
    'IDR',
    'SGD',
    'OMR',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'HKD',
    'KWD',
    'RUB',
    'PHP',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'THB',
    'EUR',
    'DKK',
    'ILS',
    'KRW',
    'CNY',
    'USD',
    'QAR',
    'TRY',
    'NOK',
    'PLN',
    'BRL',
    'SAR',
    'EGP',
    'CLP',
    'AED',
    'AUD',
    'ARS',
    'PKR',
    'MZN',
    'CVE',
    'PEN',
    'BHD',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'ZMW',
    'LAK',
    'ALL',
    'BYN',
    'MDL',
    'BWP',
    'MAD',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'LBP',
    'PGK',
    'JOD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'DOP',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK',
    'UAH',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'ANG',
    'CZK',
    'VUV',
    'KGS'
  ],
  GBP: [
    'CNY',
    'NOK',
    'PKR',
    'KES',
    'EGP',
    'OMR',
    'CLP',
    'CHF',
    'ARS',
    'INR',
    'KWD',
    'RUB',
    'PHP',
    'VND',
    'JPY',
    'TWD',
    'SGD',
    'NZD',
    'HKD',
    'DKK',
    'MAD',
    'COP',
    'KRW',
    'QAR',
    'BRL',
    'SEK',
    'SAR',
    'ETB',
    'UYU',
    'UZS',
    'PEN',
    'MKD',
    'CRC',
    'JMD',
    'LAK',
    'ALL',
    'UAH',
    'AZN',
    'MUR',
    'BDT',
    'BOB',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'VUV',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'BND',
    'BYN',
    'ISK',
    'KZT',
    'HNL',
    'MDL',
    'MOP',
    'GTQ',
    'NGN',
    'LKR',
    'XCD',
    'BHD',
    'GEL',
    'PYG',
    'ZMW',
    'TND',
    'ANG',
    'SCR',
    'GNF',
    'DOP',
    'BWP',
    'MNT',
    'CVE',
    'BAM',
    'MWK',
    'NAD',
    'LBP',
    'AMD',
    'PGK',
    'GHS',
    'NPR',
    'JOD',
    'BZD',
    'BSD',
    'XAF',
    'BGN',
    'KGS',
    'DZD',
    'XPF',
    'FJD',
    'MZN',
    'SRD',
    'ILS',
    'AUD',
    'IDR',
    'ZAR',
    'CAD',
    'EUR',
    'CZK',
    'RON',
    'MYR',
    'MXN',
    'HUF',
    'THB',
    'HRK',
    'USD',
    'AED',
    'TRY',
    'PLN'
  ],
  GEL: [
    'DKK',
    'ILS',
    'CNY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'COP',
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'QAR',
    'TRY',
    'MXN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'RWF',
    'ETB',
    'UYU',
    'DJF',
    'MKD',
    'CRC',
    'RSD',
    'UAH',
    'MGA',
    'AZN',
    'MUR',
    'TZS',
    'BBD',
    'BND',
    'ANG',
    'TND',
    'BYN',
    'VUV',
    'DOP',
    'MDL',
    'GTQ',
    'CLP',
    'LKR',
    'AMD',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'XCD',
    'PYG',
    'NGN',
    'GNF',
    'CZK',
    'SCR',
    'VND',
    'RON',
    'CVE',
    'TWD',
    'BHD',
    'ZMW',
    'BAM',
    'MWK',
    'FJD',
    'GHS',
    'NPR',
    'BWP',
    'BZD',
    'MAD',
    'BSD',
    'MNT',
    'KGS',
    'NAD',
    'MZN',
    'LBP',
    'PGK',
    'LAK',
    'JOD',
    'ALL',
    'PEN',
    'JMD',
    'XAF',
    'BGN',
    'BDT',
    'DZD',
    'BOB',
    'XPF',
    'SRD',
    'XOF',
    'KYD'
  ],
  GHS: [
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'CNY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'QAR',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'BZD',
    'ETB',
    'BSD',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'GNF',
    'UAH',
    'AZN',
    'CVE',
    'MUR',
    'PEN',
    'GEL',
    'PYG',
    'TND',
    'MWK',
    'VUV',
    'SCR',
    'DOP',
    'NPR',
    'OMR',
    'KGS',
    'CLP',
    'COP',
    'MZN',
    'ISK',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'JMD',
    'NGN',
    'CZK',
    'BDT',
    'BOB',
    'VND',
    'RON',
    'XOF',
    'RWF',
    'TWD',
    'DJF',
    'BHD',
    'ZMW',
    'BAM',
    'MGA',
    'LKR',
    'TZS',
    'FJD',
    'BWP',
    'KYD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'BYN',
    'RSD',
    'NAD',
    'MDL',
    'LBP',
    'PGK',
    'JOD'
  ],
  GNF: [
    'THB',
    'DZD',
    'XPF',
    'CAD',
    'SAR',
    'GHS',
    'NPR',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'UAH',
    'MUR',
    'PEN',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'PKR',
    'BOB',
    'KES',
    'DOP',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'TZS',
    'AUD',
    'ISK',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'KWD',
    'JMD',
    'RUB',
    'NGN',
    'CZK',
    'BYN',
    'BDT',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'LKR',
    'CHF',
    'FJD',
    'BWP',
    'INR',
    'HKD',
    'XCD',
    'MAD',
    'KRW',
    'NAD',
    'QAR',
    'LBP',
    'PGK',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'AMD',
    'SRD',
    'BZD',
    'BSD',
    'MKD',
    'AZN',
    'MWK',
    'VUV',
    'SCR',
    'KGS',
    'MZN',
    'KZT',
    'MOP',
    'MDL',
    'RWF',
    'DJF',
    'ZMW',
    'BAM',
    'MGA',
    'KYD',
    'ANG',
    'MNT',
    'RSD',
    'CVE'
  ],
  GTQ: [
    'MXN',
    'GBP',
    'ZAR',
    'THB',
    'CAD',
    'CNY',
    'EUR',
    'USD',
    'AED',
    'AUD',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'LAK',
    'JOD',
    'ALL',
    'PEN',
    'TRY',
    'JMD',
    'XAF',
    'BGN',
    'PLN',
    'SEK',
    'BDT',
    'DZD',
    'BOB',
    'XPF',
    'SRD',
    'XOF',
    'SAR',
    'KYD',
    'DKK',
    'RWF',
    'ILS',
    'ETB',
    'UYU',
    'DJF',
    'MKD',
    'CRC',
    'UAH',
    'MGA',
    'AZN',
    'MUR',
    'TZS',
    'BBD',
    'BND',
    'ANG',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'BYN',
    'VUV',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'MDL',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'COP',
    'LKR',
    'AMD',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'XCD',
    'KWD',
    'RUB',
    'NGN',
    'GNF',
    'CZK',
    'VND',
    'RON',
    'PHP',
    'CVE',
    'TWD',
    'BHD',
    'ZMW',
    'BAM',
    'MWK',
    'FJD',
    'BWP',
    'GHS',
    'NPR',
    'BZD',
    'MAD',
    'BSD',
    'MNT',
    'KRW',
    'RSD',
    'KGS',
    'NAD',
    'MZN',
    'LBP',
    'PGK',
    'QAR'
  ],
  HKD: [
    'DKK',
    'MAD',
    'COP',
    'KRW',
    'USD',
    'AED',
    'QAR',
    'JOD',
    'TRY',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'SEK',
    'DZD',
    'FJD',
    'SAR',
    'ILS',
    'PEN',
    'CNY',
    'UAH',
    'GBP',
    'BDT',
    'AUD',
    'XOF',
    'NOK',
    'PKR',
    'KES',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'ZAR',
    'BYN',
    'CHF',
    'CAD',
    'ARS',
    'INR',
    'EUR',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'RON',
    'LKR',
    'PHP',
    'VND',
    'JPY',
    'TWD',
    'MYR',
    'MXN',
    'BHD',
    'GEL',
    'SGD',
    'TND',
    'HUF',
    'NZD',
    'THB',
    'MNT',
    'CVE',
    'BAM',
    'MWK',
    'NAD',
    'LBP',
    'AMD',
    'PGK',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'KGS',
    'XPF',
    'MZN',
    'SRD',
    'ETB',
    'UYU',
    'MKD',
    'CRC',
    'JMD',
    'LAK',
    'ALL',
    'AZN',
    'MUR',
    'BOB',
    'KYD',
    'RWF',
    'DJF',
    'VUV',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'BND',
    'ISK',
    'KZT',
    'HNL',
    'MDL',
    'MOP',
    'GTQ',
    'XCD',
    'PYG',
    'ZMW',
    'ANG',
    'SCR',
    'GNF',
    'DOP',
    'BWP'
  ],
  HNL: [
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'XOF',
    'INR',
    'HKD',
    'KRW',
    'NGN',
    'VND',
    'TZS',
    'BBD',
    'LBP',
    'BND',
    'QAR',
    'JOD',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'DZD',
    'XPF',
    'FJD',
    'CAD',
    'SAR',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'UAH',
    'MUR',
    'LKR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'TND',
    'BRL',
    'PKR',
    'KES',
    'DOP',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'AED',
    'AUD',
    'ARS',
    'GHS',
    'NPR',
    'KWD',
    'RUB',
    'CZK',
    'RON',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'HUF',
    'KYD',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'PGK',
    'ANG',
    'MDL',
    'ETB',
    'MNT',
    'NAD',
    'AZN',
    'AMD',
    'PYG',
    'GNF',
    'SCR',
    'CVE',
    'SRD',
    'MKD',
    'BAM',
    'MWK',
    'KZT',
    'MOP',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'MZN',
    'ZMW'
  ],
  HRK: ['ANG', 'AMD', 'LAK', 'CVE', 'ALL', 'GBP', 'CAD', 'EUR', 'USD'],
  HUF: [
    'UAH',
    'LKR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'TND',
    'BRL',
    'DZD',
    'PKR',
    'KES',
    'DOP',
    'ALL',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'MUR',
    'ARS',
    'GHS',
    'NPR',
    'KWD',
    'RUB',
    'RON',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'XOF',
    'INR',
    'HKD',
    'KRW',
    'NGN',
    'VND',
    'TZS',
    'BBD',
    'LBP',
    'QAR',
    'JOD',
    'MXN',
    'XAF',
    'BGN',
    'SEK',
    'BYN',
    'THB',
    'XPF',
    'FJD',
    'SAR',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'NAD',
    'AZN',
    'PYG',
    'GNF',
    'SCR',
    'SRD',
    'ETB',
    'LAK',
    'MKD',
    'BAM',
    'MWK',
    'KZT',
    'MOP',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'MZN',
    'ZMW',
    'HNL',
    'KYD',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'AMD',
    'PGK',
    'BND',
    'ANG',
    'MDL',
    'CVE',
    'BWP',
    'MNT',
    'EUR',
    'USD',
    'AED',
    'AUD',
    'CZK',
    'GBP',
    'PLN',
    'ZAR',
    'CAD'
  ],
  IDR: [
    'DOP',
    'SAR',
    'EGP',
    'CLP',
    'UAH',
    'ARS',
    'CZK',
    'PKR',
    'KES',
    'JPY',
    'TWD',
    'PEN',
    'BHD',
    'SGD',
    'OMR',
    'NZD',
    'BDT',
    'CHF',
    'ISK',
    'XOF',
    'INR',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'RON',
    'PHP',
    'VND',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'FJD',
    'DKK',
    'MAD',
    'ILS',
    'KRW',
    'CNY',
    'LKR',
    'QAR',
    'JOD',
    'TRY',
    'GEL',
    'NOK',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'MZN',
    'CVE',
    'JMD',
    'BOB',
    'KZT',
    'HNL',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'BND',
    'ZMW',
    'LAK',
    'ALL',
    'MDL',
    'BWP',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LBP',
    'PGK',
    'XCD',
    'PYG',
    'GNF',
    'XPF',
    'SCR',
    'AED',
    'AUD',
    'MYR',
    'GBP',
    'CAD',
    'EUR',
    'USD'
  ],
  ILS: [
    'NZD',
    'THB',
    'CHF',
    'INR',
    'HKD',
    'HUF',
    'CNY',
    'AED',
    'JPY',
    'MYR',
    'SGD',
    'JMD',
    'LAK',
    'OMR',
    'ALL',
    'CLP',
    'ZAR',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'ARS',
    'KYD',
    'MOP',
    'RWF',
    'KWD',
    'DJF',
    'RUB',
    'NGN',
    'CZK',
    'RSD',
    'MGA',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'MXN',
    'BHD',
    'ZMW',
    'SEK',
    'BYN',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'COP',
    'MNT',
    'GTQ',
    'KRW',
    'NAD',
    'LKR',
    'LBP',
    'AMD',
    'PGK',
    'QAR',
    'JOD',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'ANG',
    'BRL',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SAR',
    'SRD',
    'ETB',
    'UYU',
    'CVE',
    'MKD',
    'CRC',
    'BAM',
    'MWK',
    'UAH',
    'AZN',
    'MUR',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'PKR',
    'KES',
    'MZN',
    'TWD',
    'PEN',
    'IDR',
    'EGP',
    'CAD',
    'EUR',
    'USD',
    'PLN',
    'GBP',
    'AUD'
  ],
  INR: [
    'CAD',
    'EUR',
    'USD',
    'AUD',
    'GBP',
    'JPY',
    'MYR',
    'SGD',
    'CHF',
    'DOP',
    'BWP',
    'HKD',
    'MAD',
    'COP',
    'MNT',
    'CVE',
    'KRW',
    'BAM',
    'MWK',
    'NAD',
    'AED',
    'LBP',
    'AMD',
    'PGK',
    'GHS',
    'NPR',
    'QAR',
    'JOD',
    'BZD',
    'TRY',
    'BSD',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'SEK',
    'KGS',
    'DZD',
    'XPF',
    'FJD',
    'SAR',
    'SRD',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'PEN',
    'CNY',
    'MKD',
    'CRC',
    'LAK',
    'ALL',
    'UAH',
    'AZN',
    'JMD',
    'MUR',
    'BDT',
    'BOB',
    'XOF',
    'KYD',
    'RWF',
    'NOK',
    'DJF',
    'VUV',
    'RSD',
    'PKR',
    'MGA',
    'KES',
    'TZS',
    'MZN',
    'BBD',
    'BND',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'BYN',
    'ISK',
    'KZT',
    'HNL',
    'MDL',
    'ARS',
    'MOP',
    'KWD',
    'GTQ',
    'RUB',
    'NGN',
    'CZK',
    'RON',
    'LKR',
    'PHP',
    'VND',
    'TWD',
    'XCD',
    'MXN',
    'BHD',
    'GEL',
    'PYG',
    'TND',
    'ANG',
    'HUF',
    'ZAR',
    'NZD',
    'THB',
    'SCR',
    'GNF',
    'ZMW'
  ],
  ISK: [
    'AED',
    'AUD',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'KWD',
    'RUB',
    'CZK',
    'RON',
    'MZN',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'HUF',
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'XOF',
    'INR',
    'KYD',
    'HKD',
    'KRW',
    'NGN',
    'RSD',
    'MGA',
    'VND',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'DZD',
    'XPF',
    'FJD',
    'CAD',
    'SAR',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'NAD',
    'UAH',
    'AZN',
    'MUR',
    'LKR',
    'AMD',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'GNF',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'BSD',
    'VUV',
    'KGS',
    'ZMW',
    'RWF',
    'DJF',
    'ANG',
    'MDL',
    'MNT',
    'CVE',
    'SRD',
    'MKD'
  ],
  JMD: [
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'AUD',
    'ARS',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'LKR',
    'CHF',
    'INR',
    'HKD',
    'MAD',
    'KRW',
    'QAR',
    'JOD',
    'TRY',
    'MXN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'CNY',
    'UAH',
    'PEN',
    'NOK',
    'TND',
    'BRL',
    'BDT',
    'PKR',
    'DOP',
    'KGS',
    'MGA',
    'KZT',
    'MZN',
    'TZS',
    'ISK',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'BYN',
    'MDL',
    'RWF',
    'DJF',
    'ZMW',
    'BAM',
    'FJD',
    'BWP',
    'KYD',
    'XCD',
    'ANG',
    'MNT',
    'GNF',
    'RSD',
    'NAD',
    'CVE',
    'LBP',
    'PGK',
    'XAF',
    'MWK',
    'BGN',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'GHS',
    'NPR',
    'BZD',
    'ETB',
    'BSD',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'AZN',
    'MUR',
    'LAK',
    'ALL',
    'GEL',
    'PYG',
    'VUV',
    'BOB',
    'SCR'
  ],
  JOD: [
    'EUR',
    'DKK',
    'MAD',
    'ILS',
    'KRW',
    'CNY',
    'USD',
    'LKR',
    'QAR',
    'TRY',
    'GEL',
    'NOK',
    'TND',
    'XAF',
    'PLN',
    'BRL',
    'SAR',
    'IDR',
    'EGP',
    'CLP',
    'UAH',
    'AED',
    'AUD',
    'ARS',
    'CZK',
    'PKR',
    'KES',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'BHD',
    'SGD',
    'OMR',
    'GBP',
    'NZD',
    'BDT',
    'CHF',
    'CAD',
    'XOF',
    'INR',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'RON',
    'PHP',
    'VND',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'THB',
    'FJD',
    'MDL',
    'BWP',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LBP',
    'PGK',
    'XCD',
    'PYG',
    'BGN',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'DOP',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'MZN',
    'CVE',
    'JMD',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'BND',
    'ZMW',
    'LAK',
    'ALL',
    'BYN'
  ],
  JPY: [
    'HKD',
    'KWD',
    'GTQ',
    'RUB',
    'NGN',
    'CZK',
    'RON',
    'LKR',
    'PHP',
    'VND',
    'TWD',
    'MYR',
    'XCD',
    'MXN',
    'BHD',
    'GEL',
    'PYG',
    'SGD',
    'TND',
    'HUF',
    'NZD',
    'THB',
    'SCR',
    'GNF',
    'DOP',
    'BWP',
    'DKK',
    'MAD',
    'COP',
    'KRW',
    'USD',
    'BAM',
    'MWK',
    'NAD',
    'AED',
    'LBP',
    'AMD',
    'PGK',
    'GHS',
    'NPR',
    'QAR',
    'JOD',
    'BZD',
    'TRY',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'SEK',
    'DZD',
    'XPF',
    'FJD',
    'MZN',
    'SAR',
    'ILS',
    'ETB',
    'UYU',
    'PEN',
    'CNY',
    'CRC',
    'JMD',
    'LAK',
    'ALL',
    'UAH',
    'GBP',
    'AZN',
    'MUR',
    'BDT',
    'BOB',
    'AUD',
    'XOF',
    'KYD',
    'NOK',
    'RSD',
    'PKR',
    'MGA',
    'KES',
    'TZS',
    'BBD',
    'BND',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'ZAR',
    'BYN',
    'CHF',
    'ISK',
    'KZT',
    'CAD',
    'HNL',
    'ARS',
    'INR',
    'EUR',
    'MOP',
    'ZMW',
    'ANG',
    'MNT',
    'CVE',
    'BSD',
    'KGS',
    'SRD',
    'MKD',
    'RWF',
    'DJF',
    'VUV',
    'MDL'
  ],
  KES: [
    'CAD',
    'HNL',
    'XOF',
    'INR',
    'MOP',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'JOD',
    'MXN',
    'LAK',
    'ALL',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'FJD',
    'EUR',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'GTQ',
    'KRW',
    'CNY',
    'USD',
    'CRC',
    'NAD',
    'LKR',
    'LBP',
    'PGK',
    'QAR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'XPF',
    'DOP',
    'SAR',
    'ETB',
    'IDR',
    'EGP',
    'BAM',
    'CLP',
    'UAH',
    'MUR',
    'AED',
    'AUD',
    'ARS',
    'GHS',
    'NPR',
    'CZK',
    'PKR',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'OMR',
    'GBP',
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'KYD',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'ZMW',
    'MDL',
    'MNT',
    'AZN',
    'GNF',
    'SCR',
    'SRD',
    'MKD',
    'MWK',
    'AMD',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'MZN',
    'CVE',
    'KZT'
  ],
  KGS: [
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'LKR',
    'CHF',
    'FJD',
    'INR',
    'HKD',
    'MAD',
    'KRW',
    'QAR',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'DZD',
    'CAD',
    'SAR',
    'GHS',
    'DKK',
    'ILS',
    'UYU',
    'CNY',
    'CRC',
    'UAH',
    'MUR',
    'PEN',
    'GEL',
    'NOK',
    'JMD',
    'TND',
    'BRL',
    'BDT',
    'PKR',
    'KES',
    'DOP',
    'XOF',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'AUD',
    'ISK',
    'ARS',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'BYN',
    'VND',
    'RON',
    'PHP',
    'BAM',
    'ZMW',
    'BWP',
    'KYD',
    'ANG',
    'MNT',
    'GNF',
    'RSD',
    'NAD',
    'CVE',
    'LBP',
    'PGK',
    'MWK',
    'AMD',
    'XPF',
    'NPR',
    'SRD',
    'BZD',
    'ETB',
    'BSD',
    'GTQ',
    'MKD',
    'AZN',
    'MZN',
    'LAK',
    'ALL',
    'PYG',
    'VUV',
    'BOB',
    'SCR',
    'RWF',
    'DJF',
    'MGA',
    'KZT',
    'TZS',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'XCD',
    'MDL'
  ],
  KRW: [
    'JPY',
    'MYR',
    'SGD',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'HKD',
    'MXN',
    'HUF',
    'ZAR',
    'THB',
    'EUR',
    'CNY',
    'USD',
    'AED',
    'AUD',
    'AMD',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'ANG',
    'CZK',
    'VUV',
    'KGS',
    'PKR',
    'MZN',
    'TWD',
    'CVE',
    'PEN',
    'BHD',
    'JMD',
    'OMR',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'KYD',
    'MOP',
    'RWF',
    'KWD',
    'RUB',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'JOD',
    'LAK',
    'ALL',
    'SEK',
    'BYN',
    'FJD',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'LBP',
    'PGK',
    'QAR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'KES',
    'DOP',
    'SAR',
    'SRD',
    'ETB',
    'IDR',
    'EGP',
    'MKD',
    'BAM',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'DJF',
    'ZMW'
  ],
  KWD: [
    'TRY',
    'BRL',
    'KES',
    'SAR',
    'IDR',
    'AED',
    'AUD',
    'JPY',
    'MYR',
    'SGD',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'HKD',
    'PHP',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'THB',
    'EUR',
    'CNY',
    'USD',
    'LBP',
    'PGK',
    'QAR',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'DOP',
    'SRD',
    'ETB',
    'EGP',
    'MKD',
    'BAM',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'AMD',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'ANG',
    'CZK',
    'VUV',
    'KGS',
    'PKR',
    'MZN',
    'TWD',
    'CVE',
    'PEN',
    'BHD',
    'JMD',
    'OMR',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'RUB',
    'KRW',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'JOD',
    'ZMW',
    'LAK',
    'ALL',
    'BYN',
    'FJD',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR'
  ],
  KYD: [
    'SGD',
    'INR',
    'GBP',
    'CAD',
    'EUR',
    'USD',
    'AUD',
    'BHD',
    'BAM',
    'MWK',
    'HUF',
    'NZD',
    'CHF',
    'FJD',
    'BWP',
    'GHS',
    'NPR',
    'BZD',
    'HKD',
    'MAD',
    'MNT',
    'KRW',
    'RSD',
    'KGS',
    'NAD',
    'MZN',
    'LBP',
    'PGK',
    'QAR',
    'LAK',
    'JOD',
    'ALL',
    'PEN',
    'TRY',
    'MXN',
    'JMD',
    'XAF',
    'BGN',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'BDT',
    'DZD',
    'BOB',
    'XPF',
    'XOF',
    'SAR',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'MKD',
    'CRC',
    'UAH',
    'MGA',
    'AZN',
    'MUR',
    'TZS',
    'BBD',
    'BND',
    'ANG',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'BYN',
    'VUV',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'MDL',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'LKR',
    'AMD',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'XCD',
    'KWD',
    'RUB',
    'NGN',
    'GNF',
    'CZK',
    'VND',
    'RON',
    'PHP',
    'CVE',
    'JPY',
    'TWD',
    'MYR',
    'ZMW',
    'BSD',
    'SRD',
    'RWF',
    'DJF'
  ],
  KZT: [
    'JOD',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'FJD',
    'EUR',
    'DKK',
    'MAD',
    'ILS',
    'CNY',
    'USD',
    'CRC',
    'LKR',
    'QAR',
    'TRY',
    'GEL',
    'NOK',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'KES',
    'DOP',
    'SAR',
    'IDR',
    'EGP',
    'CLP',
    'UAH',
    'AED',
    'AUD',
    'ARS',
    'KWD',
    'CZK',
    'PKR',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'BHD',
    'SGD',
    'OMR',
    'GBP',
    'NZD',
    'BDT',
    'CHF',
    'ISK',
    'CAD',
    'XOF',
    'INR',
    'HKD',
    'RUB',
    'KRW',
    'NGN',
    'RON',
    'PHP',
    'VND',
    'BBD',
    'BND',
    'ZMW',
    'LAK',
    'ALL',
    'MDL',
    'BWP',
    'UYU',
    'MNT',
    'GTQ',
    'NAD',
    'AZN',
    'LBP',
    'PGK',
    'XCD',
    'PYG',
    'GNF',
    'XPF',
    'SCR',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'MZN',
    'CVE',
    'JMD',
    'BOB',
    'HNL',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'TZS'
  ],
  LAK: [
    'HRK',
    'QAR',
    'LBP',
    'PGK',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'MWK',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'AMD',
    'DZD',
    'XPF',
    'CAD',
    'SAR',
    'GHS',
    'NPR',
    'BZD',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'UAH',
    'AZN',
    'MUR',
    'ALL',
    'PEN',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'BDT',
    'PKR',
    'BOB',
    'SCR',
    'KES',
    'DOP',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'KGS',
    'CLP',
    'COP',
    'KZT',
    'AED',
    'MZN',
    'TZS',
    'AUD',
    'ISK',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'KWD',
    'JMD',
    'RUB',
    'NGN',
    'CZK',
    'BYN',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'MDL',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'BAM',
    'HUF',
    'MGA',
    'NZD',
    'LKR',
    'CHF',
    'FJD',
    'BWP',
    'INR',
    'KYD',
    'HKD',
    'XCD',
    'MAD',
    'MNT',
    'KRW',
    'GNF',
    'RSD',
    'NAD',
    'CVE',
    'SRD',
    'BSD',
    'MKD',
    'VUV',
    'RWF',
    'DJF',
    'ZMW',
    'ANG'
  ],
  LBP: [
    'SGD',
    'GBP',
    'CHF',
    'CAD',
    'INR',
    'EUR',
    'USD',
    'AUD',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'BHD',
    'JMD',
    'OMR',
    'NZD',
    'BDT',
    'BOB',
    'ISK',
    'HNL',
    'XOF',
    'KYD',
    'MOP',
    'HKD',
    'RUB',
    'KRW',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'JOD',
    'MXN',
    'LAK',
    'ALL',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'FJD',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'CNY',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'PGK',
    'QAR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'KES',
    'DOP',
    'SAR',
    'ETB',
    'IDR',
    'EGP',
    'BAM',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'AED',
    'KZT',
    'AMD',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'KWD',
    'CZK',
    'KGS',
    'PKR',
    'MZN',
    'CVE',
    'RWF',
    'DJF',
    'ZMW',
    'MDL',
    'SRD',
    'MKD',
    'BSD',
    'ANG',
    'VUV'
  ],
  LKR: [
    'EUR',
    'USD',
    'AUD',
    'SGD',
    'INR',
    'GBP',
    'CAD',
    'GTQ',
    'CNY',
    'MKD',
    'CRC',
    'GNF',
    'UAH',
    'AZN',
    'MUR',
    'CVE',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'MWK',
    'BRL',
    'VUV',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'IDR',
    'EGP',
    'OMR',
    'KGS',
    'CLP',
    'COP',
    'AED',
    'MZN',
    'ISK',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'PEN',
    'KWD',
    'JMD',
    'RUB',
    'NGN',
    'CZK',
    'BDT',
    'BOB',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'JPY',
    'RWF',
    'TWD',
    'MYR',
    'DJF',
    'BHD',
    'ZMW',
    'BAM',
    'HUF',
    'MGA',
    'NZD',
    'CHF',
    'TZS',
    'FJD',
    'BWP',
    'KYD',
    'HKD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'KRW',
    'BYN',
    'RSD',
    'NAD',
    'MDL',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'SAR',
    'DKK',
    'ILS',
    'ETB',
    'UYU'
  ],
  MAD: [
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'CNY',
    'TRY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'MXN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'SRD',
    'MDL',
    'BWP',
    'ETB',
    'UYU',
    'MNT',
    'GTQ',
    'MKD',
    'CRC',
    'NAD',
    'UAH',
    'AZN',
    'MUR',
    'LKR',
    'AMD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'GNF',
    'SCR',
    'DOP',
    'CVE',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'NGN',
    'CZK',
    'VUV',
    'KGS',
    'VND',
    'RON',
    'MZN',
    'TWD',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'ZMW',
    'JMD',
    'BDT',
    'FJD',
    'BOB',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'ANG',
    'XAF',
    'BGN',
    'BYN',
    'DZD',
    'XPF'
  ],
  MDL: [
    'HUF',
    'NZD',
    'LKR',
    'CHF',
    'TZS',
    'FJD',
    'INR',
    'HKD',
    'XCD',
    'MAD',
    'KRW',
    'BYN',
    'LBP',
    'QAR',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'DZD',
    'XPF',
    'CAD',
    'SAR',
    'GHS',
    'DKK',
    'ILS',
    'UYU',
    'CNY',
    'CRC',
    'UAH',
    'MUR',
    'PEN',
    'GEL',
    'NOK',
    'TND',
    'BRL',
    'PKR',
    'KES',
    'DOP',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'AUD',
    'ISK',
    'ARS',
    'ALL',
    'KWD',
    'JMD',
    'RUB',
    'NGN',
    'CZK',
    'BDT',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'MGA',
    'BWP',
    'KYD',
    'ANG',
    'MNT',
    'RSD',
    'NAD',
    'PGK',
    'AMD',
    'SRD',
    'BZD',
    'ETB',
    'BSD',
    'GTQ',
    'MKD',
    'GNF',
    'AZN',
    'CVE',
    'PYG',
    'MWK',
    'VUV',
    'SCR',
    'NPR',
    'KGS',
    'MZN',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'LAK',
    'BOB',
    'RWF',
    'DJF',
    'ZMW',
    'BAM'
  ],
  MGA: [
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'TRY',
    'MXN',
    'GBP',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'CNY',
    'NOK',
    'BRL',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'AED',
    'AUD',
    'VND',
    'RON',
    'XOF',
    'MDL',
    'RWF',
    'TWD',
    'DJF',
    'BHD',
    'ZMW',
    'BAM',
    'LKR',
    'FJD',
    'BWP',
    'KYD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'GNF',
    'RSD',
    'NAD',
    'CVE',
    'QAR',
    'LBP',
    'PGK',
    'JOD',
    'XAF',
    'MWK',
    'BGN',
    'PLN',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'GHS',
    'NPR',
    'BZD',
    'DKK',
    'ILS',
    'ETB',
    'BSD',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'UAH',
    'AZN',
    'MUR',
    'LAK',
    'ALL',
    'PEN',
    'GEL',
    'PYG',
    'TND',
    'VUV',
    'BDT',
    'PKR',
    'BOB',
    'SCR',
    'DOP',
    'OMR',
    'KGS',
    'CLP',
    'COP',
    'KZT',
    'MZN',
    'TZS',
    'ISK',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'KWD',
    'JMD',
    'RUB',
    'NGN',
    'CZK',
    'BYN'
  ],
  MKD: [
    'TRY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'MXN',
    'GBP',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'CNY',
    'MUR',
    'LKR',
    'AMD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'GNF',
    'SCR',
    'DOP',
    'CVE',
    'SRD',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'CZK',
    'VUV',
    'KGS',
    'RON',
    'MZN',
    'TWD',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'ZMW',
    'JMD',
    'BDT',
    'BOB',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'NGN',
    'RSD',
    'MGA',
    'VND',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'ANG',
    'XAF',
    'BGN',
    'PLN',
    'BYN',
    'DZD',
    'XPF',
    'FJD',
    'MDL',
    'BWP',
    'MAD',
    'ETB',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'UAH',
    'AZN'
  ],
  MNT: [
    'ARS',
    'KWD',
    'RUB',
    'CZK',
    'VND',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'MAD',
    'KRW',
    'QAR',
    'JOD',
    'MXN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'CNY',
    'TRY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'AED',
    'AUD',
    'MOP',
    'HNL',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'NGN',
    'VUV',
    'KGS',
    'RON',
    'MZN',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'ZMW',
    'JMD',
    'BDT',
    'FJD',
    'BOB',
    'XOF',
    'KYD',
    'BWP',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'ANG',
    'XAF',
    'BGN',
    'BYN',
    'DZD',
    'XPF',
    'SRD',
    'MDL',
    'ETB',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'NAD',
    'UAH',
    'AZN',
    'MUR',
    'LKR',
    'AMD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'GNF',
    'SCR',
    'DOP',
    'CVE',
    'BAM',
    'MWK',
    'ISK',
    'KZT'
  ],
  MOP: [
    'AUD',
    'JPY',
    'MYR',
    'SGD',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'THB',
    'EUR',
    'CNY',
    'USD',
    'ETB',
    'IDR',
    'EGP',
    'MKD',
    'BAM',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'AED',
    'KZT',
    'AMD',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'KWD',
    'BSD',
    'ANG',
    'CZK',
    'VUV',
    'KGS',
    'PKR',
    'MZN',
    'TWD',
    'CVE',
    'PEN',
    'BHD',
    'JMD',
    'OMR',
    'BDT',
    'BOB',
    'ISK',
    'HNL',
    'XOF',
    'KYD',
    'HKD',
    'RWF',
    'DJF',
    'RUB',
    'KRW',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'LBP',
    'BND',
    'JOD',
    'MXN',
    'ZMW',
    'LAK',
    'ALL',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'FJD',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'PGK',
    'QAR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'KES',
    'DOP',
    'SAR',
    'SRD'
  ],
  MUR: [
    'GBP',
    'THB',
    'CAD',
    'CNY',
    'EUR',
    'USD',
    'AED',
    'AUD',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'RSD',
    'MGA',
    'VND',
    'AMD',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'MXN',
    'ANG',
    'XAF',
    'BGN',
    'PLN',
    'ZAR',
    'SEK',
    'BYN',
    'XPF',
    'FJD',
    'SAR',
    'MDL',
    'CVE',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'UAH',
    'AZN',
    'LKR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'GNF',
    'DZD',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'SRD',
    'ETB',
    'LAK',
    'ALL',
    'IDR',
    'EGP',
    'MKD',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'KZT',
    'MOP',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'KWD',
    'BSD',
    'RUB',
    'CZK',
    'VUV',
    'KGS',
    'RON',
    'MZN',
    'PHP',
    'TWD',
    'PEN',
    'BHD',
    'ZMW',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'HNL',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'KRW',
    'NGN'
  ],
  MWK: [
    'NZD',
    'LKR',
    'CHF',
    'FJD',
    'BWP',
    'INR',
    'KYD',
    'HKD',
    'XCD',
    'MAD',
    'KRW',
    'RSD',
    'NAD',
    'QAR',
    'LBP',
    'PGK',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'AMD',
    'DZD',
    'XPF',
    'CAD',
    'SAR',
    'GHS',
    'NPR',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'UAH',
    'MUR',
    'PEN',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'PKR',
    'BOB',
    'SCR',
    'KES',
    'DOP',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'MZN',
    'TZS',
    'AUD',
    'ISK',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'KWD',
    'JMD',
    'RUB',
    'NGN',
    'CZK',
    'BYN',
    'BDT',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'BAM',
    'HUF',
    'MGA',
    'ANG',
    'MNT',
    'GNF',
    'CVE',
    'SRD',
    'BZD',
    'BSD',
    'MKD',
    'AZN',
    'VUV',
    'KGS',
    'MDL',
    'RWF',
    'DJF',
    'ZMW'
  ],
  MXN: [
    'KES',
    'JPY',
    'MYR',
    'IDR',
    'EGP',
    'SGD',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'PHP',
    'HUF',
    'ZAR',
    'SEK',
    'KRW',
    'CNY',
    'TRY',
    'NOK',
    'BRL',
    'SAR',
    'VUV',
    'KGS',
    'PKR',
    'MZN',
    'TWD',
    'CVE',
    'PEN',
    'JMD',
    'OMR',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'KYD',
    'MOP',
    'RWF',
    'KWD',
    'DJF',
    'RUB',
    'NGN',
    'CZK',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'BHD',
    'ZMW',
    'LAK',
    'ALL',
    'BYN',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'COP',
    'MNT',
    'GTQ',
    'NAD',
    'LKR',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SRD',
    'ETB',
    'UYU',
    'MKD',
    'CRC',
    'BAM',
    'MWK',
    'CLP',
    'UAH',
    'AZN',
    'MUR',
    'AMD',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'ANG',
    'GBP',
    'CAD',
    'THB',
    'EUR',
    'USD',
    'AED',
    'AUD'
  ],
  MYR: [
    'NOK',
    'PKR',
    'KES',
    'JPY',
    'EGP',
    'ZAR',
    'CHF',
    'INR',
    'HKD',
    'KWD',
    'RUB',
    'PHP',
    'MXN',
    'SGD',
    'HUF',
    'NZD',
    'THB',
    'DKK',
    'KRW',
    'QAR',
    'TRY',
    'PLN',
    'BRL',
    'SEK',
    'SAR',
    'ILS',
    'CNY',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'MZN',
    'PEN',
    'JMD',
    'LAK',
    'OMR',
    'ALL',
    'CLP',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'ARS',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'NGN',
    'CZK',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'VND',
    'BBD',
    'TWD',
    'BND',
    'BHD',
    'ZMW',
    'BYN',
    'MDL',
    'BWP',
    'MAD',
    'COP',
    'MNT',
    'GTQ',
    'NAD',
    'LKR',
    'LBP',
    'PGK',
    'JOD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'ANG',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SRD',
    'ETB',
    'UYU',
    'CVE',
    'MKD',
    'CRC',
    'BAM',
    'MWK',
    'UAH',
    'AZN',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'IDR',
    'CAD',
    'EUR',
    'USD',
    'AED',
    'GBP',
    'AUD'
  ],
  MZN: [
    'CAD',
    'CNY',
    'EUR',
    'USD',
    'AUD',
    'JPY',
    'MYR',
    'SGD',
    'NZD',
    'CHF',
    'INR',
    'GBP',
    'SRD',
    'SAR',
    'GHS',
    'NPR',
    'BZD',
    'DKK',
    'ILS',
    'ETB',
    'BSD',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'UAH',
    'AZN',
    'MUR',
    'LAK',
    'ALL',
    'PEN',
    'GEL',
    'NOK',
    'PYG',
    'JMD',
    'TND',
    'BRL',
    'VUV',
    'BDT',
    'PKR',
    'BOB',
    'SCR',
    'KES',
    'DOP',
    'IDR',
    'EGP',
    'OMR',
    'KGS',
    'CLP',
    'MGA',
    'COP',
    'KZT',
    'AED',
    'TZS',
    'ISK',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'BYN',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'MDL',
    'RWF',
    'TWD',
    'DJF',
    'BHD',
    'ZMW',
    'BAM',
    'HUF',
    'LKR',
    'FJD',
    'BWP',
    'KYD',
    'HKD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'KRW',
    'GNF',
    'RSD',
    'NAD',
    'CVE',
    'QAR',
    'LBP',
    'PGK',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'MWK',
    'BGN',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'AMD',
    'DZD',
    'XPF'
  ],
  NAD: [
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'XOF',
    'INR',
    'HKD',
    'MAD',
    'KRW',
    'QAR',
    'JOD',
    'TRY',
    'MXN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'CNY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'AED',
    'AUD',
    'ARS',
    'KWD',
    'RUB',
    'CZK',
    'VND',
    'PHP',
    'LAK',
    'ALL',
    'PEN',
    'ZMW',
    'JMD',
    'BDT',
    'FJD',
    'BOB',
    'KYD',
    'BWP',
    'RWF',
    'MNT',
    'DJF',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'ANG',
    'XAF',
    'BGN',
    'BYN',
    'DZD',
    'XPF',
    'SRD',
    'MDL',
    'ETB',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'UAH',
    'AZN',
    'MUR',
    'LKR',
    'AMD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'GNF',
    'VUV',
    'SCR',
    'DOP',
    'CVE',
    'BAM',
    'MWK',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'NGN',
    'KGS',
    'RON',
    'MZN'
  ],
  NGN: [
    'VND',
    'QAR',
    'MXN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'MAD',
    'ILS',
    'CNY',
    'TRY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'AED',
    'AUD',
    'ARS',
    'KWD',
    'RUB',
    'CZK',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'JOD',
    'ANG',
    'XAF',
    'BGN',
    'FJD',
    'BYN',
    'DZD',
    'XPF',
    'MDL',
    'BWP',
    'ETB',
    'UYU',
    'MNT',
    'GTQ',
    'MKD',
    'CRC',
    'NAD',
    'UAH',
    'AZN',
    'MUR',
    'LKR',
    'AMD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'GNF',
    'SCR',
    'DOP',
    'CVE',
    'SRD',
    'BAM',
    'MWK',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'RON',
    'MZN',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'ZMW',
    'JMD',
    'BDT',
    'BOB',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'RSD',
    'MGA'
  ],
  NOK: [
    'JOD',
    'TRY',
    'PLN',
    'BRL',
    'SEK',
    'SAR',
    'ILS',
    'CNY',
    'GBP',
    'AUD',
    'PKR',
    'KES',
    'JPY',
    'MYR',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'ZAR',
    'CHF',
    'CAD',
    'XOF',
    'ARS',
    'INR',
    'EUR',
    'HKD',
    'KWD',
    'RUB',
    'CZK',
    'PHP',
    'VND',
    'TWD',
    'MXN',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'THB',
    'DKK',
    'MAD',
    'COP',
    'KRW',
    'USD',
    'AED',
    'QAR',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'ANG',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SRD',
    'ETB',
    'UYU',
    'CVE',
    'MKD',
    'CRC',
    'BAM',
    'MWK',
    'UAH',
    'AZN',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'MZN',
    'PEN',
    'JMD',
    'LAK',
    'ALL',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'BBD',
    'BND',
    'ZMW',
    'BYN',
    'MDL',
    'BWP',
    'MNT',
    'GTQ',
    'NAD',
    'LKR',
    'LBP',
    'PGK'
  ],
  NPR: [
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'TRY',
    'MXN',
    'GBP',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'CNY',
    'NOK',
    'BRL',
    'VUV',
    'PKR',
    'BOB',
    'SCR',
    'DOP',
    'OMR',
    'KGS',
    'CLP',
    'COP',
    'MZN',
    'TZS',
    'ISK',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'JMD',
    'NGN',
    'CZK',
    'BYN',
    'BDT',
    'VND',
    'RON',
    'XOF',
    'MDL',
    'RWF',
    'TWD',
    'DJF',
    'BHD',
    'ZMW',
    'BAM',
    'MGA',
    'LKR',
    'FJD',
    'BWP',
    'KYD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'RSD',
    'NAD',
    'CVE',
    'QAR',
    'LBP',
    'PGK',
    'JOD',
    'XAF',
    'BGN',
    'PLN',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'GHS',
    'BZD',
    'DKK',
    'ILS',
    'ETB',
    'BSD',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'GNF',
    'UAH',
    'AZN',
    'MUR',
    'PEN',
    'GEL',
    'PYG',
    'TND',
    'MWK'
  ],
  NZD: [
    'MXN',
    'BHD',
    'SGD',
    'HUF',
    'BYN',
    'THB',
    'BWP',
    'DKK',
    'MAD',
    'COP',
    'GTQ',
    'KRW',
    'USD',
    'AED',
    'LKR',
    'LBP',
    'QAR',
    'JOD',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'SEK',
    'DZD',
    'XPF',
    'FJD',
    'DOP',
    'SAR',
    'ILS',
    'ETB',
    'UYU',
    'CNY',
    'CRC',
    'UAH',
    'GBP',
    'MUR',
    'AUD',
    'GHS',
    'NPR',
    'PKR',
    'KES',
    'JPY',
    'MYR',
    'PEN',
    'IDR',
    'EGP',
    'JMD',
    'LAK',
    'OMR',
    'ALL',
    'CLP',
    'ZAR',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'CAD',
    'HNL',
    'XOF',
    'ARS',
    'INR',
    'EUR',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'TWD',
    'BND',
    'ZMW',
    'MDL',
    'MNT',
    'NAD',
    'PGK',
    'ANG',
    'SCR',
    'GNF',
    'SRD',
    'CVE',
    'MKD',
    'BAM',
    'MWK',
    'AZN',
    'AMD',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'MZN',
    'KZT',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'RSD',
    'MGA'
  ],
  OMR: [
    'CNY',
    'USD',
    'AED',
    'AUD',
    'JPY',
    'MYR',
    'SGD',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'HKD',
    'MXN',
    'HUF',
    'THB',
    'EUR',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'PGK',
    'QAR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'KES',
    'DOP',
    'SAR',
    'SRD',
    'ETB',
    'IDR',
    'EGP',
    'MKD',
    'BAM',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'KZT',
    'AMD',
    'ARS',
    'GHS',
    'NPR',
    'MOP',
    'BZD',
    'KWD',
    'BSD',
    'ANG',
    'CZK',
    'VUV',
    'KGS',
    'PKR',
    'MZN',
    'TWD',
    'CVE',
    'PEN',
    'BHD',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'HNL',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'RUB',
    'KRW',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'LBP',
    'BND',
    'JOD',
    'ZMW',
    'LAK',
    'ALL',
    'ZAR',
    'SEK',
    'BYN',
    'FJD',
    'MDL',
    'BWP'
  ],
  PEN: [
    'GBP',
    'EUR',
    'USD',
    'INR',
    'KRW',
    'BYN',
    'RSD',
    'NAD',
    'MDL',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'AMD',
    'DZD',
    'XPF',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'MKD',
    'CRC',
    'GNF',
    'UAH',
    'AZN',
    'MUR',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'MWK',
    'BRL',
    'VUV',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'GHS',
    'NPR',
    'BZD',
    'IDR',
    'EGP',
    'OMR',
    'KGS',
    'CLP',
    'COP',
    'AED',
    'MZN',
    'AUD',
    'ISK',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'KWD',
    'JMD',
    'RUB',
    'NGN',
    'CZK',
    'BDT',
    'BOB',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'BAM',
    'HUF',
    'MGA',
    'NZD',
    'LKR',
    'CHF',
    'TZS',
    'FJD',
    'BWP',
    'KYD',
    'HKD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'SRD',
    'CVE',
    'BSD',
    'RWF',
    'DJF',
    'ZMW'
  ],
  PGK: [
    'HKD',
    'RUB',
    'KRW',
    'PHP',
    'VND',
    'JOD',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'THB',
    'EUR',
    'DKK',
    'MAD',
    'ILS',
    'CNY',
    'USD',
    'QAR',
    'TRY',
    'NOK',
    'PLN',
    'BRL',
    'KES',
    'SAR',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'AED',
    'AUD',
    'ARS',
    'KWD',
    'CZK',
    'PKR',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'XOF',
    'INR',
    'RWF',
    'DJF',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'BBD',
    'LBP',
    'BND',
    'ZMW',
    'LAK',
    'ALL',
    'BYN',
    'FJD',
    'MDL',
    'BWP',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'DOP',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK',
    'UAH',
    'MUR',
    'KZT',
    'AMD',
    'GHS',
    'NPR',
    'MOP',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'MZN',
    'CVE',
    'PEN',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'HNL',
    'KYD'
  ],
  PHP: [
    'HUF',
    'ZAR',
    'SEK',
    'THB',
    'EUR',
    'CNY',
    'USD',
    'BRL',
    'IDR',
    'AED',
    'AUD',
    'JPY',
    'MYR',
    'SGD',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'HKD',
    'MXN',
    'XAF',
    'BGN',
    'BYN',
    'XPF',
    'FJD',
    'SAR',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'LAK',
    'TND',
    'ALL',
    'PLN',
    'GNF',
    'DZD',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'SRD',
    'ETB',
    'EGP',
    'MKD',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'KZT',
    'MOP',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'KWD',
    'BSD',
    'RUB',
    'CZK',
    'VUV',
    'KGS',
    'RON',
    'MZN',
    'TWD',
    'PEN',
    'BHD',
    'ZMW',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'AMD',
    'HNL',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'ANG',
    'KRW',
    'NGN',
    'RSD',
    'MGA',
    'VND',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'CVE',
    'QAR',
    'JOD'
  ],
  PKR: [
    'KWD',
    'CZK',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'GBP',
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'CAD',
    'HNL',
    'XOF',
    'INR',
    'KYD',
    'HKD',
    'RUB',
    'KRW',
    'NGN',
    'MGA',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'JOD',
    'MXN',
    'LAK',
    'ALL',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'FJD',
    'EUR',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'GTQ',
    'CNY',
    'USD',
    'CRC',
    'NAD',
    'LKR',
    'QAR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'XPF',
    'KES',
    'DOP',
    'SAR',
    'ETB',
    'IDR',
    'EGP',
    'BAM',
    'OMR',
    'CLP',
    'UAH',
    'MUR',
    'AED',
    'AUD',
    'KZT',
    'ARS',
    'GHS',
    'NPR',
    'MOP',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'MZN',
    'CVE',
    'RWF',
    'DJF',
    'RSD',
    'ZMW',
    'MDL',
    'MNT',
    'AZN',
    'GNF',
    'SCR',
    'SRD',
    'MKD',
    'MWK',
    'AMD'
  ],
  PLN: [
    'OMR',
    'CLP',
    'MUR',
    'ARS',
    'GHS',
    'KWD',
    'RUB',
    'RON',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'NZD',
    'BDT',
    'CHF',
    'ISK',
    'XOF',
    'INR',
    'HKD',
    'KRW',
    'NGN',
    'VND',
    'LBP',
    'QAR',
    'JOD',
    'MXN',
    'XAF',
    'BGN',
    'SEK',
    'BYN',
    'THB',
    'FJD',
    'SAR',
    'DKK',
    'MAD',
    'UYU',
    'CNY',
    'CRC',
    'UAH',
    'LKR',
    'TRY',
    'GEL',
    'NOK',
    'TND',
    'BRL',
    'DZD',
    'PKR',
    'KES',
    'DOP',
    'IDR',
    'EGP',
    'MWK',
    'KZT',
    'MOP',
    'NPR',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'MZN',
    'ZMW',
    'BOB',
    'HNL',
    'KYD',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'AMD',
    'TZS',
    'BBD',
    'PGK',
    'BND',
    'ANG',
    'XPF',
    'MDL',
    'CVE',
    'BWP',
    'MNT',
    'GTQ',
    'NAD',
    'AZN',
    'XCD',
    'PYG',
    'GNF',
    'SCR',
    'SRD',
    'ETB',
    'LAK',
    'ALL',
    'MKD',
    'BAM',
    'AED',
    'AUD',
    'CZK',
    'HUF',
    'GBP',
    'ZAR',
    'CAD',
    'ILS',
    'EUR',
    'USD'
  ],
  PYG: [
    'GEL',
    'NOK',
    'TND',
    'BRL',
    'BYN',
    'PKR',
    'KES',
    'DOP',
    'EUR',
    'GTQ',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'LKR',
    'AUD',
    'ISK',
    'ARS',
    'XCD',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'VND',
    'RON',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'FJD',
    'INR',
    'GHS',
    'NPR',
    'HKD',
    'MAD',
    'KRW',
    'LBP',
    'QAR',
    'JOD',
    'ALL',
    'PEN',
    'TRY',
    'MXN',
    'JMD',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'BDT',
    'DZD',
    'BOB',
    'XPF',
    'CAD',
    'XOF',
    'SAR',
    'DKK',
    'ILS',
    'UYU',
    'CNY',
    'CRC',
    'UAH',
    'MUR',
    'TZS',
    'ANG',
    'VUV',
    'SCR',
    'MDL',
    'AMD',
    'KZT',
    'MOP',
    'HNL',
    'GNF',
    'CVE',
    'ZMW',
    'BAM',
    'MWK',
    'BWP',
    'BZD',
    'BSD',
    'MNT',
    'RSD',
    'KGS',
    'NAD',
    'MZN',
    'PGK',
    'LAK',
    'SRD',
    'KYD',
    'RWF',
    'ETB',
    'DJF',
    'MKD',
    'MGA',
    'AZN',
    'BBD',
    'BND'
  ],
  QAR: [
    'TRY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'SAR',
    'IDR',
    'EGP',
    'AED',
    'AUD',
    'KWD',
    'JPY',
    'MYR',
    'SGD',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'HKD',
    'RUB',
    'KRW',
    'PHP',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'THB',
    'EUR',
    'DKK',
    'ILS',
    'CNY',
    'USD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'DOP',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'KZT',
    'AMD',
    'ARS',
    'GHS',
    'NPR',
    'MOP',
    'BZD',
    'BSD',
    'ANG',
    'CZK',
    'VUV',
    'KGS',
    'MZN',
    'TWD',
    'CVE',
    'PEN',
    'BHD',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'HNL',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'NGN',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'VND',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'JOD',
    'ZMW',
    'LAK',
    'ALL',
    'BYN',
    'FJD',
    'MDL',
    'BWP',
    'MAD',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR'
  ],
  RON: [
    'RUB',
    'CZK',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'NZD',
    'BDT',
    'CHF',
    'ISK',
    'XOF',
    'INR',
    'HKD',
    'KRW',
    'NGN',
    'VND',
    'PHP',
    'LBP',
    'QAR',
    'JOD',
    'MXN',
    'XAF',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'FJD',
    'SAR',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'CNY',
    'CRC',
    'LKR',
    'TRY',
    'GEL',
    'NOK',
    'TND',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'PKR',
    'KES',
    'DOP',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'UAH',
    'MUR',
    'AED',
    'AUD',
    'ARS',
    'GHS',
    'KWD',
    'VUV',
    'KGS',
    'MZN',
    'ZMW',
    'BOB',
    'AMD',
    'HNL',
    'KYD',
    'RWF',
    'DJF',
    'ANG',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'PGK',
    'BND',
    'CVE',
    'XPF',
    'MDL',
    'BWP',
    'MNT',
    'GTQ',
    'NAD',
    'AZN',
    'XCD',
    'PYG',
    'LAK',
    'ALL',
    'GNF',
    'SCR',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK',
    'KZT',
    'NPR',
    'MOP',
    'BZD',
    'BSD',
    'GBP',
    'CAD',
    'EUR',
    'USD'
  ],
  RSD: [
    'HKD',
    'MAD',
    'KRW',
    'NAD',
    'LBP',
    'PGK',
    'QAR',
    'LAK',
    'JOD',
    'ALL',
    'PEN',
    'TRY',
    'MXN',
    'JMD',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'BDT',
    'DZD',
    'BOB',
    'XPF',
    'CAD',
    'XOF',
    'SAR',
    'KYD',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'CNY',
    'CRC',
    'UAH',
    'MGA',
    'MUR',
    'TZS',
    'BBD',
    'BND',
    'GEL',
    'NOK',
    'TND',
    'BRL',
    'BYN',
    'PKR',
    'KES',
    'DOP',
    'EUR',
    'GTQ',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'LKR',
    'AUD',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'XCD',
    'KWD',
    'PYG',
    'RUB',
    'NGN',
    'CZK',
    'VND',
    'RON',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'BAM',
    'HUF',
    'NZD',
    'CHF',
    'FJD',
    'INR',
    'GHS',
    'NPR',
    'BWP',
    'BZD',
    'BSD',
    'MNT',
    'KGS',
    'MZN',
    'SRD',
    'RWF',
    'DJF',
    'MKD',
    'AZN',
    'ANG',
    'VUV',
    'MDL',
    'AMD',
    'GNF',
    'SCR',
    'CVE',
    'ZMW',
    'MWK'
  ],
  RUB: [
    'JOD',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'THB',
    'EUR',
    'DKK',
    'MAD',
    'ILS',
    'CNY',
    'USD',
    'LKR',
    'TRY',
    'NOK',
    'TND',
    'PLN',
    'BRL',
    'PKR',
    'KES',
    'SAR',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'UAH',
    'AED',
    'AUD',
    'ARS',
    'KWD',
    'CZK',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'XOF',
    'INR',
    'HKD',
    'KRW',
    'NGN',
    'PHP',
    'VND',
    'QAR',
    'ZMW',
    'LAK',
    'ALL',
    'BYN',
    'FJD',
    'MDL',
    'BWP',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'XCD',
    'GEL',
    'PYG',
    'XAF',
    'BGN',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'DOP',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK',
    'MUR',
    'KZT',
    'AMD',
    'GHS',
    'NPR',
    'MOP',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'MZN',
    'CVE',
    'PEN',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'HNL',
    'KYD',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND'
  ],
  RWF: [
    'BBD',
    'ARS',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'BYN',
    'VND',
    'RON',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'LKR',
    'CHF',
    'FJD',
    'INR',
    'HKD',
    'XCD',
    'MAD',
    'KRW',
    'QAR',
    'LBP',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'DZD',
    'XPF',
    'CAD',
    'SAR',
    'GHS',
    'NPR',
    'DKK',
    'ILS',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'UAH',
    'MUR',
    'LAK',
    'ALL',
    'PEN',
    'GEL',
    'NOK',
    'JMD',
    'TND',
    'BRL',
    'BDT',
    'PKR',
    'BOB',
    'KES',
    'DOP',
    'XOF',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'TZS',
    'AUD',
    'ISK',
    'MOP',
    'HNL',
    'BND',
    'MDL',
    'DJF',
    'ZMW',
    'BAM',
    'BWP',
    'KYD',
    'ANG',
    'MNT',
    'GNF',
    'RSD',
    'NAD',
    'CVE',
    'PGK',
    'MWK',
    'AMD',
    'SRD',
    'BZD',
    'ETB',
    'BSD',
    'MKD',
    'AZN',
    'MZN',
    'PYG',
    'VUV',
    'SCR',
    'KGS',
    'MGA',
    'KZT'
  ],
  SAR: [
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'GBP',
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'CAD',
    'HNL',
    'XOF',
    'INR',
    'KYD',
    'HKD',
    'KRW',
    'NGN',
    'MGA',
    'VND',
    'RON',
    'PHP',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'MXN',
    'LAK',
    'ALL',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'FJD',
    'EUR',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'GTQ',
    'CNY',
    'USD',
    'CRC',
    'NAD',
    'LKR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'PKR',
    'XPF',
    'KES',
    'DOP',
    'ETB',
    'IDR',
    'EGP',
    'BAM',
    'OMR',
    'CLP',
    'UAH',
    'MUR',
    'AED',
    'AUD',
    'KZT',
    'ARS',
    'GHS',
    'NPR',
    'MOP',
    'KWD',
    'RUB',
    'CZK',
    'MZN',
    'JPY',
    'TWD',
    'MYR',
    'RWF',
    'DJF',
    'RSD',
    'ZMW',
    'MDL',
    'MNT',
    'AZN',
    'GNF',
    'SCR',
    'SRD',
    'MKD',
    'MWK',
    'AMD',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'CVE'
  ],
  SCR: [
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'TRY',
    'MXN',
    'GBP',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'CNY',
    'BRL',
    'KES',
    'EUR',
    'IDR',
    'USD',
    'AED',
    'AUD',
    'XCD',
    'KWD',
    'PYG',
    'RUB',
    'NGN',
    'GNF',
    'CZK',
    'VND',
    'RON',
    'CVE',
    'TWD',
    'BHD',
    'ZMW',
    'BAM',
    'MWK',
    'FJD',
    'BWP',
    'GHS',
    'NPR',
    'BZD',
    'MAD',
    'BSD',
    'MNT',
    'KRW',
    'RSD',
    'KGS',
    'NAD',
    'MZN',
    'LBP',
    'PGK',
    'QAR',
    'LAK',
    'JOD',
    'ALL',
    'PEN',
    'JMD',
    'XAF',
    'BGN',
    'PLN',
    'BDT',
    'DZD',
    'BOB',
    'XPF',
    'SRD',
    'XOF',
    'KYD',
    'DKK',
    'RWF',
    'ILS',
    'ETB',
    'UYU',
    'DJF',
    'MKD',
    'CRC',
    'UAH',
    'MGA',
    'AZN',
    'MUR',
    'TZS',
    'BBD',
    'BND',
    'ANG',
    'GEL',
    'NOK',
    'TND',
    'BYN',
    'VUV',
    'PKR',
    'DOP',
    'MDL',
    'GTQ',
    'EGP',
    'OMR',
    'CLP',
    'COP',
    'LKR',
    'AMD',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS'
  ],
  SEK: [
    'IDR',
    'ZAR',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'EUR',
    'HKD',
    'PHP',
    'MXN',
    'SGD',
    'HUF',
    'THB',
    'USD',
    'AED',
    'TRY',
    'BRL',
    'SAR',
    'CNY',
    'GBP',
    'AUD',
    'JPY',
    'MYR',
    'PEN',
    'EGP',
    'JMD',
    'LAK',
    'OMR',
    'ALL',
    'CLP',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'ARS',
    'KYD',
    'MOP',
    'RWF',
    'KWD',
    'DJF',
    'RUB',
    'NGN',
    'CZK',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'VND',
    'BBD',
    'TWD',
    'BND',
    'BHD',
    'ZMW',
    'BYN',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'COP',
    'MNT',
    'GTQ',
    'KRW',
    'NAD',
    'LKR',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'ANG',
    'PLN',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SRD',
    'ILS',
    'ETB',
    'UYU',
    'CVE',
    'MKD',
    'CRC',
    'BAM',
    'MWK',
    'UAH',
    'AZN',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'PKR',
    'KES',
    'MZN'
  ],
  SGD: [
    'CNY',
    'UAH',
    'GBP',
    'AUD',
    'PKR',
    'KES',
    'JPY',
    'MYR',
    'PEN',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'ZAR',
    'NZD',
    'BDT',
    'CHF',
    'CAD',
    'XOF',
    'ARS',
    'INR',
    'EUR',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'RON',
    'PHP',
    'VND',
    'TWD',
    'MXN',
    'BHD',
    'HUF',
    'SEK',
    'BYN',
    'THB',
    'DKK',
    'MAD',
    'COP',
    'KRW',
    'USD',
    'AED',
    'LKR',
    'QAR',
    'JOD',
    'TRY',
    'GEL',
    'NOK',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'FJD',
    'SAR',
    'ILS',
    'MKD',
    'CRC',
    'BAM',
    'MWK',
    'AZN',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'MZN',
    'JMD',
    'LAK',
    'ALL',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'BND',
    'ZMW',
    'MDL',
    'BWP',
    'MNT',
    'GTQ',
    'NAD',
    'LBP',
    'PGK',
    'XCD',
    'PYG',
    'ANG',
    'DZD',
    'XPF',
    'SCR',
    'GNF',
    'DOP',
    'SRD',
    'ETB',
    'UYU',
    'CVE'
  ],
  SRD: [
    'RON',
    'MZN',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'HUF',
    'NZD',
    'BDT',
    'CHF',
    'FJD',
    'BOB',
    'XOF',
    'INR',
    'KYD',
    'HKD',
    'KRW',
    'RSD',
    'MGA',
    'VND',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'DZD',
    'XPF',
    'CAD',
    'SAR',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'NAD',
    'UAH',
    'MUR',
    'LKR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'BAM',
    'OMR',
    'CLP',
    'AED',
    'AUD',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'GHS',
    'NPR',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'ZMW',
    'RWF',
    'DJF',
    'ANG',
    'MDL',
    'MNT',
    'MKD',
    'AZN',
    'AMD',
    'GNF',
    'CVE',
    'MWK',
    'BZD',
    'BSD',
    'VUV',
    'KGS'
  ],
  THB: [
    'GTQ',
    'KRW',
    'LKR',
    'LBP',
    'QAR',
    'JOD',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'DZD',
    'XPF',
    'FJD',
    'DOP',
    'SAR',
    'ILS',
    'UYU',
    'CNY',
    'CRC',
    'UAH',
    'MUR',
    'GHS',
    'NPR',
    'PKR',
    'KES',
    'JPY',
    'MYR',
    'PEN',
    'IDR',
    'EGP',
    'SGD',
    'JMD',
    'LAK',
    'OMR',
    'ALL',
    'CLP',
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'XOF',
    'ARS',
    'INR',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'RON',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'TWD',
    'BHD',
    'HUF',
    'SEK',
    'BYN',
    'DKK',
    'MAD',
    'COP',
    'NAD',
    'PGK',
    'PYG',
    'ANG',
    'SCR',
    'GNF',
    'SRD',
    'ETB',
    'CVE',
    'MKD',
    'BAM',
    'MWK',
    'AZN',
    'AMD',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'MZN',
    'KZT',
    'HNL',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'BND',
    'ZMW',
    'MDL',
    'BWP',
    'MNT',
    'USD',
    'AED',
    'GBP',
    'AUD',
    'ZAR',
    'CAD',
    'EUR',
    'MXN'
  ],
  TND: [
    'BAM',
    'MWK',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'KGS',
    'MZN',
    'LAK',
    'ALL',
    'PEN',
    'JMD',
    'BDT',
    'BOB',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'BND',
    'ANG',
    'BYN',
    'MDL',
    'GTQ',
    'LKR',
    'AMD',
    'XCD',
    'GEL',
    'PYG',
    'GNF',
    'SCR',
    'DOP',
    'CVE',
    'AED',
    'INR',
    'CAD',
    'CHF',
    'ZAR',
    'DKK',
    'GBP',
    'HKD',
    'JPY',
    'COP',
    'MYR',
    'NOK',
    'NZD',
    'SEK',
    'SGD',
    'THB',
    'TWD',
    'USD',
    'CNY',
    'ILS',
    'AUD',
    'ARS',
    'CLP',
    'MXN',
    'UYU',
    'AZN',
    'BHD',
    'CRC',
    'CZK',
    'EGP',
    'FJD',
    'IDR',
    'JOD',
    'KES',
    'KRW',
    'KWD',
    'KZT',
    'LBP',
    'MOP',
    'OMR',
    'PGK',
    'PKR',
    'QAR',
    'RUB',
    'SAR',
    'ZMW',
    'XAF',
    'XPF',
    'RON',
    'BGN',
    'PHP',
    'UAH',
    'PLN',
    'HUF',
    'MUR',
    'DZD',
    'ETB',
    'HNL',
    'ISK',
    'MKD',
    'NGN',
    'SRD',
    'VND',
    'MAD',
    'BWP',
    'MNT',
    'TRY',
    'VUV',
    'NAD',
    'EUR'
  ],
  TRY: [
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'BRL',
    'GNF',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'IDR',
    'EGP',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'VND',
    'RON',
    'MZN',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'HUF',
    'NZD',
    'BDT',
    'CHF',
    'FJD',
    'BOB',
    'XOF',
    'INR',
    'KYD',
    'BWP',
    'HKD',
    'MAD',
    'MNT',
    'KRW',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'MXN',
    'XAF',
    'BGN',
    'PLN',
    'SEK',
    'BYN',
    'THB',
    'DZD',
    'XPF',
    'SAR',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'CRC',
    'NAD',
    'UAH',
    'AZN',
    'MUR',
    'LKR',
    'AMD',
    'CVE',
    'BSD',
    'VUV',
    'KGS',
    'ZMW',
    'RWF',
    'DJF',
    'ANG',
    'SRD',
    'MDL',
    'MKD',
    'EUR',
    'USD',
    'AED',
    'AUD',
    'GBP',
    'ZAR',
    'CAD'
  ],
  TWD: [
    'PHP',
    'MXN',
    'HUF',
    'SEK',
    'USD',
    'AED',
    'TRY',
    'BRL',
    'SAR',
    'CNY',
    'GBP',
    'AUD',
    'KES',
    'JPY',
    'MYR',
    'IDR',
    'SGD',
    'ZAR',
    'NZD',
    'THB',
    'CHF',
    'CAD',
    'INR',
    'EUR',
    'HKD',
    'DJF',
    'RUB',
    'NGN',
    'CZK',
    'RSD',
    'MGA',
    'RON',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'BHD',
    'ZMW',
    'BYN',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'COP',
    'MNT',
    'GTQ',
    'KRW',
    'NAD',
    'LKR',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'ANG',
    'PLN',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SRD',
    'ILS',
    'ETB',
    'UYU',
    'CVE',
    'MKD',
    'CRC',
    'BAM',
    'MWK',
    'UAH',
    'AZN',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'VUV',
    'KGS',
    'PKR',
    'MZN',
    'PEN',
    'EGP',
    'JMD',
    'LAK',
    'OMR',
    'ALL',
    'CLP',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'ARS',
    'KYD',
    'MOP',
    'RWF',
    'KWD'
  ],
  TZS: [
    'GBP',
    'EUR',
    'USD',
    'OMR',
    'KGS',
    'CLP',
    'COP',
    'AED',
    'MZN',
    'AUD',
    'ISK',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'ARS',
    'LAK',
    'ALL',
    'KWD',
    'JMD',
    'RUB',
    'NGN',
    'CZK',
    'BDT',
    'BOB',
    'VND',
    'RON',
    'PHP',
    'XOF',
    'MDL',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'BAM',
    'HUF',
    'MGA',
    'NZD',
    'LKR',
    'CHF',
    'FJD',
    'BWP',
    'INR',
    'KYD',
    'HKD',
    'XCD',
    'MAD',
    'ANG',
    'MNT',
    'KRW',
    'BYN',
    'RSD',
    'NAD',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'AMD',
    'DZD',
    'XPF',
    'SRD',
    'CAD',
    'SAR',
    'GHS',
    'BZD',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'GTQ',
    'CNY',
    'MKD',
    'CRC',
    'GNF',
    'UAH',
    'AZN',
    'CVE',
    'MUR',
    'PEN',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'MWK',
    'BRL',
    'VUV',
    'PKR',
    'SCR',
    'KES',
    'DOP',
    'NPR',
    'IDR',
    'EGP',
    'RWF',
    'DJF',
    'ZMW',
    'BSD'
  ],
  UAH: [
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'MXN',
    'HUF',
    'GBP',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'CNY',
    'EUR',
    'USD',
    'AED',
    'AUD',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'HNL',
    'XOF',
    'KYD',
    'RWF',
    'LAK',
    'DJF',
    'ALL',
    'KRW',
    'NGN',
    'RSD',
    'MGA',
    'VND',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'XAF',
    'BGN',
    'BYN',
    'XPF',
    'FJD',
    'SAR',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'PLN',
    'BRL',
    'GNF',
    'DZD',
    'PKR',
    'SCR',
    'AMD',
    'KES',
    'DOP',
    'SRD',
    'ETB',
    'ANG',
    'IDR',
    'EGP',
    'MKD',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'MUR',
    'KZT',
    'MOP',
    'ARS',
    'GHS',
    'NPR',
    'CVE',
    'BZD',
    'KWD',
    'BSD',
    'RUB',
    'CZK',
    'VUV',
    'KGS',
    'RON',
    'MZN',
    'TWD',
    'PEN',
    'BHD',
    'ZMW'
  ],
  USD: [
    'JPY',
    'SGD',
    'CHF',
    'INR',
    'CNY',
    'VUV',
    'KGS',
    'PKR',
    'KES',
    'MZN',
    'TWD',
    'PEN',
    'EGP',
    'JMD',
    'LAK',
    'OMR',
    'ALL',
    'CLP',
    'NZD',
    'BDT',
    'BOB',
    'ISK',
    'KZT',
    'HNL',
    'XOF',
    'ARS',
    'KYD',
    'MOP',
    'HKD',
    'RWF',
    'KWD',
    'DJF',
    'RUB',
    'NGN',
    'UZS',
    'RSD',
    'MGA',
    'PHP',
    'TZS',
    'VND',
    'BBD',
    'BND',
    'BHD',
    'ZMW',
    'SEK',
    'BYN',
    'MDL',
    'BWP',
    'DKK',
    'MAD',
    'COP',
    'MNT',
    'GTQ',
    'KRW',
    'NAD',
    'LKR',
    'LBP',
    'PGK',
    'QAR',
    'JOD',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'XAF',
    'BGN',
    'ANG',
    'BRL',
    'DZD',
    'XPF',
    'SCR',
    'FJD',
    'GNF',
    'DOP',
    'SAR',
    'SRD',
    'ETB',
    'UYU',
    'CVE',
    'MKD',
    'CRC',
    'BAM',
    'MWK',
    'UAH',
    'AZN',
    'MUR',
    'AMD',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'MYR',
    'IDR',
    'ZAR',
    'THB',
    'CAD',
    'EUR',
    'CZK',
    'RON',
    'MXN',
    'HUF',
    'AED',
    'TRY',
    'PLN',
    'ILS',
    'GBP',
    'AUD',
    'HRK'
  ],
  UYU: [
    'DZD',
    'XPF',
    'FJD',
    'DOP',
    'SAR',
    'CRC',
    'CLP',
    'UAH',
    'MUR',
    'AED',
    'AUD',
    'ARS',
    'GHS',
    'PKR',
    'KES',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'IDR',
    'EGP',
    'SGD',
    'JMD',
    'OMR',
    'GBP',
    'NZD',
    'BDT',
    'CHF',
    'ISK',
    'CAD',
    'XOF',
    'INR',
    'HKD',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'RON',
    'PHP',
    'VND',
    'MXN',
    'BHD',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'EUR',
    'DKK',
    'MAD',
    'ILS',
    'KRW',
    'CNY',
    'USD',
    'LKR',
    'LBP',
    'QAR',
    'JOD',
    'TRY',
    'GEL',
    'NOK',
    'TND',
    'XAF',
    'BGN',
    'PLN',
    'BRL',
    'SCR',
    'GNF',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'MWK',
    'AZN',
    'AMD',
    'NPR',
    'BZD',
    'BSD',
    'ANG',
    'VUV',
    'KGS',
    'MZN',
    'CVE',
    'BOB',
    'KZT',
    'HNL',
    'KYD',
    'MOP',
    'RWF',
    'DJF',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'BND',
    'ZMW',
    'LAK',
    'ALL',
    'MDL',
    'BWP',
    'MNT',
    'GTQ',
    'NAD',
    'PGK',
    'XCD',
    'PYG'
  ],
  UZS: ['GBP', 'USD', 'EUR'],
  VES: ['EUR'],
  VND: [
    'EUR',
    'IDR',
    'USD',
    'AED',
    'AUD',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'MXN',
    'GBP',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'CNY',
    'BRL',
    'KES',
    'DOP',
    'CVE',
    'EGP',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'KWD',
    'BSD',
    'RUB',
    'NGN',
    'CZK',
    'VUV',
    'KGS',
    'RON',
    'MZN',
    'TWD',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'ZMW',
    'JMD',
    'BDT',
    'FJD',
    'BOB',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'KRW',
    'RSD',
    'MGA',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'ANG',
    'XAF',
    'BGN',
    'PLN',
    'BYN',
    'DZD',
    'XPF',
    'MDL',
    'BWP',
    'SRD',
    'DKK',
    'MAD',
    'ILS',
    'ETB',
    'UYU',
    'MNT',
    'GTQ',
    'MKD',
    'CRC',
    'NAD',
    'UAH',
    'AZN',
    'MUR',
    'LKR',
    'AMD',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'TND',
    'GNF',
    'PKR',
    'SCR'
  ],
  VUV: [
    'TRY',
    'MXN',
    'GBP',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'CNY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'PHP',
    'JPY',
    'MYR',
    'SGD',
    'HUF',
    'NZD',
    'CHF',
    'INR',
    'HKD',
    'KRW',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'QAR',
    'JOD',
    'ANG',
    'XAF',
    'BGN',
    'PLN',
    'BYN',
    'DZD',
    'XPF',
    'SRD',
    'MDL',
    'ETB',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'NAD',
    'UAH',
    'AZN',
    'MUR',
    'LKR',
    'AMD',
    'XCD',
    'GEL',
    'PYG',
    'TND',
    'GNF',
    'SCR',
    'DOP',
    'CVE',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'ISK',
    'KZT',
    'MOP',
    'HNL',
    'ARS',
    'GHS',
    'NPR',
    'BZD',
    'BSD',
    'NGN',
    'CZK',
    'KGS',
    'VND',
    'RON',
    'MZN',
    'TWD',
    'LAK',
    'ALL',
    'PEN',
    'BHD',
    'ZMW',
    'JMD',
    'BDT',
    'FJD',
    'BOB',
    'XOF',
    'KYD',
    'BWP',
    'RWF',
    'MAD',
    'MNT',
    'DJF',
    'RSD',
    'MGA',
    'TZS'
  ],
  XAF: [
    'GTQ',
    'CNY',
    'USD',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'TRY',
    'XCD',
    'GEL',
    'NOK',
    'PYG',
    'LAK',
    'TND',
    'ALL',
    'BGN',
    'PLN',
    'BRL',
    'GNF',
    'DZD',
    'PKR',
    'XPF',
    'SCR',
    'KES',
    'DOP',
    'ETB',
    'IDR',
    'EGP',
    'MKD',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'AED',
    'AUD',
    'KZT',
    'ARS',
    'GHS',
    'NPR',
    'MOP',
    'BZD',
    'KWD',
    'RUB',
    'CZK',
    'VUV',
    'KGS',
    'MZN',
    'JPY',
    'TWD',
    'MYR',
    'PEN',
    'BHD',
    'SGD',
    'JMD',
    'GBP',
    'NZD',
    'BDT',
    'CHF',
    'BOB',
    'ISK',
    'CAD',
    'AMD',
    'HNL',
    'XOF',
    'INR',
    'KYD',
    'HKD',
    'ANG',
    'KRW',
    'NGN',
    'RSD',
    'MGA',
    'VND',
    'RON',
    'PHP',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'CVE',
    'QAR',
    'JOD',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'BYN',
    'THB',
    'FJD',
    'SAR',
    'MDL',
    'EUR',
    'BWP',
    'DKK',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'SRD',
    'BSD',
    'ZMW',
    'RWF',
    'DJF'
  ],
  XCD: [
    'QAR',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'CAD',
    'SAR',
    'DKK',
    'ILS',
    'CNY',
    'UAH',
    'PEN',
    'GEL',
    'NOK',
    'TND',
    'BRL',
    'BDT',
    'PKR',
    'KES',
    'XOF',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'AUD',
    'ARS',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'VND',
    'RON',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'LKR',
    'CHF',
    'FJD',
    'INR',
    'HKD',
    'MAD',
    'KRW',
    'LBP',
    'PGK',
    'MWK',
    'BGN',
    'AMD',
    'DZD',
    'XPF',
    'NPR',
    'SRD',
    'GHS',
    'BZD',
    'ETB',
    'BSD',
    'UYU',
    'GTQ',
    'MKD',
    'CRC',
    'AZN',
    'MUR',
    'MZN',
    'LAK',
    'ALL',
    'PYG',
    'JMD',
    'VUV',
    'BOB',
    'SCR',
    'DOP',
    'RWF',
    'DJF',
    'KGS',
    'MGA',
    'KZT',
    'TZS',
    'ISK',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'BYN',
    'MDL',
    'ZMW',
    'BAM',
    'BWP',
    'KYD',
    'ANG',
    'MNT',
    'GNF',
    'RSD',
    'NAD',
    'CVE'
  ],
  XOF: [
    'INR',
    'HKD',
    'XCD',
    'MAD',
    'KRW',
    'QAR',
    'LBP',
    'JOD',
    'TRY',
    'MXN',
    'XAF',
    'BGN',
    'GBP',
    'PLN',
    'ZAR',
    'SEK',
    'THB',
    'DZD',
    'XPF',
    'CAD',
    'SAR',
    'GHS',
    'DKK',
    'ILS',
    'UYU',
    'CNY',
    'CRC',
    'UAH',
    'MUR',
    'ALL',
    'PEN',
    'GEL',
    'NOK',
    'JMD',
    'TND',
    'BRL',
    'BDT',
    'PKR',
    'KES',
    'DOP',
    'EUR',
    'IDR',
    'EGP',
    'USD',
    'OMR',
    'CLP',
    'COP',
    'AED',
    'TZS',
    'AUD',
    'ISK',
    'ARS',
    'KWD',
    'RUB',
    'NGN',
    'CZK',
    'BYN',
    'VND',
    'RON',
    'PHP',
    'JPY',
    'TWD',
    'MYR',
    'BHD',
    'SGD',
    'HUF',
    'NZD',
    'LKR',
    'CHF',
    'FJD',
    'BWP',
    'KYD',
    'ANG',
    'MNT',
    'GNF',
    'RSD',
    'NAD',
    'CVE',
    'PGK',
    'MWK',
    'AMD',
    'SRD',
    'NPR',
    'BZD',
    'ETB',
    'BSD',
    'GTQ',
    'MKD',
    'AZN',
    'MZN',
    'LAK',
    'PYG',
    'VUV',
    'BOB',
    'SCR',
    'KGS',
    'MGA',
    'KZT',
    'MOP',
    'BBD',
    'HNL',
    'BND',
    'MDL',
    'RWF',
    'DJF',
    'ZMW',
    'BAM'
  ],
  XPF: [
    'KRW',
    'PHP',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'THB',
    'SAR',
    'EUR',
    'DKK',
    'ILS',
    'CNY',
    'USD',
    'TRY',
    'NOK',
    'PLN',
    'BRL',
    'PKR',
    'KES',
    'IDR',
    'EGP',
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'JPY',
    'MYR',
    'SGD',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'HKD',
    'DJF',
    'ANG',
    'NGN',
    'RSD',
    'MGA',
    'VND',
    'RON',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'CVE',
    'QAR',
    'JOD',
    'XAF',
    'BYN',
    'FJD',
    'MDL',
    'BWP',
    'MAD',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'XCD',
    'GEL',
    'PYG',
    'LAK',
    'TND',
    'ALL',
    'BGN',
    'GNF',
    'DZD',
    'SCR',
    'DOP',
    'SRD',
    'ETB',
    'MKD',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'KZT',
    'ARS',
    'GHS',
    'NPR',
    'MOP',
    'BZD',
    'BSD',
    'CZK',
    'VUV',
    'KGS',
    'MZN',
    'TWD',
    'PEN',
    'BHD',
    'ZMW',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'AMD',
    'HNL',
    'XOF',
    'KYD',
    'RWF'
  ],
  ZAR: [
    'LBP',
    'AMD',
    'PGK',
    'GHS',
    'NPR',
    'QAR',
    'JOD',
    'XAF',
    'BGN',
    'BRL',
    'SEK',
    'DZD',
    'XPF',
    'FJD',
    'MZN',
    'SAR',
    'DKK',
    'ILS',
    'ETB',
    'UYU',
    'PEN',
    'CNY',
    'CRC',
    'LAK',
    'ALL',
    'UAH',
    'JMD',
    'MUR',
    'BDT',
    'BOB',
    'XOF',
    'KYD',
    'NOK',
    'RSD',
    'PKR',
    'MGA',
    'KES',
    'TZS',
    'BBD',
    'BND',
    'IDR',
    'EGP',
    'OMR',
    'CLP',
    'BYN',
    'CHF',
    'ISK',
    'KZT',
    'HNL',
    'ARS',
    'INR',
    'MOP',
    'KWD',
    'GTQ',
    'RUB',
    'NGN',
    'RON',
    'LKR',
    'PHP',
    'VND',
    'JPY',
    'TWD',
    'MYR',
    'XCD',
    'MXN',
    'BHD',
    'GEL',
    'PYG',
    'SGD',
    'TND',
    'NZD',
    'SCR',
    'DOP',
    'BWP',
    'HKD',
    'MAD',
    'COP',
    'KRW',
    'BAM',
    'NAD',
    'BZD',
    'BSD',
    'KGS',
    'SRD',
    'MKD',
    'AZN',
    'RWF',
    'DJF',
    'VUV',
    'MDL',
    'ZMW',
    'ANG',
    'GNF',
    'MNT',
    'CVE',
    'MWK',
    'TRY',
    'PLN',
    'AUD',
    'GBP',
    'CAD',
    'CZK',
    'HUF',
    'THB',
    'EUR',
    'USD',
    'AED'
  ],
  ZMW: [
    'IDR',
    'EGP',
    'AED',
    'AUD',
    'KWD',
    'RUB',
    'JPY',
    'MYR',
    'SGD',
    'GBP',
    'NZD',
    'CHF',
    'CAD',
    'INR',
    'HKD',
    'KRW',
    'PHP',
    'MXN',
    'HUF',
    'ZAR',
    'SEK',
    'THB',
    'SAR',
    'EUR',
    'DKK',
    'CNY',
    'USD',
    'TRY',
    'NOK',
    'BRL',
    'PKR',
    'KES',
    'MKD',
    'BAM',
    'OMR',
    'MWK',
    'CLP',
    'UAH',
    'MUR',
    'KZT',
    'ARS',
    'GHS',
    'NPR',
    'MOP',
    'BZD',
    'BSD',
    'CZK',
    'VUV',
    'KGS',
    'MZN',
    'TWD',
    'PEN',
    'BHD',
    'JMD',
    'BDT',
    'BOB',
    'ISK',
    'AMD',
    'HNL',
    'XOF',
    'KYD',
    'RWF',
    'DJF',
    'ANG',
    'NGN',
    'RSD',
    'MGA',
    'VND',
    'RON',
    'TZS',
    'BBD',
    'LBP',
    'PGK',
    'BND',
    'CVE',
    'QAR',
    'JOD',
    'BYN',
    'FJD',
    'MDL',
    'BWP',
    'MAD',
    'ILS',
    'UYU',
    'MNT',
    'GTQ',
    'CRC',
    'NAD',
    'AZN',
    'LKR',
    'XCD',
    'GEL',
    'PYG',
    'LAK',
    'TND',
    'XAF',
    'ALL',
    'BGN',
    'PLN',
    'GNF',
    'DZD',
    'XPF',
    'SCR',
    'DOP',
    'SRD',
    'ETB'
  ]
};

export const PeggedCurrencies: Record<string, { peggedToCurrency: string; ratio: number }> = {
  SAR: { peggedToCurrency: 'USD', ratio: 0.27 },
  AED: { peggedToCurrency: 'USD', ratio: 0.27 },
  BGN: { peggedToCurrency: 'EUR', ratio: 0.51 },
  DKK: { peggedToCurrency: 'EUR', ratio: 0.13 }
};

export function transformPeggedSymbols(symbols: string[]): string[] {
  return [
    ...new Set<string>(
      symbols.map((symbol: string) => {
        const { fromCurrency, toCurrency } = convertForexSymbolStringToCurrencyPair(symbol);
        if (toCurrency in PeggedCurrencies) {
          const peggedTo = PeggedCurrencies[toCurrency].peggedToCurrency;
          if (peggedTo !== fromCurrency) {
            const { symbol } = getForexCurrencyParams(fromCurrency, peggedTo);
            return symbol;
          }
        }
        if (fromCurrency in PeggedCurrencies) {
          const peggedTo = PeggedCurrencies[fromCurrency].peggedToCurrency;
          if (peggedTo !== toCurrency) {
            const { symbol } = getForexCurrencyParams(peggedTo, toCurrency);
            return symbol;
          }
        }
        return symbol;
      })
    )
  ];
}

export async function convertToPegged(fromCurrency: string, toCurrency: string, amount?: number) {
  if (isCurrencyPegged(fromCurrency)) {
    const { peggedToCurrency } = PeggedCurrencies[fromCurrency];
    fromCurrency = peggedToCurrency;
  } else if (isCurrencyPegged(toCurrency)) {
    const { peggedToCurrency, ratio } = PeggedCurrencies[toCurrency];
    toCurrency = peggedToCurrency;
    amount ? (amount = amount * ratio) : null;
  }

  return { fromCurrency, toCurrency, amountInPegged: amount ? +amount.toFixed(6) : undefined };
}

function isCurrencyPegged(currency: string) {
  if (currency in PeggedCurrencies) {
    return currency;
  }
  return null;
}
