import React from 'react';
import './BaseTablePaginator.scss';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import { Table } from '@tanstack/react-table';

export default function BaseTablePaginator<D>(props: BaseTablePaginatorProps<D>) {
  const { table, numResults, disabled } = props;
  const { pageSize, pageIndex } = table.getState().pagination;
  const totalPagesCount = Math.ceil(numResults / pageSize);
  const isFirstPage = pageIndex === 0;
  const lastPageIndex = totalPagesCount - 1;

  return (
    <div className="base-table-paginator-container">
      <div className="pagination">
        <button
          type="button"
          data-testid="first-page"
          className="pagination-button first-page"
          onClick={() => table.setPageIndex(0)}
          disabled={disabled || isFirstPage}
        >
          First
        </button>
        <button
          type="button"
          data-testid="previous-page"
          className="pagination-button previous-page"
          onClick={() => table.previousPage()}
          disabled={disabled || isFirstPage}
        >
          <ArrowLeft2 />
        </button>
        <input
          className="page-input"
          data-testid="page-number"
          value={pageIndex + 1}
          disabled={disabled || numResults === 0}
          onChange={(e) => {
            const newPageNumber = Number(e.target.value);
            const pageIndex = Number.isNaN(newPageNumber) ? 0 : newPageNumber - 1;
            table.setPageIndex(pageIndex);
          }}
        />
        <button
          type="button"
          data-testid="next-page"
          className="pagination-button next-page"
          onClick={() => table.nextPage()}
          disabled={disabled || numResults === 0 || pageIndex >= lastPageIndex}
        >
          <ArrowRight2 />
        </button>
        <button
          type="button"
          data-testid="last-page"
          className="pagination-button last-page"
          onClick={() => table.setPageIndex(lastPageIndex)}
          disabled={disabled || numResults === 0 || pageIndex === lastPageIndex}
        >
          Last
        </button>
      </div>
      <div className="results">{`${numResults} results`}</div>
    </div>
  );
}

type BaseTablePaginatorProps<D> = {
  table: Table<D>;
  numResults: number;
  disabled?: boolean;
};
