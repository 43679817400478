import React, { PropsWithChildren, ReactElement } from 'react';
import { SeparatorRow } from '../separator-row/SeparatorRow';

export type FilterRowProps = PropsWithChildren<object>;

export function FilterRow(props: FilterRowProps): ReactElement {
  return (
    <SeparatorRow className="filter-row" gap="16px" separatorHeight="15px">
      {props.children}
    </SeparatorRow>
  );
}
