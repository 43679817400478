import React, { ReactElement, useEffect, useState } from 'react';
import DetailsPage from 'components/layout/DetailsPage';
import { Card } from '@grain/web-components/layout/Card';
import { CurrenciesTable } from './CurrenciesTable';
import { CurrenciesFilters } from './CurrenciesFilters';
import { GrainCurrencyPair } from '@grain/core-types';
import { ButtonRow } from '@grain/web-components/button-row/ButtonRow';
import { IconicButton } from '@grain/web-components/iconic-button/IconicButton';
import { Add, Chart21, DocumentUpload } from 'iconsax-react';
import './CurrenciesPage.scss';
import UpdateForwardPointsDialog from 'components/forwards/UpdateForwardPointsDialog';
import { useGrainConfig, useRefreshGrainConfig } from 'hooks';
import AddCurrencyPairDialog from 'components/currencies/AddCurrencyPairDialog';
import EditGrainConfigDialog from 'components/currencies/EditGrainConfigDialog';
import { ALL_CURRENCIES_OPTION } from '@grain/web-components/currency-filter-select/CurrencyFilterSelect';
import { useSearchParams } from 'react-router-dom';
import { getCurrencyPairQueryParams } from './currencies-utils';

export const GRAIN_CURRENCY_PAIR_FILTERS_DEFAULT_SELECTION: Partial<GrainCurrencyPair> = {
  fromCurrency: ALL_CURRENCIES_OPTION.value,
  toCurrency: ALL_CURRENCIES_OPTION.value
};

export function CurrenciesPage(): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();

  const { refreshGrainConfig } = useRefreshGrainConfig();
  const [filters, setFilters] = useState<Partial<GrainCurrencyPair>>({
    fromCurrency: searchParams.get('fromCurrency') || ALL_CURRENCIES_OPTION.value,
    toCurrency: searchParams.get('toCurrency') || ALL_CURRENCIES_OPTION.value
  });
  const { grainConfig } = useGrainConfig();
  useEffect(() => {
    setSearchParams(
      getCurrencyPairQueryParams({
        fromCurrency: filters.fromCurrency,
        toCurrency: filters.toCurrency
      })
    );
  }, [filters.fromCurrency, filters.toCurrency, setSearchParams]);

  return (
    <DetailsPage title="Currencies Settings" subtitle="Manage Grain's settings">
      <div className="currencies-content">
        <div className="currencies-controls">
          <CurrenciesFilters onChange={setFilters} dropdownSelection={filters} />
          <ButtonRow>
            <UpdateForwardPointsDialog
              onUploadSucceed={(_, url: string) => alert(url)}
              triggerComponent={<IconicButton icon={DocumentUpload} text="Forward points" />}
            />
            {grainConfig && (
              <EditGrainConfigDialog
                initialData={grainConfig}
                onCloseAfterSuccess={refreshGrainConfig}
                triggerComponent={<IconicButton icon={Chart21} text="Config Details" />}
              />
            )}
            <AddCurrencyPairDialog onSubmitCallback={setFilters} trigger={<IconicButton icon={Add} text="Add Currency Pair" />} />
          </ButtonRow>
        </div>
        <Card>
          <CurrenciesTable
            {...filters}
            onSubmitCallback={() =>
              setFilters({
                fromCurrency: ALL_CURRENCIES_OPTION.value,
                toCurrency: ALL_CURRENCIES_OPTION.value
              })
            }
          />
        </Card>
      </div>
    </DetailsPage>
  );
}
