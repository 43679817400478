import {
  AddGrainCurrencyPairRequestBody,
  GetCurrencyPairProvidersStatusResponse,
  GrainConfig,
  GrainCurrencyPair,
  Partner
} from '@grain/admin-console-api-types';
import { BaseService } from '@grain/web-utils';

export default class GrainSettingsService extends BaseService {
  private static instance: GrainSettingsService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new GrainSettingsService(baseUrl, handleError);
  }

  public static getInstance(): GrainSettingsService {
    return this.instance;
  }

  async addNewCurrencyPair(data: AddGrainCurrencyPairRequestBody): Promise<void> {
    await this.authenticatedCall({
      url: 'grain-settings/currency-pairs',
      method: 'POST',
      data
    });
  }

  async getGrainCurrencyPairs(): Promise<GrainCurrencyPair[]> {
    const { grainCurrencyPairs } = await this.authenticatedCall<CurrencyPairsResponse>({
      url: 'grain-settings/currency-pairs',
      method: 'GET'
    });

    return grainCurrencyPairs;
  }

  async getGrainCurrencyPairPartners(id: string): Promise<Partner[]> {
    const response = await this.authenticatedCall<{ partners: Partner[] }>({
      url: `grain-settings/currency-pairs/${id}/partners`,
      method: 'GET'
    });

    return response.partners;
  }

  async getProviderStatuses(currencyPairs: string[]): Promise<GetCurrencyPairProvidersStatusResponse['providerStatuses']> {
    const { providerStatuses } = await this.authenticatedCall<GetCurrencyPairProvidersStatusResponse>({
      url: 'grain-settings/providers/status',
      method: 'GET',
      params: { currencyPairs: currencyPairs.join(',') }
    });

    return providerStatuses;
  }

  async getGrainConfig(): Promise<GrainConfig> {
    const res: GrainConfigResponse = await this.authenticatedCall({
      url: 'grain-settings/config',
      method: 'GET'
    });
    return res.grainConfig;
  }

  async removeGrainCurrencyPair(id: string): Promise<void> {
    await this.authenticatedCall({
      url: `grain-settings/currency-pairs/${id}`,
      method: 'DELETE'
    });
  }

  async updateGrainConfig(config: Partial<GrainConfig>): Promise<void> {
    await this.authenticatedCall({
      url: `grain-settings/config`,
      data: { config },
      method: 'PUT'
    });
  }
}

export interface CurrencyPairsResponse {
  grainCurrencyPairs: GrainCurrencyPair[];
}

export interface GrainConfigResponse {
  grainConfig: GrainConfig;
}
