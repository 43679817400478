import React, { ReactElement } from 'react';
import './DialogBaseStep.scss';
import classNames from 'classnames';

export default function DialogBaseStep(props: DialogBaseStepProps) {
  const { className, children, button, formId } = props;
  const buttonElement = formId && button ? React.cloneElement(button, { form: formId }) : button;
  return (
    <div className={classNames('dialog-base-step-container', className)}>
      {children}
      {buttonElement}
    </div>
  );
}

type DialogBaseStepProps = {
  className: string;
  children: React.ReactNode;
  button?: ReactElement;
  formId?: string;
};
