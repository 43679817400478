import { ExtraErrorParams } from '@grain/core-types';
import { StatusCodes } from 'http-status-codes';
import { HttpError } from './http-error';

export const TOKEN_EXPIRED_ERROR_MESSAGE = 'Token Expired';

export class TokenExpiredError extends HttpError {
  constructor(extraParams: ExtraErrorParams = {}) {
    super(StatusCodes.UNAUTHORIZED, TOKEN_EXPIRED_ERROR_MESSAGE, extraParams);
  }
}
