import { ActionRejection, OrganizationType } from '@grain/core-types';
import { convertISOStringsToDates } from '@grain/formatters';
import { BaseService } from '@grain/web-utils';

export interface ActionRejectionsResponse {
  actionRejections: ActionRejection[];
}

export interface ActionRejectionsRequestParams {
  organizationType?: OrganizationType;
  customerId?: string;
}

export default class ActionRejectionsService extends BaseService {
  private static instance: ActionRejectionsService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new ActionRejectionsService(baseUrl, handleError);
  }

  public static getInstance(): ActionRejectionsService {
    return this.instance;
  }

  async getActionRejectionsRequest(params: ActionRejectionsRequestParams): Promise<ActionRejection[]> {
    const res: ActionRejectionsResponse = await this.authenticatedCall({
      url: 'action-rejections/',
      params,
      method: 'GET'
    });

    return convertISOStringsToDates(res.actionRejections);
  }
}
