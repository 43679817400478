import { useQuery } from '@tanstack/react-query';
import { queries } from '../queries';

export const usePartner = (partnerId?: string) => {
  const { data, isLoading, isError, refetch } = useQuery({
    ...queries.partner.detail(partnerId as string),
    enabled: !!partnerId
  });

  return {
    partner: data,
    isLoadingPartner: isLoading,
    isErrorLoadingPartner: isError,
    refetchPartner: refetch
  };
};
