import React from 'react';
import './BaseIcon.scss';
import classNames from 'classnames';

export default function BaseIcon(props: BaseIconProps) {
  const { className, children, size = 18, appearance = 'dark', colorMethod = 'stroke' } = props;

  return (
    <div className={classNames('base-icon-container', className, appearance, colorMethod)} style={{ width: size }}>
      {children}
    </div>
  );
}

export type BaseIconProps = {
  className?: string;
  children: React.ReactNode;
  appearance?: 'light' | 'dark' | 'grey' | 'blue' | 'secondary-grey';
  colorMethod?: 'stroke' | 'fill';
  size?: number;
};
