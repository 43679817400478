import { ParsingOptions, read, Sheet2JSONOpts, utils, WorkBook, WorkSheet } from 'xlsx';

export type Row = {
  [col: string]: any;
};

export type Column = {
  id: string;
  header: any;
  accessorFn: ColumnCellAccessor;
};

export type ColumnCellAccessor = (row: Row) => any;

export type DataSet = {
  [index: string]: WorkSheet;
};

const range = (length: number, start = 0) => Array.from({ length }, (v, k) => start + k);

export function getRowsCols<T extends Row>(
  data: DataSet,
  sheetName: string,
  accessorGenerator = (id: string) => (row: Row) => row[id],
  sheetParser?: (worksheet: WorkSheet, opts?: Sheet2JSONOpts) => T[]
): { rows: T[]; columns: Column[] } {
  const rows = sheetParser ? sheetParser(data[sheetName]) : utils.sheet_to_json<T>(data[sheetName], { header: 1 });
  if ((rows[0][0] as string)?.toLowerCase().includes('pdf')) {
    throw new Error('PDF File is not a spreadsheet');
  }

  const header = rows[0];
  const maxRowLength = rows.map((row) => Object.keys(row).length).reduce((a, b) => Math.max(a, b), -Infinity);
  const columns: Column[] = range(maxRowLength)
    .map((id) => id.toString())
    .map((id) => ({
      id,
      header: header[id] ?? utils.encode_col(+id),
      accessorFn: accessorGenerator(id)
    }));

  return { rows: rows.slice(1), columns };
}

export const XLSX_READ_OPTIONS: ParsingOptions = {
  WTF: true,
  dense: true,
  cellDates: true
};

export function readExcelFile(buffer: any, options: ParsingOptions = XLSX_READ_OPTIONS): WorkBook {
  // Buffer type is used in Node.js whereas base64 is the browser's default
  return read(buffer, { ...options, type: 'buffer' });
}
