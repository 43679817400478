import { isConnectedToBankingVendor } from '../utils';
import CreateCounterpartyDialog from '../../common/CreateCounterpartyDialog';
import React from 'react';
import { useEnableTransferMate } from './hooks';
import { AdminConsoleApiPartner, EnableTransferMateRequestPayload } from '@grain/admin-console-api-types';
import { PartnerSettingStatus } from './PartnerSettingStatus';

export const PartnerTransferMateSetting = (props: PartnerTransferMateSettingProps) => {
  const { enableTransferMate } = useEnableTransferMate();

  const isTransferMateEnabled = isConnectedToBankingVendor(props.partner.organizations ?? []);
  if (isTransferMateEnabled) {
    return <PartnerSettingStatus settingDisplayName="TransferMate" settingEnabled={true} />;
  }

  return (
    <CreateCounterpartyDialog
      dialogContent="Enabling live mode for a Partner and connect them with TransferMate platform."
      dialogTitle="Enable TransferMate"
      trigger={<PartnerSettingStatus settingDisplayName="TransferMate" settingEnabled={false} />}
      onCounterpartyCreationRequested={(payload: EnableTransferMateRequestPayload) =>
        enableTransferMate({ partnerId: props.partner.id, data: payload })
      }
    />
  );
};

export type PartnerTransferMateSettingProps = {
  partner: AdminConsoleApiPartner;
};
