import React from 'react';
import './BaseSideBar.scss';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

export default function BaseSideBar(props: BaseSideBarProps) {
  const { className, badge, items, footer } = props;
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className={classNames('base-side-bar-container', className)}>
      <div className="side-bar-upper">
        <div className="logo-container">
          <picture className="logo" onClick={handleLogoClick}>
            <source media="(max-width: 1365px)" srcSet="/images/web-components/logo-tablet.svg" />
            <img alt="logo" src="/images/web-components/logo.svg" />
          </picture>
        </div>
        {badge}
      </div>
      <div className="side-bar-items">{items}</div>
      {footer && <div className="side-bar-footer">{footer}</div>}
    </div>
  );
}

type BaseSideBarProps = {
  className?: string;
  badge?: React.ReactNode;
  items: React.ReactNode;
  footer?: React.ReactNode;
};
