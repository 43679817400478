import React from 'react';
import classNames from 'classnames';
import './HorizontalSelector.scss';

export default function HorizontalSelector<O extends string = string>(props: HorizontalSelectorProps<O>) {
  const handleOptionClick = (option: O) => {
    if (props.disabled) {
      return;
    }

    if (option === props.selected) {
      return;
    }

    props.onSelected(option);
  };

  const options = props.options.map((option) =>
    typeof option === 'string'
      ? {
          value: option,
          label: option
        }
      : option
  );

  return (
    <div className={classNames('horizontal-selector-container', props.className)}>
      {options.map(({ value, label }) => (
        <div
          className={classNames('option', {
            selected: value === props.selected
          })}
          key={value}
          onClick={() => handleOptionClick(value)}
        >
          {label}
        </div>
      ))}
    </div>
  );
}

type HorizontalSelectorProps<O extends string = string> = {
  className?: string;
  selected?: O;
  options: HorizontalSelectorOption<O>[];
  disabled?: boolean;
  onSelected: (selected: O) => void;
};

type HorizontalSelectorOption<O extends string = string> =
  | O
  | {
      value: O;
      label: string | React.ReactNode;
    };
