import React, { useEffect } from 'react';
import BaseAutoComplete from '../forms/BaseAutoComplete';
import CurrencyFlag from '../CurrencyFlag/CurrencyFlag';
import './CurrencyFlagSelector.scss';

export default function CurrencyFlagSelector({ name, currency, onCurrencyChange, options, disabled }: CurrencyFlagSelectorProps) {
  const onChange = (name: string, value: string) => {
    if (onCurrencyChange) {
      onCurrencyChange(name, value);
    }
  };

  useEffect(() => {
    if (!currency) {
      onChange(name, options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  return (
    <div className="flag-selector">
      {currency && (
        <>
          <CurrencyFlag currency={currency} size={18} />
          <BaseAutoComplete
            className="flag-dropdown"
            variant="standard"
            name={name}
            value={currency}
            options={options.map((currency) => ({
              value: currency,
              text: currency
            }))}
            onValueChange={onChange}
            hideErrorText
            borderless
            disabled={disabled}
            onClick={(e) => e.stopPropagation()}
          />
        </>
      )}
    </div>
  );
}

export type CurrencyFlagSelectorProps = {
  name: string;
  currency?: string;
  options: string[];
  disabled?: boolean;
  onCurrencyChange?: (name: string, value: string) => void;
};
