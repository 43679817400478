import { GrainCurrencyPair } from '@grain/core-types';
import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import GrainSettingsService from 'services/GrainSettingsService';

export const settingsQueries = createQueryKeyStore({
  grainConfig: null,
  grainCurrencyPairs: {
    all: () => ({
      queryKey: ['allGrainCurrencyPairs'],
      queryFn: () => GrainSettingsService.getInstance().getGrainCurrencyPairs()
    }),
    partners: (id: string | undefined) => ({
      queryKey: ['id', id],
      queryFn: () => (!id ? [] : GrainSettingsService.getInstance().getGrainCurrencyPairPartners(id))
    })
  },
  providerStatuses: {
    query: (currencyPairs: GrainCurrencyPair[] = []) => {
      const concatCurrencyPairs = [
        ...new Set(
          currencyPairs?.map((currencyPair) => `${currencyPair.fromCurrency}${currencyPair.toCurrency}`).sort((a, b) => a.localeCompare(b))
        )
      ];

      return {
        queryKey: ['currencyPairs', ...concatCurrencyPairs],
        queryFn: () => GrainSettingsService.getInstance().getProviderStatuses(concatCurrencyPairs)
      };
    }
  }
});
