import React, { PropsWithChildren, ReactElement } from 'react';
import { Chip, ChipText } from '../chip/Chip';
import './HealthStatusChip.scss';
import classNames from 'classnames';

export type HealthStatusChipProps = PropsWithChildren<{
  status: 'healthy' | 'down' | null;
  text: string;
  className?: string;
}>;

export function HealthStatusChip(props: HealthStatusChipProps): ReactElement {
  const { status, className } = props;

  return (
    <Chip className={classNames(`health-status-chip`, className)} disabled={status === 'down'}>
      {status && <span className={`health-status-indicator health-status-indicator-${status}`}>&bull;</span>}
      <ChipText text={props.text} />
    </Chip>
  );
}
