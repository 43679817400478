import { useMutation, useQueryClient } from '@tanstack/react-query';
import PartnersService, { type CreateWalletParams } from 'services/PartnersService';
import { queries } from '../../../queries';

export const useCreateWallet = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createWallet,
    isError: walletCreationFailed,
    isLoading: isCreatingWallet,
    isSuccess: walletCreationSucceeded,
    reset: resetCreateWalletMutation,
    error: walletCreationError
  } = useMutation<void, Error, CreateWalletParams>((params) => PartnersService.getInstance().createWallet(params), {
    async onSuccess() {
      await queryClient.refetchQueries(queries.partners.wallets.list._def);
    }
  });

  return {
    createWallet,
    walletCreationFailed,
    isCreatingWallet,
    walletCreationSucceeded,
    resetCreateWalletMutation,
    walletCreationError
  };
};
