import { AdminConsoleApiPartner } from '@grain/admin-console-api-types';
import { hasLiveApiKeys } from '../utils';
import { PartnerSettingStatus } from './PartnerSettingStatus';
import { useEnableApiKeys } from './hooks';

export const PartnerApiKeysSetting = (props: PartnerApiKeysSettingProps) => {
  const { enableApiKeys } = useEnableApiKeys();
  const apiKeysEnabled = hasLiveApiKeys(props.partner.organizations ?? []);

  if (apiKeysEnabled) {
    return <PartnerSettingStatus settingDisplayName="API Keys" settingEnabled={true} />;
  }

  return <PartnerSettingStatus settingEnabled={false} settingDisplayName="API Keys" onClick={() => enableApiKeys(props.partner.id)} />;
};

export type PartnerApiKeysSettingProps = {
  partner: AdminConsoleApiPartner;
};
