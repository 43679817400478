import { AdminConsoleApiUsersResponse, AdminConsoleRole, AdminConsoleUser } from '@grain/admin-console-api-types';
import { UsersService as BaseUsersService } from '@grain/web-utils';

export default class UsersService extends BaseUsersService {
  protected static instance: UsersService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    BaseUsersService.initialize(baseUrl, handleError);
    this.instance = new UsersService(baseUrl, handleError);
  }

  public static getInstance(): UsersService {
    return this.instance;
  }

  async getUsersRequest(): Promise<AdminConsoleUser[]> {
    const res: AdminConsoleApiUsersResponse = await this.authenticatedCall({
      url: 'users'
    });
    return res.users;
  }

  async updateUserRole(userId: string, role: AdminConsoleRole) {
    await this.authenticatedCall({
      url: `users/${userId}/role`,
      data: { role },
      method: 'POST'
    });
  }
}
