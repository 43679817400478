import { Company } from './company';
import { Transaction } from './transaction';
import { SpotInboundFundTransfer, SpotOutboundFundTransfer } from './fund-transfer';

export enum SpotStatus {
  Completed = 'Completed',
  Pending = 'Pending',
  Failed = 'Failed'
}

export interface Spot {
  id: string;
  toCurrency: string;
  fromCurrency: string;
  toCurrencyAmount: number;
  completedAt?: Date;
  quote: number;
  transactionId: string;
  createdAt: Date;
  companyId: string;
  company?: Company;
  status: SpotStatus;
  transaction: Transaction;
  spotOutboundFundTransfer?: SpotOutboundFundTransfer;
  spotInboundFundTransfer?: SpotInboundFundTransfer;
}

export type SpotProposalResponsePayload = {
  id: string;
  fromCurrency: string;
  toCurrency: string;
  toCurrencyAmount?: number;
  fromCurrencyAmount?: number;
  quote: number;
  validUntilTs: number;
};

export type SpotActionResult = {
  spotId: string;
  transactionId?: string;
};
