import { BaseService } from '@grain/web-utils';
import { ManagingPlatform } from '@grain/admin-console-api-types';
import { GetTreasuryWalletsResponseBody } from '@grain/payments-service-types';

export class TreasuryService extends BaseService {
  private static instance: TreasuryService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new TreasuryService(baseUrl, handleError);
  }

  public static getInstance(): TreasuryService {
    return this.instance;
  }

  async addPartnerBeneficiariesToTreasury(partnerId: string): Promise<void> {
    await this.authenticatedCall({
      data: { managedBy: ManagingPlatform.TransferMate, partnerId },
      method: 'POST',
      url: `treasury/create-payment-beneficiary-for-partner`
    });
  }

  async executePennyTestToPartner(params: ExecutePennyTestToPartnerParams): Promise<void> {
    await this.authenticatedCall({
      url: `treasury/execute-penny-test-to-partner`,
      data: params,
      method: 'POST'
    });
  }

  async syncGrainTreasuryBankAccounts(): Promise<void> {
    await this.authenticatedCall({
      method: 'POST',
      url: 'treasury/enable'
    });
  }

  async getWallets(): Promise<GetTreasuryWalletsResponseBody> {
    return await this.authenticatedCall({
      method: 'GET',
      url: 'treasury/wallets'
    });
  }
}

export interface ExecutePennyTestToPartnerParams {
  amount: number;
  currency: string;
  partnerId: string;
}
