import React from 'react';
import BaseTable, { BaseTableColumnDef, BaseTableColumnDefTextAlignType, TableRow } from '@grain/web-components/table/BaseTable';
import { asAmount, asDateString } from '@grain/web-components/utils/formatting';
import SpotStatusBadge from './SpotStatusBadge';
import { numericSortByColumnId, sortByDates } from '@grain/web-components/table/sorting';
import { ListSpotsResponseSpot, SpotStatus } from '@grain/admin-console-api-types';

export default function SpotsTable(props: SpotsTableProps) {
  const { spots, pageSize = 10, paginated, loading, sortable } = props;

  const sortBySpotStatus = (rowA: TableRow<ListSpotsResponseSpot>, rowB: TableRow<ListSpotsResponseSpot>, key: string) => {
    const sortOrder: { [key: string]: number } = {
      [SpotStatus.Pending]: 1,
      [SpotStatus.Completed]: 2
    };

    const statusA = String(rowA.original[key as keyof ListSpotsResponseSpot]);
    const statusB = String(rowB.original[key as keyof ListSpotsResponseSpot]);

    if (statusA === statusB) {
      return sortByDates(rowA, rowB, 'creationDate', true);
    }
    if (!statusA || !statusB) return -1;
    return sortOrder[statusA] > sortOrder[statusB] ? -1 : 1;
  };

  const columns: BaseTableColumnDef<ListSpotsResponseSpot>[] = [
    {
      accessorFn: (spot) => spot.partnerName,
      id: 'partnerName',
      header: 'Partner name'
    },
    {
      accessorFn: (spot) => spot.companyName,
      id: 'companyName',
      header: 'Customer'
    },
    {
      accessorFn: (spot) => `${spot.fromCurrency} ${asAmount(spot.fromCurrencyAmount)}`,
      id: 'fromCurrencyAmount',
      header: 'From Amount',
      sortingFn: numericSortByColumnId,
      textAlignType: BaseTableColumnDefTextAlignType.Amount
    },
    {
      accessorFn: (spot) => `${spot.toCurrency} ${asAmount(spot.toCurrencyAmount)}`,
      id: 'toCurrencyAmount',
      header: 'To Amount',
      sortingFn: numericSortByColumnId,
      textAlignType: BaseTableColumnDefTextAlignType.Amount
    },
    {
      accessorFn: (spot) => spot.quote,
      id: 'quote',
      header: 'Quote',
      sortingFn: numericSortByColumnId,
      textAlignType: BaseTableColumnDefTextAlignType.Amount
    },
    {
      accessorFn: (spot) => asDateString(spot.creationDate),
      id: 'creationDate',
      header: 'Creation date',
      sortingFn: sortByDates,
      textAlignType: BaseTableColumnDefTextAlignType.Date
    },
    {
      accessorFn: (spot) => spot.managementPlatform,
      id: 'managingPlatform',
      header: 'Managing platform',
      constantWidth: 200
    },
    {
      cell: React.useCallback(({ row: { original: spot } }) => <SpotStatusBadge value={spot.status} />, []),
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      textAlignType: BaseTableColumnDefTextAlignType.Custom,
      sortingFn: sortBySpotStatus
    }
  ];

  return (
    <div className="Spots-table-container">
      <BaseTable
        columns={columns}
        data={spots}
        paginated={paginated}
        pageSize={pageSize}
        loading={loading}
        fixedLayout
        disableSorting={!sortable}
        {...(sortable ? { initialSorting: [{ id: 'creationDate', desc: true }] } : {})}
      />
    </div>
  );
}

interface SpotsTableProps {
  spots: ListSpotsResponseSpot[];
  pageSize?: number;
  paginated?: boolean;
  loading?: boolean;
  customerSpecific?: boolean;
  showTabs?: boolean;
  sortable?: boolean;
}
