import React from 'react';
import BaseInput from '../forms/BaseInput';
import BaseIcon from '../icons/BaseIcon';
import { CloseCircle, SearchNormal1 } from 'iconsax-react';
import classNames from 'classnames';
import './Searchbar.scss';

export default function Searchbar(props: SearchbarProps) {
  const { value: searchTerm, placeholder, onChange: handleSearchTermChange } = props;

  return (
    <div className="searchbar-container">
      <BaseInput
        className="searchbar-input"
        startAdornment={
          <div className="searchbar-input-search-icon">
            <BaseIcon size={14} appearance="grey">
              <SearchNormal1 />
            </BaseIcon>
          </div>
        }
        endAdornment={
          searchTerm && (
            <div className={classNames('searchbar-input-clear-icon')} onClick={() => handleSearchTermChange?.('')}>
              <BaseIcon size={16} appearance="grey">
                <CloseCircle />
              </BaseIcon>
            </div>
          )
        }
        value={searchTerm}
        hideErrorText
        extraSmall
        internalTextFieldParams={{ placeholder }}
        onChange={(e) => handleSearchTermChange?.(e.target.value)}
      />
    </div>
  );
}

export interface SearchbarProps {
  placeholder?: string;
  value?: string;
  onChange?: (searchTerm: string) => void;
}
