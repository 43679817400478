import { useMutation, useQueryClient } from '@tanstack/react-query';
import PartnersService, { EditCurrencyPairParams } from 'services/PartnersService';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import { queries } from '../../../../queries';

export const useEditCurrencyPair = () => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, isSuccess, mutateAsync } = useMutation<void, Error, EditCurrencyPairParams>({
    mutationFn: (params) => PartnersService.getInstance().editCurrencyPair(params),
    onSuccess: async () => {
      notifySuccess('Currency pair edited successfully');
      await queryClient.refetchQueries(queries.partners.currencyPairs._def);
    }
  });

  return {
    editedCurrencyPairSuccessfully: isSuccess,
    failedToEditCurrencyPair: isError,
    editCurrencyPair: mutateAsync,
    isEditingCurrencyPair: isLoading,
    editCurrencyPairError: error
  };
};
