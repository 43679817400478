import React, { ChangeEvent, useState } from 'react';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import BaseInput from '@grain/web-components/forms/BaseInput';
import FormDialog from '@grain/web-components/dialogs/FormDialog';
import { CreateForwardPeriodicSpreadRequestParams } from '@grain/admin-console-api-types';
import ForwardsService from '../../../services/ForwardsService';
import BaseDropdown from '@grain/web-components/forms/BaseDropdown';
import { MAJOR_CURRENCIES, PERIODIC_RATES_CURRENCIES_USING_SPREADS } from '@grain/rate-utils';
import { notifySuccess } from '@grain/web-components/notifications/toasters';

const EXPECTED_NUM_OF_PARAMETERS = 6;
const fromCurrencyOptions = [
  ...PERIODIC_RATES_CURRENCIES_USING_SPREADS.map((currency) => ({
    value: currency,
    text: currency
  }))
];
const toCurrencyOptions = [...MAJOR_CURRENCIES.map((currency) => ({ value: currency, text: currency }))];

export default function CreateForwardPeriodicSpreadDialog() {
  const [newData, setNewData] = useState<Partial<CreateForwardPeriodicSpreadRequestParams>>({});

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  const canSubmit = Object.keys(newData).length === EXPECTED_NUM_OF_PARAMETERS;

  const onSubmit = async () => {
    await ForwardsService.getInstance().createOrUpdateForwardPeriodicSpread({
      fromCurrency: newData.fromCurrency!,
      toCurrency: newData.toCurrency!,
      spread30: newData.spread30!,
      spread60: newData.spread60!,
      spread90: newData.spread90!,
      spread360: newData.spread360!
    });
    notifySuccess('Spread Updated Successfully');
    setNewData({});
  };

  const onClose = () => {
    setNewData({});
  };

  return (
    <FormDialog
      title="Set Forward Periodic Spread"
      trigger={<CTAButton text="Set Forward Spread" />}
      canSubmit={canSubmit}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <BaseDropdown
        name="fromCurrency"
        labelText="From Currency"
        options={fromCurrencyOptions}
        value={newData.fromCurrency || ''}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="toCurrency"
        labelText="To Currency"
        options={toCurrencyOptions}
        value={newData.toCurrency || ''}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput name="spread30" labelText="Spread 30" value={newData.spread30 || ''} onChange={onChange} hideErrorText small />
      <BaseInput name="spread60" labelText="Spread 60" value={newData.spread60 || ''} onChange={onChange} hideErrorText small />
      <BaseInput name="spread90" labelText="Spread 90" value={newData.spread90 || ''} onChange={onChange} hideErrorText small />
      <BaseInput name="spread360" labelText="Spread 360" value={newData.spread360 || ''} onChange={onChange} hideErrorText small />
    </FormDialog>
  );
}
