import { useCallback, useEffect, useState } from 'react';
import type { PaginationState, TableOptions } from '@tanstack/react-table';
import { usePagination, UsePaginationOptions } from './usePagination';

export interface ControlledPaginationOptions extends UsePaginationOptions {
  serverSide?: boolean;
  totalItems?: number;
  pageIndex: number;
  onPageIndexChange?: (pageIndex: number) => void;
}

export function useControlledPagination(options: ControlledPaginationOptions): {
  paginationProps: Pick<TableOptions<unknown>, 'autoResetPageIndex' | 'manualPagination' | 'onPaginationChange' | 'rowCount' | 'state'>;
  paginationState: PaginationState;
  resetPageIndex: () => void;
  setPaginationState: (newState: PaginationState) => void;
} {
  const {
    initialState: { pagination: initialPaginationState },
    ...paginationProps
  } = usePagination(options).paginationProps;

  const [paginationState, setPaginationState] = useState(initialPaginationState);

  const resetPageIndex = useCallback(() => {
    setPaginationState((previousState) => ({
      ...previousState,
      pageIndex: initialPaginationState.pageIndex
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    options?.onPageIndexChange?.(paginationState.pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationState.pageIndex]);

  useEffect(() => {
    if (options.pageIndex != null && paginationState.pageIndex != options.pageIndex) {
      setPaginationState((previousState) => ({
        ...previousState,
        pageIndex: options.pageIndex
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.pageIndex]);

  return {
    paginationProps: {
      ...paginationProps,
      autoResetPageIndex: options?.autoResetPageIndex !== false && options?.serverSide !== true,
      manualPagination: options?.serverSide,
      onPaginationChange: setPaginationState,
      state: {
        pagination: paginationState
      },
      rowCount: options?.totalItems
    },
    paginationState,
    resetPageIndex,
    setPaginationState
  };
}
