import React, { ChangeEvent, useEffect, useState } from 'react';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import BaseInput from '@grain/web-components/forms/BaseInput';
import FormDialog from '@grain/web-components/dialogs/FormDialog';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import { Forward } from '@grain/core-types';
import BaseAutoComplete, { baseAutoCompleteOptions } from '@grain/web-components/forms/BaseAutoComplete';
import 'react-json-view-lite/dist/index.css';
import { collapseAllNested, JsonView } from 'react-json-view-lite';
import './ReportForwardDialog.scss';
import ForwardsService from '../../../services/ForwardsService';

const REPORT_FORWARD_INITIAL_STATE = {
  forwardId: '',
  price: 0,
  externalId: ''
};

interface ReportForwardDialogProps {
  onSubmitCallback: () => Promise<void>;
  forwards: Forward[];
}

export default function ReportForwardDialog({ onSubmitCallback, forwards }: ReportForwardDialogProps) {
  const [updateForwardData, setUpdateForwardData] = useState<ReportForwardDialogData>(REPORT_FORWARD_INITIAL_STATE);

  useEffect(() => {
    setUpdateForwardData((_) => ({
      forwardId: updateForwardData.forwardId,
      externalId: '',
      price: 0
    }));
  }, [updateForwardData.forwardId]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdateForwardData({ ...updateForwardData, [name]: value });
  };

  const onChangeBaseAutoComplete = (name: string, val: string) => {
    setUpdateForwardData({ ...updateForwardData, [name]: val });
  };

  const validate = () => {
    const { price, forwardId, externalId } = updateForwardData;
    if (!forwardId) {
      return 'forward ID is required';
    }
    if (!externalId) {
      return 'external ID is required';
    }
    if (!price) {
      return 'price is required';
    }
    if (Number.isNaN(Number(price))) {
      return 'price must be a valid number';
    }
    if (price < 0) {
      return 'price must be positive';
    }

    return '';
  };

  const onSubmit = async () => {
    await ForwardsService.getInstance().reportForward(updateForwardData);
    notifySuccess('Forward was updated successfully');
    setUpdateForwardData(REPORT_FORWARD_INITIAL_STATE);
    onSubmitCallback();
  };

  const onClose = () => {
    setUpdateForwardData(REPORT_FORWARD_INITIAL_STATE);
  };

  const selectedForward = forwards.find((forward) => forward.id === updateForwardData.forwardId);

  return (
    <FormDialog
      className="report-forward-dialog-form"
      title="Manually Update Forward"
      trigger={<CTAButton text="Manually Update Forward" />}
      canSubmit
      onClose={onClose}
      onSubmit={onSubmit}
      validate={validate}
    >
      <BaseAutoComplete
        labelText="Forward ID"
        name="forwardId"
        value={updateForwardData.forwardId}
        options={baseAutoCompleteOptions(forwards, 'id')}
        onValueChange={onChangeBaseAutoComplete}
        hideErrorText
        small
        required
      />
      <div className="json-viewer-wrapper">
        <JsonView data={selectedForward || {}} shouldExpandNode={collapseAllNested} />
      </div>
      <BaseInput
        name="price"
        labelText="Price"
        value={updateForwardData.price || ''}
        onChange={(e) => {
          const { name, value } = e.target;
          setUpdateForwardData({
            ...updateForwardData,
            [name]: parseFloat(value)
          });
        }}
        hideErrorText
        small
        required
        type="number"
        disabled={!updateForwardData.forwardId}
      />
      <BaseInput
        name="externalId"
        labelText="External ID"
        value={updateForwardData.externalId || ''}
        onChange={onChange}
        hideErrorText
        small
        required
        disabled={!updateForwardData.forwardId}
      />
    </FormDialog>
  );
}

type ReportForwardDialogData = {
  forwardId: string;
  price: number;
  externalId: string;
};
