import { useQuery } from '@tanstack/react-query';
import { queries } from 'queries';

export function useGrainCurrencyPairs() {
  const {
    data: grainCurrencyPairs,
    isError: isErrorLoadingGrainCurrencyPairs,
    isLoading: isLoadingGrainCurrencyPairs
  } = useQuery({
    ...queries.settings.grainCurrencyPairs.all(),
    keepPreviousData: true,
    refetchInterval: 30_000,
    staleTime: 5_000
  });

  return {
    isLoadingGrainCurrencyPairs,
    isErrorLoadingGrainCurrencyPairs,
    grainCurrencyPairs
  };
}
