import BaseDialog, { BaseDialogHandle } from '@grain/web-components/dialogs/BaseDialog';
import React, { useRef } from 'react';
import UploadSection from '@grain/web-components/sections/UploadSection';
import PartnersService from '../../../services/PartnersService';

export default function UploadHedgeOfflineDialog({
  onUploadSucceed,
  triggerComponent,
  onUploadStart,
  partnerId
}: UpdateForwardPointsDialogProps) {
  const dialogRef = useRef<BaseDialogHandle>(null);
  const onFileUploaded = (file: File, status: string) => {
    onUploadSucceed(file, status);
    dialogRef.current?.hideDialog();
  };

  return (
    <BaseDialog ref={dialogRef} trigger={triggerComponent}>
      <div>
        <UploadSection
          onFileUploaded={onFileUploaded}
          uploadFile={(file) => {
            onUploadStart?.();
            return PartnersService.getInstance().uploadOfflineMarkupsFile(partnerId, file);
          }}
        />
      </div>
    </BaseDialog>
  );
}

interface UpdateForwardPointsDialogProps {
  onUploadStart?: () => void;
  onUploadSucceed: (file: File, status: string) => void;
  triggerComponent: React.ReactNode;
  partnerId: string;
}
