import amplitude from 'amplitude-js';
import environment from '../environments/environment';
import AlertsService from './AlertsService';
import { buildNum, buildTime, version } from '../version';

class AnalyticsService {
  private static instance: AnalyticsService;
  private isInitialized: boolean;
  private pendingEvents: PendingEvent[];

  private constructor() {
    this.isInitialized = false;
    this.pendingEvents = [];
  }

  public static getInstance(): AnalyticsService {
    if (!this.instance) {
      this.instance = new AnalyticsService();
    }
    return this.instance;
  }

  public initAnalytics() {
    if (environment.enableAnalytics) {
      if (environment.amplitudeApiKey) {
        amplitude.getInstance().init(environment.amplitudeApiKey);
        this.isInitialized = true;
        this.logPendingEvents();
        this.logEvent('App Loaded', { buildTime, version, buildNum });
      } else {
        // eslint-disable-next-line no-console
        console.log('failed initializing analytics - ENABLE_ANALYTICS is set but amplitude API key is missing');
        AlertsService.getInstance().captureException(
          new Error('failed initializing analytics - ENABLE_ANALYTICS is set but amplitude API key is missing')
        );
      }
    }
  }

  public setUser(userId: string, userProperties: AnalyticsUserProperties) {
    if (this.isInitialized) {
      amplitude.getInstance().setUserId(userId);
      amplitude.getInstance().setVersionName(version);
      amplitude.getInstance().setUserProperties({ ...userProperties, buildTime, version, buildNum });
    }
  }

  public logEvent(eventName: string, eventProperties: { [key: string]: any } = {}, timestamp?: number) {
    if (this.isInitialized) {
      amplitude.getInstance().logEventWithTimestamp(eventName, eventProperties, timestamp || Date.now());
    } else {
      this.pendingEvents.push({
        eventName,
        eventProperties,
        timestamp: Date.now()
      });
    }
  }

  private logPendingEvents() {
    this.pendingEvents.forEach((e) => this.logEvent(e.eventName, e.eventProperties, e.timestamp));
    this.pendingEvents = [];
  }
}

export type PendingEvent = {
  eventName: string;
  eventProperties: { [key: string]: any };
  timestamp: number;
};

export type AnalyticsUserProperties = {
  email?: string;
};

export default AnalyticsService;
