import { AdminConsoleApiPaymentBeneficiary, AdminConsoleApiWallet, PaymentBeneficiaryType } from '@grain/admin-console-api-types';
import { Separator } from '@grain/web-components/separator/Separator';
import { Fragment } from 'react';
import { PartnerWalletDetailsBeneficiarySection } from './PartnerWalletDetailsBeneficiarySection';

export const PartnerWalletDetailsBeneficiariesSection = (props: PartnerWalletDetailsBeneficiariesSectionProps) => {
  const { wallet } = props;
  const fromTreasury = wallet.beneficiaries.find((beneficiary) => beneficiary.type === PaymentBeneficiaryType.GrainTreasury);
  const toTreasury = wallet.beneficiaries.find((beneficiary) => beneficiary.type === PaymentBeneficiaryType.PartnerOwnedByGrain);
  const withdraw = wallet.beneficiaries.find((beneficiary) => beneficiary.type === PaymentBeneficiaryType.PartnerOwnedByPartner);
  const beneficiariesToDisplay = [withdraw, fromTreasury, toTreasury].filter(
    (beneficiary): beneficiary is AdminConsoleApiPaymentBeneficiary => beneficiary != null
  );

  return (
    <>
      {beneficiariesToDisplay.map((beneficiary, index) => (
        <Fragment key={`${beneficiary.type}_payment_beneficiary`}>
          <PartnerWalletDetailsBeneficiarySection beneficiary={beneficiary} />

          {index != beneficiariesToDisplay.length - 1 && <Separator className="my-6" />}
        </Fragment>
      ))}
    </>
  );
};

export type PartnerWalletDetailsBeneficiariesSectionProps = {
  wallet: AdminConsoleApiWallet;
};
