import React, { ReactNode } from 'react';
import './MessageDialog.scss';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from './Dialog';
import { DialogProps } from '@radix-ui/react-dialog';
import CTAButton from '../buttons/CTAButton';

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { onAccept, onCancel, title, content, children, ...dialogProps } = props;
  const [trigger] = React.Children.toArray(children);

  return (
    <Dialog {...dialogProps}>
      {trigger}
      <DialogContent className="dialog-details-content min-w-72 bg-white">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="my-8 text-center">
          <div className="font-bold text-primary text-2xl mb-4">Are you sure?</div>
          <div className="text-secondary-grey text-base whitespace-break-spaces">{content}</div>
        </div>
        <DialogFooter className="flex flex-row justify-center sm:flex-row sm:justify-center gap-4 mb-1.5">
          <CTAButton text="Yes" appearance="white" onClick={onAccept} />
          <DialogClose asChild>
            <CTAButton text="No" appearance="blue" onClick={onCancel} />
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

interface ConfirmationDialogProps extends DialogProps {
  title: string;
  content: string;
  onAccept: () => void;
  onCancel?: () => void;
  trigger?: ReactNode;
}
