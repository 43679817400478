import React, { ReactElement } from 'react';
import './DialogDetailsStep.scss';
import classNames from 'classnames';
import DialogBaseStep from './DialogBaseStep';

export default function DialogDetailsStep(props: DialogDetailsStepProps) {
  const { className, children, summary, button } = props;
  return (
    <DialogBaseStep className={classNames('dialog-details-step-container', className)} button={button}>
      {children}
      <div className="details details-wrapper">
        {summary.map((detail) => (
          <div key={detail.text} className="detail">
            <div className="title">{detail.text}</div>
            <div className="value">{detail.value}</div>
          </div>
        ))}
      </div>
    </DialogBaseStep>
  );
}

type DialogDetailsStepProps = {
  className: string;
  children: React.ReactNode;
  summary: DialogStepSummaryEntry;
  button?: ReactElement;
};

export type DialogStepSummaryEntry = { text: string; value: string }[];
