import React, { useState } from 'react';
import classNames from 'classnames';
import './ExpandableMappingItem.scss';
import { ArrowRight2 } from 'iconsax-react';

export default function ExpandableMappingItem(props: ExpandableMappingItemProps) {
  const { title, children } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classNames('expandable-mapping-item-container', { expanded })}>
      <div className="toggleable" onClick={toggleExpanded}>
        <div className="expand-item-text">{title}</div>
        <ArrowRight2 className="expand-arrow" size={14} />
      </div>
      <div className="child-items">{children}</div>
    </div>
  );
}

type ExpandableMappingItemProps = {
  title: string;
  children: React.ReactNode;
};
