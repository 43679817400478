import { useQuery } from '@tanstack/react-query';
import { queries } from '../queries';
import { TreasuryService } from 'services/TreasuryService';

export const useTreasuryWallets = () => {
  const { data, isError, isLoading } = useQuery({
    ...queries.treasury.wallets,
    queryFn: () => TreasuryService.getInstance().getWallets()
  });

  return {
    treasuryWallets: data,
    hasFailedLoadingTreasuryWallets: isError,
    isLoadingTreasuryWallets: isLoading
  };
};
