import React from 'react';
import './DialogSuccessStep.scss';
import DialogDetailsStep, { DialogStepSummaryEntry } from './DialogDetailsStep';

export default function DialogSuccessStep(props: DialogSuccessStepProps) {
  const { imageUrl = '/images/web-components/success-animation.gif', title, note, summary } = props;
  return (
    <DialogDetailsStep className="dialog-success-step-container" summary={summary}>
      <img className="success-image" alt="success" src={imageUrl} />
      <div className="main-title">{title}</div>
      {note && <div className="note">{note}</div>}
    </DialogDetailsStep>
  );
}

type DialogSuccessStepProps = {
  imageUrl?: string;
  title: string;
  note?: string;
  summary: DialogStepSummaryEntry;
};
