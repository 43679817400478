import { AdminConsoleApiCountryConfig, UpdateCountryConfigPayload } from '@grain/admin-console-api-types';
import { BaseService } from '@grain/web-utils';

export default class CountryConfigService extends BaseService {
  private static instance: CountryConfigService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new CountryConfigService(baseUrl, handleError);
  }

  public static getInstance(): CountryConfigService {
    return this.instance;
  }

  async getCountryConfigs(): Promise<AdminConsoleApiCountryConfig[]> {
    const res: { countryConfigs: AdminConsoleApiCountryConfig[] } = await this.authenticatedCall({
      url: `compliance/country-configs`,
      method: 'GET'
    });

    return res.countryConfigs;
  }

  async updateCountryConfig(countryConfigId: string, payload: UpdateCountryConfigPayload) {
    await this.authenticatedCall({
      url: `compliance/country-configs/${countryConfigId}`,
      data: payload,
      method: 'PUT'
    });
  }
}
