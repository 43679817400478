import React, { ReactElement } from 'react';
import { paymentTypeDisplayName } from '@grain/web-utils';
import { Card } from '../layout/Card';
import CurrencyFlag from '../CurrencyFlag/CurrencyFlag';
import { Flag } from '../flag/Flag';
import { asAmount } from '../utils/formatting';
import { Separator } from '../separator/Separator';
import { PaymentType } from '@grain/core-types';
import './WalletCard.scss';
import { Tag } from '../Tag/Tag';

export interface WalletCardProps<ValueTitle extends string = string> {
  balance: number;
  countryCode?: string;
  currency: string;
  onClick?: () => void;
  values: readonly WalletCardValue<ValueTitle>[];
}

export type WalletCardValue<Title extends string> = Title extends 'Payment Type' | 'Supported Payments'
  ? { title: Title; value: ReactElement<WalletCardPaymentTypesProps> }
  : { title: Title; value: string | number | ReactElement | null };

const FLAG_SIZE = 32;

export function WalletCard<ValueTitle extends string>(props: WalletCardProps<ValueTitle>): ReactElement {
  const { currency, countryCode } = props;

  return (
    <Card className="wallet-card" onClick={props.onClick}>
      <div className="wallet-card-header">
        <div className="wallet-card-title">
          {countryCode ? <Flag countryCode={countryCode} size={FLAG_SIZE} /> : <CurrencyFlag currency={currency} size={FLAG_SIZE} />}
          <span>{`${currency} ${asAmount(props.balance)}`}</span>
        </div>
      </div>
      <Separator />
      {props.values.map((value) => (
        <div className="wallet-card-detail" key={value.title}>
          <span className="wallet-card-detail-title">{value.title}</span>
          <span className="wallet-card-detail-value">{value.value}</span>
        </div>
      ))}
    </Card>
  );
}

interface WalletCardPaymentTypesProps {
  paymentTypes: PaymentType[];
}

export function WalletCardPaymentTypes(props: WalletCardPaymentTypesProps): ReactElement {
  return (
    <div className="payment-types">
      {props.paymentTypes.map((paymentType) => (
        <Tag className="payment-type" key={paymentType} value={paymentTypeDisplayName(paymentType)} />
      ))}
    </div>
  );
}
