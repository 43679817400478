import React, { ReactElement, useMemo } from 'react';
import { FilterSelect, FilterSelectProps } from '../filter-select/FilterSelect';
import './CurrencyFilterSelect.scss';

export type CurrencyFilterSelectProps = Omit<FilterSelectProps, 'children' | 'label' | 'options' | 'selectValueClassName'> & {
  currencies: string[];
  label?: 'From' | 'To';
  sorted?: boolean;
};

export const ALL_CURRENCIES_OPTION = { value: 'All', text: 'All' } as const;

export function CurrencyFilterSelect(props: CurrencyFilterSelectProps): ReactElement {
  const { currencies, sorted, ...filterSelectProps } = props;

  const options = useMemo(() => {
    const processedCurrencies = [...currencies];

    if (sorted) {
      processedCurrencies.sort((a, b) => a.localeCompare(b));
    }

    return [
      ALL_CURRENCIES_OPTION,
      ...processedCurrencies.map((currency) => ({
        text: currency,
        value: currency
      }))
    ];
  }, [currencies, sorted]);

  return <FilterSelect {...filterSelectProps} options={options} selectValueClassName="currency-select-value" />;
}
