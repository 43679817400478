import React, { ReactNode } from 'react';
import './NameTag.scss';

export default function NameTag(props: NameTagProps) {
  const { userDetails } = props;

  const email = userDetails?.email || '';
  const firstName = userDetails?.firstName || '';
  const lastName = userDetails?.lastName || '';

  const initials = (firstName ? [firstName, lastName] : [email]).map((v) => v?.charAt(0)).join('');

  return (
    <div className="name-tag-container">
      <div className="initials">{initials.toUpperCase()}</div>
      <div className="name-and-email">
        {firstName && (
          <div className="user-name-and-suffix">
            <div className="user-name">{`${firstName} ${lastName}`}</div>
            {userDetails?.suffix}
          </div>
        )}
        <div className="user-email">{email}</div>
      </div>
    </div>
  );
}

type NameTagProps = {
  userDetails?: UserDetailsType;
};

export type UserDetailsType = {
  firstName?: string;
  lastName?: string;
  email: string;
  suffix?: ReactNode;
};
