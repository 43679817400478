import Page from '../layout/Page';
import React, { useCallback, useEffect, useState } from 'react';
import colors from '@grain/web-components/styles/colors.scss';
import SectionCard from '@grain/web-components/layout/SectionCard';
import './UserManagementPage.scss';
import { AdminConsoleUser } from '@grain/admin-console-api-types';
import UsersTable from './UsersTable';
import { UserEdit } from 'iconsax-react';
import UsersService from '../../services/UsersService';

export default function UserManagementPage() {
  const [users, setUsers] = useState<AdminConsoleUser[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const refreshUsers = useCallback(async () => {
    setLoading(true);
    try {
      const users = await UsersService.getInstance().getUsersRequest();
      setUsers(users);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async function () {
      await refreshUsers();
    })();
  }, [refreshUsers]);

  return (
    <Page className="user-management-page" title="Admin Console Users Management" subtitle="Manage Admin Console Users">
      <SectionCard
        className="users-table-section"
        title="Users"
        icon={<UserEdit />}
        iconBackgroundColor={colors.secondaryPurple}
        loading={isLoading}
      >
        <UsersTable users={users} onCloseAfterSuccess={refreshUsers} />
      </SectionCard>
    </Page>
  );
}
