import { useMutation } from '@tanstack/react-query';
import { ExecutePennyTestToPartnerParams, TreasuryService } from 'services/TreasuryService';

export const useExecutePennyTestToPartner = () => {
  const {
    mutateAsync: executePennyTestToPartner,
    isError: pennyTestExecutionFailed,
    isLoading: isExecutingPennyTest,
    isSuccess: pennyTestExecutionSucceeded,
    reset: resetExecutePennyTestToPartnerMutation,
    error: pennyTestExecutionError
  } = useMutation<void, Error, ExecutePennyTestToPartnerParams>((params) =>
    TreasuryService.getInstance().executePennyTestToPartner(params)
  );

  return {
    executePennyTestToPartner,
    pennyTestExecutionFailed,
    isExecutingPennyTest,
    pennyTestExecutionSucceeded,
    resetExecutePennyTestToPartnerMutation,
    pennyTestExecutionError
  };
};
