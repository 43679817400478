import React from 'react';
import './MultiStepsDialog.scss';
import BaseDialog, { BaseDialogProps } from '../dialogs/BaseDialog';
import Spinner from '../spinner/Spinner';
import ErrorMessage from '../errors/ErrorMessage';

export default function MultiStepsDialog(props: MultiStepsDialogProps) {
  const { loading, error, errorReason, children, clearDialog, onClose, ...baseDialogProps } = props;

  const onDialogClose = () => {
    onClose();
    clearDialog();
  };

  let dialogContent;
  if (loading) {
    dialogContent = <Spinner />;
  } else if (error) {
    dialogContent = <ErrorMessage message={error} reason={errorReason} />;
  } else {
    dialogContent = children;
  }
  return (
    <BaseDialog onClose={onDialogClose} {...baseDialogProps}>
      <div className="multi-steps-dialog-container">{dialogContent}</div>
    </BaseDialog>
  );
}

type MultiStepsDialogProps = BaseDialogProps & {
  loading?: boolean;
  error?: string;
  errorReason?: string;
  children: React.ReactNode;
  onOpen?: () => void;
  clearDialog: () => void;
  onClose: () => void;
  triggerAlt?: string;
  leftHeader?: string | React.ReactElement;
  containerClassName?: string;
  contentClassName?: string;
};
