import './SpotStatusBadge.scss';
import StatusBadge from '@grain/web-components/badges/StatusBadge';
import { SpotStatus } from '@grain/core-types';

export default function SpotStatusBadge({ value }: SpotStatusBadgeProps) {
  return <StatusBadge className="spot-status-badge-container" status={value} />;
}

type SpotStatusBadgeProps = {
  value: SpotStatus;
};
