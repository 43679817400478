import { FlatJson } from './json';

export interface ActionRejection {
  id: string;
  data: FlatJson;
  companyId: string;
  organizationId: string;
  actionType: ActionType;
  message: string;
  createdAt: Date;
}

export enum ActionType {
  HedgeQuoteRejection = 'hedge_quote_rejection',
  HedgeAcceptRejection = 'hedge_accept_rejection',
  SpotQuoteRejection = 'spot_quote_rejection',
  SpotAcceptRejection = 'spot_accept_rejection'
}
