import { ActionReducerMapBuilder, AsyncThunk, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AlertsService, AnalyticsService, getAuthToken, ScreenCaptureService, sendUserInfoToChatService } from '../services';
import BaseUsersService from '../services/BaseUsersService';
import AuthenticationService from '../services/AuthenticationService';

export interface UserState<UserType> {
  isAuthenticated: boolean;
  user?: UserType;
}

export function getUserStoreInitialState<UserType>(): UserState<UserType> {
  return {
    isAuthenticated: !!getAuthToken(),
    user: undefined
  };
}

export const signUp = createAsyncThunk('user/signup', async (params: SignupParams) => {
  await AuthenticationService.getInstance().signUpRequest(params);
  AnalyticsService.getInstance().logEvent('User Signup');
});

export const login = createAsyncThunk('user/login', async ({ email, password, recoveryToken }: LoginParams) => {
  await AuthenticationService.getInstance().loginRequest(email, password, recoveryToken);
  AnalyticsService.getInstance().logEvent('User Login');
});

export const logout = createAsyncThunk('user/logout', async () => {
  await AuthenticationService.getInstance().logoutRequest();
  AnalyticsService.getInstance().logEvent('User Logout');
});

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (email: string) =>
  AuthenticationService.getInstance().forgotPasswordRequest(email)
);

export const finishGoogleLogin = createAsyncThunk('user/finishGoogleLogin', async ({ query, onError }: FinishLoginParams) => {
  const querySearchParams = new URLSearchParams(query);
  const base64StateParam = querySearchParams.get('state');
  if (base64StateParam) {
    const decodedState = atob(base64StateParam);
    const { redirectTo } = JSON.parse(decodedState);

    const isAllowedRedirectUrl = [
      /^https:\/\/([a-zA-Z0-9-]+\.)?grain(-admin)?-console\.pages\.dev$/,
      /^http:\/\/localhost/,
      /^https:\/\/console\.grainfinance\.co/,
      /^https:\/\/admin-console\.grainfinance\.co/
    ].some((regex) => redirectTo?.match(regex) != null);
    const isAlreadyInRedirectPage = redirectTo.includes(window.location.host);

    const shouldRedirectToStateUrl = redirectTo && isAllowedRedirectUrl && !isAlreadyInRedirectPage;
    if (shouldRedirectToStateUrl) {
      const redirectToWithoutEndingSlash = redirectTo.replace(/\/$/, '');
      window.location.href = `${redirectToWithoutEndingSlash}/google/authorized?${query}`;
      return;
    }
  }

  try {
    await AuthenticationService.getInstance().finishGoogleLoginRequest(query);
    AnalyticsService.getInstance().logEvent('User Google Login');
  } catch (err) {
    onError?.();
    throw err;
  }
});

const baseExtraReducers = <UserType>(thunk: AsyncThunk<any, any, object>, builder: ActionReducerMapBuilder<UserState<UserType>>) => {
  builder.addCase(thunk.fulfilled, (state) => {
    if (thunk !== forgotPassword) state.isAuthenticated = thunk !== logout;
  });
  builder.addCase(thunk.rejected, (state) => {
    if (thunk !== forgotPassword) state.isAuthenticated = thunk === logout;
  });
};

export const getUser = createAsyncThunk('user/me', async <UserType>() => BaseUsersService.getInstance().getUserMeRequest<UserType>());

export const verifyUserEmail = createAsyncThunk<any, string>('user/verify-email', async <UserType>(userId: string) =>
  BaseUsersService.getInstance().verifyUserEmailRequest<UserType>(userId)
);

const userExtraReducers = <UserType>(thunk: AsyncThunk<UserType, any, object>, builder: ActionReducerMapBuilder<UserState<UserType>>) => {
  builder.addCase(thunk.fulfilled, (state: UserState<any>, action: PayloadAction<UserType>) => {
    state.user = action.payload;
    AnalyticsService.getInstance().setUser(state.user.id, {
      email: state.user.email
    });
    AlertsService.getInstance().setUser(state.user.id, {
      email: state.user.email
    });
    ScreenCaptureService.getInstance().setUser(state.user.id, {
      email: state.user.email
    });
    sendUserInfoToChatService(state.user);
  });
};

export function getExtraReducers<UserType>(builder: ActionReducerMapBuilder<UserState<UserType>>) {
  baseExtraReducers(signUp, builder);
  baseExtraReducers(login, builder);
  baseExtraReducers(logout, builder);
  baseExtraReducers(forgotPassword, builder);
  baseExtraReducers(finishGoogleLogin, builder);
  userExtraReducers(verifyUserEmail, builder);
  userExtraReducers(getUser, builder);
}

export type SignupParams = {
  email: string;
  password: string;
};

type LoginParams = {
  email: string;
  password: string;
  recoveryToken?: string;
};

type FinishLoginParams = {
  query: string;
  onError?: () => void;
};
