import React, { ChangeEvent, ReactElement, useState } from 'react';
import BaseDropdown, { BaseDropdownOption } from '../forms/BaseDropdown';
import FormDialog from './FormDialog';

export default function SelectOptionDialog(props: ChangeStatusDialogProps) {
  const { initialOption, title, options, onCloseAfterSuccess, onSubmit, trigger } = props;
  const [status, setStatus] = useState<string>(initialOption);

  const canSubmit = !!status && status !== initialOption;

  const onDialogClose = (success: boolean) => {
    if (success) {
      onCloseAfterSuccess?.();
    }
    setStatus(initialOption);
  };

  const onDialogSubmit = async () => {
    await onSubmit(status);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setStatus(value);
  };

  return (
    <FormDialog title={title} trigger={trigger} canSubmit={canSubmit} onClose={onDialogClose} onSubmit={onDialogSubmit}>
      <BaseDropdown name="option" value={status} options={options} onChange={onChange} hideErrorText small />
    </FormDialog>
  );
}

type ChangeStatusDialogProps = {
  initialOption: string;
  options: BaseDropdownOption[];
  onCloseAfterSuccess?: () => void;
  onSubmit: (status: string) => Promise<void>;
  trigger: ReactElement;
  title: string;
};
