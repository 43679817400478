import { BaseDialogHandle } from '@grain/web-components/dialogs/BaseDialog';
import React, { useRef, useState } from 'react';
import './UploadHedgesDialog.scss';
import PartnersService from '../../../services/PartnersService';
import HorizontalSelector from '@grain/web-components/horizontal-selector/HorizontalSelector';
import { camelCaseToTitleCase } from '@grain/formatters';
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle } from '@grain/web-components/dialogs/Dialog';
import { FileDropzone } from '@grain/web-components/file-dropzone/FileDropzone';

export default function UploadHedgesDialog(props: UploadHedgesDialogProps) {
  const { partnerId, onUploadStart, onUploadSucceed, open, onClose } = props;
  const dialogRef = useRef<BaseDialogHandle>(null);
  const [selectedAmountDirection, setSelectedAmountDirection] = useState(props.amountDirection || AmountDirection.ToAmount);
  const hedgesFileChosen = (file: File, fileUrl: string) => {
    onUploadSucceed(file, fileUrl, selectedAmountDirection);
    dialogRef.current?.hideDialog();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="dialog-details-content min-w-72 bg-white">
        <DialogHeader>
          <DialogTitle> Upload Hedges</DialogTitle>
        </DialogHeader>

        <DialogClose onClick={onClose} />

        <HorizontalSelector
          className="my-6 w-64 mx-auto"
          options={Object.values(AmountDirection)
            .sort()
            .reverse()
            .map((type) => ({
              value: type,
              label: camelCaseToTitleCase(type)
            }))}
          selected={selectedAmountDirection}
          onSelected={(selected) => setSelectedAmountDirection(selected as AmountDirection)}
        />

        <FileDropzone
          onFileUploaded={hedgesFileChosen}
          uploadFile={(file) => {
            onUploadStart?.();
            return PartnersService.getInstance().uploadHedges(partnerId, file);
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

interface UploadHedgesDialogProps {
  amountDirection?: AmountDirection;
  partnerId: string;
  onUploadStart?: () => void;
  onUploadSucceed: (file: File, fileUrl: string, amountDirection: AmountDirection) => void;
  open: boolean;
  onClose?: () => void;
}

export enum AmountDirection {
  ToAmount = 'toAmount',
  FromAmount = 'fromAmount'
}
