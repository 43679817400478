import { AdminConsoleApiPaymentBeneficiary, PaymentBeneficiaryType } from '@grain/admin-console-api-types';
import { KeyValuePair } from '@grain/web-components/key-value-pair/KeyValuePair';
import { camelCaseToSentenceCase } from '@grain/formatters';

export const PartnerWalletDetailsBeneficiarySection = (props: PartnerWalletDetailsBeneficiarySectionProps) => {
  const { beneficiary } = props;
  const detailsToDisplay = Object.entries(beneficiary).filter(([key]) => !['type', 'id'].includes(key));
  const detailsToDisplaySortedByKeys = detailsToDisplay
    .map(([key, value]) => [camelCaseToSentenceCase(key), value])
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

  return (
    <div className="w-full">
      <p className="text-light-grey font-bold mr-auto mb-5 text-sm">{getBeneficiaryTypeDisplayName(beneficiary.type)}</p>

      <div className="grid grid-flow-col grid-rows-3 grid-cols-2 gap-y-6 gap-x-5">
        {detailsToDisplaySortedByKeys.map(([key, value]) => (
          <KeyValuePair key={key} title={key} value={value} />
        ))}
      </div>
    </div>
  );
};

export type PartnerWalletDetailsBeneficiarySectionProps = {
  beneficiary: AdminConsoleApiPaymentBeneficiary;
};

const getBeneficiaryTypeDisplayName = (type: PaymentBeneficiaryType) => {
  switch (type) {
    case PaymentBeneficiaryType.GrainTreasury:
      return 'From Treasury';
    case PaymentBeneficiaryType.PartnerOwnedByGrain:
      return 'To Treasury';
    case PaymentBeneficiaryType.PartnerOwnedByPartner:
      return 'Withdraw';
  }
};
