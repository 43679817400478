import { Table, flexRender } from '@tanstack/react-table';
import React, { ReactElement } from 'react';
import './TableFooter.scss';

export interface TableFooterProps<TData> {
  table: Table<TData>;
}

export function TableFooter<TData>(props: TableFooterProps<TData>): ReactElement | null {
  const footerGroups = props.table.getFooterGroups();

  if (!footerGroups.some((footerGroup) => footerGroup.headers.some((header) => header.column.columnDef.footer))) {
    return null;
  }

  return (
    <tfoot className="table-foot">
      {footerGroups.map((footerGroup) => (
        <tr className="table-footer-group" key={footerGroup.id}>
          {footerGroup.headers.map((header) => (
            <th className="table-footer" key={header.id}>
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
            </th>
          ))}
        </tr>
      ))}
    </tfoot>
  );
}
