import React from 'react';
import './StatusBadge.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export default function StatusBadge(props: StatusBadgeProps) {
  const { status, text, className, link, icon } = props;

  const iconElement = icon ? <div className="icon">{icon}</div> : null;
  const statusElement = <div className="text-status">{text || status}</div>;

  return (
    <div className={classNames('status-badge-container', status.replaceAll(' ', '').toLowerCase(), className)}>
      {link ? (
        <Link className="status-badge-link" to={link}>
          {iconElement}
          {statusElement}
        </Link>
      ) : (
        <>
          {iconElement}
          {statusElement}
        </>
      )}
    </div>
  );
}

type StatusBadgeProps = {
  status: string;
  text?: string;
  className?: string;
  link?: string;
  icon?: React.ReactElement;
};
