import { createQueryKeys } from '@lukemorales/query-key-factory';
import PaymentBeneficiariesService from '../services/PaymentBeneficiaries';
import { PaymentBeneficiaryType } from '@grain/core-types';

const ALL_TYPES = [
  PaymentBeneficiaryType.GrainTreasury,
  PaymentBeneficiaryType.PartnerOwnedByGrain,
  PaymentBeneficiaryType.PartnerOwnedByPartner
];

export const paymentBeneficiariesQueries = createQueryKeys('paymentBeneficiaries', {
  list: (partnerId: string, types: PaymentBeneficiaryType[] = ALL_TYPES) => {
    if (!types.length) {
      types = ALL_TYPES;
    }

    types = [...new Set(types)].sort();

    return {
      queryKey: ['partnerId', partnerId, 'types', ...types],
      queryFn: () =>
        PaymentBeneficiariesService.getInstance().getPaymentBeneficiaries({
          partnerId,
          type: types
        })
    };
  }
});
