import { Warning2 } from 'iconsax-react';
import React from 'react';
import './ErrorMessage.scss';
import colors from '../styles/colors.scss';

export default function ErrorMessage({ message, reason }: ErrorMessageProps) {
  return (
    <div className="error-card-container">
      <div className="warning-icon">
        <Warning2 color={colors.warningRed} size="51" variant="Bold" />
      </div>
      <div className="error-title">{message}</div>
      <div className="error-reason">{reason}</div>
    </div>
  );
}

type ErrorMessageProps = {
  message: string;
  reason?: string;
};
