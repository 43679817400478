import React, { useState } from 'react';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import FormDialog from '@grain/web-components/dialogs/FormDialog';
import { subDays } from 'date-fns';
import BaseDatePicker from '@grain/web-components/forms/BaseDatePicker';
import ReportsService from '../../services/ReportsService';
import { notifySuccess } from '@grain/web-components/notifications/toasters';

export default function HedgesReportsByStartDateDialog(props: HedgesReportsByStartDateDialogProps) {
  const { onSubmit } = props;
  const now = new Date();
  const initialCreatedAfter = { createdAfter: subDays(now, 7) };
  const [newData, setNewData] = useState<HedgesReportsByStartDateDialogData>(initialCreatedAfter);
  const onDateChange = (name: string, value: string | Date) => {
    setNewData({ ...newData, [name]: value });
  };
  const onSubmitInternal = async () => {
    await ReportsService.getInstance().generateHedgesCreatedAfterReportsRequest({ createdAfter: newData.createdAfter });
    notifySuccess('Hedges report by creation date is being generated. You will receive a slack message when the report is ready.');
    await onSubmit();
  };

  const onClose = () => {
    setNewData(initialCreatedAfter);
  };

  return (
    <FormDialog
      title="Hedge report by creation time"
      trigger={<CTAButton text="Generate hedges reports by creation date" />}
      canSubmit
      onClose={onClose}
      onSubmit={onSubmitInternal}
    >
      <BaseDatePicker
        name="createdAfter"
        labelText="Created After"
        value={newData.createdAfter}
        maxDate={now}
        onValueChange={onDateChange}
        small
        hideErrorText
        borderless
        shadow
        required
        allowPastDates
      />
    </FormDialog>
  );
}

type HedgesReportsByStartDateDialogProps = {
  onSubmit: () => Promise<void>;
};

type HedgesReportsByStartDateDialogData = {
  createdAfter: Date;
};
