import React from 'react';
import BaseDialog, { BaseDialogProps } from '@grain/web-components/dialogs/BaseDialog';
import { Separator } from '@grain/web-components/separator/Separator';
import './DeleteCurrencyPairDialog.scss';
import { GrainCurrencyPairWithProviders } from './types';

interface IProps extends Omit<BaseDialogProps, 'children'> {
  pair: GrainCurrencyPairWithProviders | null;
}

export default function DeleteCurrencyPairDialog(props: IProps) {
  const { pair, ...restOfProps } = props;
  const { fromCurrency, toCurrency } = pair || {};

  if (!pair) return null;

  return (
    <BaseDialog {...restOfProps} contentClassName="sm:w-2/3 md:w-[500px]">
      <div className="w-full overflow-y-auto flex flex-col">
        <div className="title-section-wrapper">
          <p className="text-light-grey text-sm font-medium mr-auto">Delete Currency Pair</p>
        </div>
        <div className="delete-section-wrapper">
          <Separator className="my-6" />
          <h1 className="delete-currency-pair-title">Delete Currency Pair</h1>
          <p className="delete-currency-pair-content">
            This will delete{' '}
            {
              <span>
                <strong>{fromCurrency}</strong>
              </span>
            }{' '}
            {'->'}{' '}
            {
              <span>
                <strong>{toCurrency}</strong>
              </span>
            }{' '}
            grain currency pair
            <br />
            Are you sure you want to continue with this action?
          </p>
        </div>
      </div>
    </BaseDialog>
  );
}
