import React from 'react';
import Spinner from '@grain/web-components/spinner/Spinner';
import { useConsumableSearchString } from '@grain/web-components/utils/hooks/use-consumable-search-string';
import { finishGoogleLogin } from '../../store/userStore';
import { useAppDispatch } from '../../store/store';

export default function GoogleOAuthorizedPage() {
  const dispatch = useAppDispatch();
  useConsumableSearchString((queryStringFromOAuthProvider) => dispatch(finishGoogleLogin({ query: queryStringFromOAuthProvider })));

  return <Spinner />;
}
