import { ALL_CURRENCIES_OPTION } from '@grain/web-components/currency-filter-select/CurrencyFilterSelect';

export function getCurrencyPairQueryParams(params: { fromCurrency?: string; toCurrency?: string }): URLSearchParams {
  const { fromCurrency, toCurrency } = params;
  const searchParams = new URLSearchParams();

  if (fromCurrency && fromCurrency !== ALL_CURRENCIES_OPTION.value) {
    searchParams.set('fromCurrency', fromCurrency);
  }

  if (toCurrency && toCurrency !== ALL_CURRENCIES_OPTION.value) {
    searchParams.set('toCurrency', toCurrency);
  }

  return searchParams;
}
