import React, { type ReactElement, useEffect, useState } from 'react';
import type { GrainCurrencyPair } from '@grain/core-types';
import { createColumnHelper, Table, useControlledSorting, usePagination, useTable } from '@grain/web-components/table-v2';
import { useGrainCurrencyPairs } from 'hooks';
import { HealthStatusChip } from '@grain/web-components/health-status-chip/HealthStatusChip';
import { useProviderStatuses } from 'hooks/useProviderStatuses';
import type { CurrencyPairProvidersStatus, GrainCurrencyPairProviderDetails } from '@grain/admin-console-api-types';
import { CurrencyPairDetailsDialog } from './CurrencyPairDetailsDialog';
import type { GrainCurrencyPairWithProviders, Provider } from './types';
import { prettifyProviderName } from './prettify-provider-name';
import { ALL_CURRENCIES_OPTION } from '@grain/web-components/currency-filter-select/CurrencyFilterSelect';
import { Trash } from 'iconsax-react';
import { IconButton } from '@grain/web-components/buttons/IconButton';
import './CurrenciesTable.scss';
import DeleteCurrencyPairDialog from './DeleteCurrencyPairDialog';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { useDeleteGrainCurrencyPair } from './hooks';

export type CurrenciesTableProps = Partial<GrainCurrencyPair> & {
  onSubmitCallback: () => void;
};

const columnHelper = createColumnHelper<
  GrainCurrencyPairWithProviders & {
    onDelete: (pair: GrainCurrencyPairWithProviders) => void;
  }
>();

const columns = [
  columnHelper.accessor('fromCurrency', { header: 'From currency' }),
  columnHelper.accessor('toCurrency', { header: 'To currency' }),
  columnHelper.accessor('symbol', { header: 'Ticker symbol' }),
  createProviderColumn('hedgeSpotRate', 'Quote Spot rate'),
  createProviderColumn('hedgeForwardPoint', 'Quote Forward points'),
  createProviderColumn('hedgeForwardOrder', 'Forward order'),
  columnHelper.display({
    cell: ({ row: { original } }) => {
      const { onDelete, ...rest } = original;
      return (
        <IconButton
          className="currencies-table-delete-icon"
          icon={Trash}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(rest);
          }}
        />
      );
    },
    header: '',
    id: 'Actions'
  })
];

let providerStatuses: Record<string, CurrencyPairProvidersStatus> | undefined = undefined;

export function CurrenciesTable(props: CurrenciesTableProps): ReactElement | null {
  const { fromCurrency, toCurrency, onSubmitCallback } = props;

  const { grainCurrencyPairs } = useGrainCurrencyPairs();
  const { deleteGrainCurrencyPairAsync, isLoadingDeleteGrainCurrencyPair: deleteDialogLoading } = useDeleteGrainCurrencyPair();

  const filteredGrainCurrencyPairs =
    grainCurrencyPairs?.filter(
      (currencyPair) =>
        (fromCurrency === ALL_CURRENCIES_OPTION.value || currencyPair.fromCurrency === fromCurrency) &&
        (toCurrency === ALL_CURRENCIES_OPTION.value || currencyPair.toCurrency === toCurrency)
    ) || [];

  const [dialogState, setDialogState] = useState<DialogState>({
    activeCurrencyPair: null,
    isRowDialogOpen: false
  });
  const [deleteDialogState, setDeleteDialogState] = useState<DialogState>({
    activeCurrencyPair: null,
    isRowDialogOpen: false
  });

  const statefulGrainCurrencyPairs =
    filteredGrainCurrencyPairs?.map((currencyPair) => {
      const providerStatus = providerStatuses?.[`${currencyPair.fromCurrency}${currencyPair.toCurrency}`];

      return {
        ...currencyPair,
        onDelete: (pair: GrainCurrencyPairWithProviders) =>
          updateDeleteDialogState({
            activeCurrencyPair: pair,
            isRowDialogOpen: true
          }),
        providers: {
          hedgeSpotRate: generateProviderDetails(currencyPair.hedgeSpotRateProviders, providerStatus?.hedgeSpotRate),
          hedgeForwardPoint: generateProviderDetails(currencyPair.hedgeForwardPointProviders, providerStatus?.hedgeForwardPoint),
          hedgeForwardOrder: generateProviderDetails(currencyPair.hedgeForwardOrderProviders)
        }
      };
    }) || [];

  const { paginationProps } = usePagination({
    // the data being changed when enriched with provider statuses causes unwanted pagination resets.
    // for now, we're disabling auto-resetting the page index, and doing it manually until we find a solution.
    autoResetPageIndex: false
  });
  const { sortingProps, sortingState } = useControlledSorting();

  const { table } = useTable(
    {
      columns,
      data: statefulGrainCurrencyPairs
    },
    paginationProps,
    sortingProps
  );

  useEffect(() => {
    table.resetPageIndex(true);
  }, [sortingState, fromCurrency, toCurrency]);

  const currentPage = table.getRowModel().rows.map((row) => row.original);

  ({ providerStatuses } = useProviderStatuses(currentPage));

  const updateDialogState = (newState: Partial<DialogState>) => {
    setDialogState((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  const updateDeleteDialogState = (newState: Partial<DialogState>) => {
    setDeleteDialogState((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  const onDeleteCurrencyPair = async () => {
    try {
      await deleteGrainCurrencyPairAsync({
        id: deleteDialogState.activeCurrencyPair?.id as string
      });
      onSubmitCallback();
      updateDeleteDialogState({ isRowDialogOpen: false });
    } catch (e) {
      // No-op
    }
  };

  return (
    <>
      <Table
        onRowClick={(row) =>
          updateDialogState({
            activeCurrencyPair: row.original,
            isRowDialogOpen: true
          })
        }
        table={table}
      />
      <CurrencyPairDetailsDialog
        currencyPair={dialogState.activeCurrencyPair}
        onCancel={() => updateDialogState({ isRowDialogOpen: false })}
        open={dialogState.isRowDialogOpen}
      />
      <DeleteCurrencyPairDialog
        pair={deleteDialogState.activeCurrencyPair}
        open={deleteDialogState.isRowDialogOpen}
        onCancel={() => updateDeleteDialogState({ isRowDialogOpen: false })}
        onAccept={onDeleteCurrencyPair}
        cancelButton={<CTAButton disabled={deleteDialogLoading} text="No" />}
        acceptButton={<CTAButton appearance="light-blue" disabled={deleteDialogLoading} text="Yes" />}
      />
    </>
  );
}

function generateProviderDetails<P extends Provider>(
  providers: P[],
  detailsToAdd?: GrainCurrencyPairProviderDetails<P>[]
): GrainCurrencyPairProviderDetails<P>[] {
  return providers.map((provider) => {
    const providerDetails = detailsToAdd?.find((details) => details.provider === provider);

    return (
      providerDetails || {
        provider,
        status: null
      }
    );
  });
}

function createProviderColumn(providerKey: keyof GrainCurrencyPairWithProviders['providers'], header: string) {
  return columnHelper.display({
    cell: ({ row: { original } }) => {
      const providers = original.providers[providerKey] as GrainCurrencyPairProviderDetails<any>[];

      const provider = providers.find((provider) => provider.status === 'healthy') || providers.at(0);

      return provider ? <HealthStatusChip status={provider.status} text={prettifyProviderName(provider.provider)} /> : null;
    },
    header,
    id: providerKey
  });
}

interface DialogState {
  activeCurrencyPair: GrainCurrencyPairWithProviders | null;
  isRowDialogOpen: boolean;
}
