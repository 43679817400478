import preval from 'preval.macro';
import format from 'date-fns/format';

const buildTimeMilliseconds: string = preval`module.exports = new Date().getTime()`;
export const buildNum: string = preval`module.exports = process.env.BUILD_NUM`;

export const buildTime = new Date(buildTimeMilliseconds);
export const build = format(buildTime, 'yyMMddHHmmss');

export const version = `${buildNum}.${build}`;
