import { AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import { httpCall, HttpError, StatusCodes, TokenExpiredError } from '@grain/api-utils';
import { captureException } from '@sentry/react';

export const AUTH_TOKEN_INDICATOR = 'authToken';

export function clearSession(): void {
  localStorage.removeItem(AUTH_TOKEN_INDICATOR);
}

export function getAuthToken(): string | null {
  return localStorage.getItem(AUTH_TOKEN_INDICATOR);
}

function getAuthenticationHeaders(additionalHeaders: RawAxiosRequestHeaders | undefined): RawAxiosRequestHeaders {
  return { Authorization: `Bearer ${getAuthToken()}`, ...additionalHeaders };
}

export class BaseService {
  public constructor(
    readonly baseUrl: string,
    readonly errorHandler: (err: Error) => void
  ) {}

  protected async call<T = unknown, D = unknown>(params: AxiosRequestConfig<D>, fullResponse?: boolean): Promise<T> {
    const url = `${this.baseUrl}/${params.url}`;
    const res: AxiosResponse = await httpCall({ ...params, url }, captureException);
    return fullResponse ? res : res.data;
  }

  protected async authenticatedCall<T = unknown, D = unknown>(params: AxiosRequestConfig, fullResponse?: boolean): Promise<T> {
    const { headers, ...payload } = params;
    try {
      return await this.call<T, D>({ headers: getAuthenticationHeaders(headers), ...payload }, fullResponse);
    } catch (err) {
      let error = err as HttpError;
      if (error.status === StatusCodes.UNAUTHORIZED) {
        clearSession();
        window.location.href = window.location.origin;
        error = new TokenExpiredError(error.extraParams);
      } else {
        error.message = error.message || 'An error has occurred';
      }
      this.errorHandler(error);
      throw error;
    }
  }

  async downloadableFileCall(params: AxiosRequestConfig): Promise<void> {
    const response = await this.authenticatedCall<AxiosResponse>({ ...params, responseType: 'blob' }, true);
    const filename = response.headers['content-disposition'].split('filename=')[1].slice(1, -1);
    this.autoDownloadFile(response.data as Blob, filename);
  }

  autoDownloadFile(blob: Blob, filename: string) {
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
}
