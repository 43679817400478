import { AdminConsoleApiWallet } from '@grain/admin-console-api-types';
import BaseDialog, { BaseDialogProps } from '@grain/web-components/dialogs/BaseDialog';
import { useState } from 'react';
import BaseTabs from '@grain/web-components/tabs/BaseTabs';
import { PartnerWalletDetailsGeneralSection } from './PartnerWalletDetailsGeneralSection';
import { PartnerWalletDetailsBeneficiariesSection } from './PartnerWalletDetailsBeneficiariesSection';

export const PartnerWalletDetailsDialog = (props: WalletDetailsProps) => {
  const { wallet, ...dialogProps } = props;
  const [activeTab, setActiveTab] = useState(WalletDetailsTab.Wallet);

  const resetModal = () => {
    setActiveTab(WalletDetailsTab.Wallet);
  };

  if (!wallet) return null;

  return (
    <BaseDialog {...dialogProps} contentClassName="sm:w-2/3 md:w-[700px] h-4/5 max-h-4/5" onClose={resetModal}>
      <BaseTabs
        className="-mt-7"
        tabs={Object.entries(WalletDetailsTab).map(([key, value]) => ({
          title: key,
          value
        }))}
        selectedValue={activeTab}
        onValueSelected={(tab) => setActiveTab(tab as WalletDetailsTab)}
      />

      <div className="w-full h-full overflow-y-auto">
        {activeTab === WalletDetailsTab.Wallet && <PartnerWalletDetailsGeneralSection wallet={wallet} />}
        {activeTab === WalletDetailsTab.Beneficiaries && <PartnerWalletDetailsBeneficiariesSection wallet={wallet} />}
      </div>
    </BaseDialog>
  );
};

enum WalletDetailsTab {
  Wallet = 'wallet',
  Beneficiaries = 'beneficiaries'
}

interface WalletDetailsProps extends Pick<BaseDialogProps, 'onCancel' | 'open'> {
  wallet?: AdminConsoleApiWallet | null;
}
