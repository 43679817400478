import { ExtraErrorParams } from '@grain/core-types';
import { BaseError } from './base-error';

export class HttpError extends BaseError {
  constructor(
    public status: number | undefined,
    message: string,
    public extraParams: ExtraErrorParams = {},
    loggedErrorMessage?: string
  ) {
    super(message, loggedErrorMessage);
    this.status = status;
    this.extraParams = extraParams;
  }
}
