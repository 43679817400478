import {
  AdminConsoleApiForwardPercentageByCountry,
  AdminConsoleApiPartner,
  AdminConsoleApiPartnerConfig,
  AdminConsoleApiPartnerCurrencyPair,
  AdminConsoleApiPartnerUser,
  AdminConsoleApiWallet,
  DashboardRole,
  HedgeSettlementType,
  ManualHedgingMappingResponse,
  MappingHedge,
  MappingTransactionType,
  Partner
} from '@grain/admin-console-api-types';
import { ChangePartnerTransferMatePasswordPayload, EnableTransferMateRequestPayload } from '@grain/payments-service-types';
import { BaseService } from '@grain/web-utils';
import { FileMappingRequest } from '@grain/api-utils';

export default class PartnersService extends BaseService {
  private static instance: PartnersService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new PartnersService(baseUrl, handleError);
  }

  public static getInstance(): PartnersService {
    return this.instance;
  }

  async createPartner(
    platform: string,
    internal: boolean,
    partnerName: string,
    partnerEmail: string,
    firstName: string,
    lastName: string
  ): Promise<Partner> {
    const res: { partner: Partner } = await this.authenticatedCall({
      url: 'partners',
      method: 'POST',
      data: {
        platform,
        internal,
        partnerName,
        partnerEmail,
        firstName,
        lastName
      }
    });

    return res.partner;
  }

  async getPartners(payload: GetPartnersRequestParams = {}): Promise<AdminConsoleApiPartner[]> {
    const res: { partners: AdminConsoleApiPartner[] } = await this.authenticatedCall({
      url: 'partners',
      params: payload,
      method: 'GET'
    });

    return res.partners;
  }

  async getPartner(partnerId: string): Promise<AdminConsoleApiPartner> {
    const res: { partner: AdminConsoleApiPartner } = await this.authenticatedCall({
      url: `partners/${partnerId}`,
      method: 'GET'
    });

    return res.partner;
  }

  async enableTransferMateForPartner(payload: EnableTransferMateParams): Promise<void> {
    const { partnerId, data } = payload;

    await this.authenticatedCall({
      url: `partners/${partnerId}/enable-transfer-mate`,
      data,
      method: 'POST'
    });
  }

  async enableLiveApiKeysForPartner(partnerId: string): Promise<void> {
    await this.authenticatedCall({
      url: `partners/${partnerId}/enable-live-api-keys`,
      method: 'POST'
    });
  }

  async changePartnerTransferMatePassword(payload: ChangeTransferMatePasswordParams): Promise<void> {
    const { partnerId, data } = payload;

    await this.authenticatedCall({
      url: `partners/${partnerId}/change-live-password`,
      data,
      method: 'POST'
    });
  }

  async getPartnerUsers(partnerId: string): Promise<AdminConsoleApiPartnerUser[]> {
    const res: { users: AdminConsoleApiPartnerUser[] } = await this.authenticatedCall({
      url: `partners/${partnerId}/users`,
      method: 'GET'
    });

    return res.users;
  }

  async createPartnerUser(params: CreatePartnerUserParams) {
    const { partnerId, email, firstName, lastName, role } = params;

    await this.authenticatedCall({
      url: `partners/${partnerId}/users`,
      data: { email, firstName, lastName, role },
      method: 'POST'
    });
  }

  async deletePartnerUser(params: DeletePartnerUserParams) {
    const { partnerId, userId } = params;

    await this.authenticatedCall({
      url: `partners/${partnerId}/users/${userId}`,
      method: 'DELETE'
    });
  }

  async updatePartnerUser(params: UpdatePartnerUserParams) {
    const { partnerId, id, role, firstName, lastName } = params;
    await this.authenticatedCall({
      url: `partners/${partnerId}/users/${id}`,
      data: { role, firstName, lastName },
      method: 'PUT'
    });
  }

  async updatePartnerConfig(params: UpdatePartnerConfigParams) {
    await this.authenticatedCall({
      url: `partners/${params.partnerId}/config`,
      data: { config: params.config },
      method: 'PUT'
    });
  }

  async getForwardPercentageCountry(partnerId: string): Promise<AdminConsoleApiForwardPercentageByCountry[]> {
    const res: {
      forwardsPercentageByCountry: AdminConsoleApiForwardPercentageByCountry[];
    } = await this.authenticatedCall({
      url: `partners/${partnerId}/forwardsPercentageByCountry`,
      method: 'GET'
    });

    return res.forwardsPercentageByCountry;
  }

  async createForwardPercentageCountry(partnerId: string, forwardPercentage: Partial<AdminConsoleApiForwardPercentageByCountry>) {
    await this.authenticatedCall({
      url: `partners/${partnerId}/forwardsPercentageByCountry`,
      data: forwardPercentage,
      method: 'POST'
    });
  }

  async updateForwardPercentageCountry(partnerId: string, forwardPercentageId: string, percentage: number) {
    await this.authenticatedCall({
      url: `partners/${partnerId}/forwardsPercentageByCountry/${forwardPercentageId}`,
      data: { percentage },
      method: 'PUT'
    });
  }

  async deleteForwardPercentageCountry(partnerId: string, forwardPercentageId: string) {
    await this.authenticatedCall({
      url: `partners/${partnerId}/forwardsPercentageByCountry/${forwardPercentageId}`,
      method: 'DELETE'
    });
  }

  async uploadHedges(partnerId: string, file: File): Promise<string> {
    const data = new FormData();
    data.append('manual-hedging-file', file);
    const { url } = await this.authenticatedCall<{ url: string }>({
      url: `partners/${partnerId}/upload-hedges`,
      data,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return url;
  }

  async uploadTransactions(partnerId: string, file: File): Promise<string> {
    const data = new FormData();
    data.append('transactions-file', file);
    const { url } = await this.authenticatedCall<{ url: string }>({
      url: `partners/${partnerId}/upload-transactions`,
      data,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return url;
  }

  async mapTransactionsFile(partnerId: string, data: FileMappingRequest<MappingTransactionType>): Promise<void> {
    await this.authenticatedCall({
      url: `partners/${partnerId}/map-transactions-file`,
      data,
      method: 'POST'
    });
  }

  async mapHedgesFile(partnerId: string, data: FileMappingRequest<MappingHedge>) {
    const res: { mappingResult: ManualHedgingMappingResponse[] } = await this.authenticatedCall({
      url: `partners/${partnerId}/map-hedges-file`,
      data,
      method: 'POST'
    });

    return res.mappingResult;
  }

  async uploadOfflineMarkupsFile(partnerId: string, file: File): Promise<string> {
    const data = new FormData();
    data.append('markup-file', file);

    const { status } = await this.authenticatedCall<{ status: string }>({
      url: `partners/${partnerId}/upload-offline-markups`,
      data,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    return status;
  }

  async withdrawFundsToGrain(params: { partnerId: string; amount: number; currency: string }): Promise<void> {
    const { partnerId, amount, currency } = params;

    await this.authenticatedCall({
      url: `partners/${partnerId}/withdraw-to-grain`,
      data: { amount, currency },
      method: 'POST'
    });
  }

  async getWallets(partnerId: string): Promise<AdminConsoleApiWallet[]> {
    const res: { wallets: AdminConsoleApiWallet[] } = await this.authenticatedCall({
      url: `partners/${partnerId}/wallets`,
      method: 'GET'
    });

    return res.wallets;
  }

  async createWallet(params: CreateWalletParams): Promise<void> {
    const { partnerId, currency } = params;

    await this.authenticatedCall({
      url: `partners/${partnerId}/wallets`,
      data: { currency },
      method: 'POST'
    });
  }

  async getCurrencyPairs(partnerId: string) {
    const res: { currencyPairs: AdminConsoleApiPartnerCurrencyPair[] } = await this.authenticatedCall({
      url: `partners/${partnerId}/currency-pairs`,
      method: 'GET'
    });

    return res.currencyPairs;
  }

  async addCurrencyPair(params: AddCurrencyPairParams) {
    const { partnerId, currencyPair } = params;

    await this.authenticatedCall({
      url: `partners/${partnerId}/currency-pairs`,
      data: currencyPair,
      method: 'POST'
    });
  }

  async deleteCurrencyPair(params: DeleteCurrencyPairParams) {
    const { partnerId, currencyPairId } = params;

    await this.authenticatedCall({
      url: `partners/${partnerId}/currency-pairs/${currencyPairId}`,
      method: 'DELETE'
    });
  }

  async editCurrencyPair(params: EditCurrencyPairParams) {
    const { partnerId, currencyPairId, settlementType } = params;

    await this.authenticatedCall({
      url: `partners/${partnerId}/currency-pairs/${currencyPairId}`,
      method: 'PATCH',
      data: { settlementType }
    });
  }
}

export interface GetPartnersRequestParams {
  withOrganizations?: boolean;
  name?: string;
  id?: string;
}

export interface WithdrawFundsToGrainParams {
  partnerId: string;
  amount: number;
  currency: string;
}

export interface CreateWalletParams {
  partnerId: string;
  currency: string;
}

export interface AddCurrencyPairParams {
  partnerId: string;
  currencyPair: Omit<AdminConsoleApiPartnerCurrencyPair, 'id'>;
}

export interface DeleteCurrencyPairParams {
  partnerId: string;
  currencyPairId: string;
}

export interface EditCurrencyPairParams {
  partnerId: string;
  currencyPairId: string;
  settlementType: HedgeSettlementType;
}

export interface UpdatePartnerConfigParams {
  partnerId: string;
  config: Partial<AdminConsoleApiPartnerConfig>;
}

export interface EnableTransferMateParams {
  partnerId: string;
  data: EnableTransferMateRequestPayload;
}

export interface ChangeTransferMatePasswordParams {
  partnerId: string;
  data: ChangePartnerTransferMatePasswordPayload;
}

export interface UpdatePartnerUserParams {
  partnerId: string;
  id: string;
  role: DashboardRole;
  firstName: string;
  lastName: string;
}

export interface CreatePartnerUserParams {
  partnerId: string;
  email: string;
  firstName: string;
  lastName: string;
  role: DashboardRole;
}

export interface DeletePartnerUserParams {
  partnerId: string;
  userId: string;
}
