import './ForwardsPage.scss';
import Page from '../../layout/Page';
import React, { useCallback, useEffect, useState } from 'react';
import { EnrichedForward, ForwardStatus, LiquidityProvider } from '@grain/liquidity-service-types';
import { Forward } from 'iconsax-react';
import colors from '@grain/web-components/styles/colors.scss';
import SectionCard from '@grain/web-components/layout/SectionCard';
import BaseTable, { BaseTableColumnDef } from '@grain/web-components/table/BaseTable';
import { asDateString } from '@grain/web-components/utils/formatting';
import { numericSortByColumnId, sortByDates } from '@grain/web-components/table/sorting';
import ForwardsService from '../../../services/ForwardsService';
import CreateForwardPeriodicSpreadDialog from './CreateForwardPeriodicSpreadDialog';
import ReportForwardDialog from './ReportForwardDialog';
import ReportManualForwardsDialog from '../../forwards/ReportManualForwardsDialog';

export default function ForwardsPage() {
  const [forwards, setForwards] = useState<EnrichedForward[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchForwards = useCallback(async function () {
    setLoading(true);
    try {
      setForwards(
        await ForwardsService.getInstance().getAllForwards({
          liquidityProvider: LiquidityProvider.HAPI
        })
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchForwards();
  }, [fetchForwards]);

  const columns: BaseTableColumnDef<EnrichedForward>[] = [
    {
      accessorFn: (forward) => forward.symbol,
      id: 'symbol',
      header: 'Symbol'
    },
    {
      accessorFn: (forward) => `${forward.fromCurrency} ${forward.fromCurrencyAmount}`,
      id: 'fromCurrencyAmount',
      header: 'From Currency Amount',
      sortingFn: numericSortByColumnId
    },
    {
      accessorFn: (forward) => `${forward.toCurrency} ${forward.toCurrencyAmount}`,
      id: 'toCurrencyAmount',
      header: 'To Currency Amount',
      sortingFn: numericSortByColumnId
    },
    {
      accessorFn: (forward) => forward.dealtCurrency,
      id: 'dealtCurrency',
      header: 'Dealt Currency'
    },
    {
      accessorFn: (forward) => forward.price,
      id: 'price',
      header: 'Price',
      sortingFn: numericSortByColumnId
    },
    {
      accessorFn: (forward) => asDateString(forward.startAt),
      id: 'startAt',
      header: 'Start Date',
      sortingFn: sortByDates
    },
    {
      accessorFn: (forward) => asDateString(forward.endAt),
      id: 'endAt',
      header: 'End Date',
      sortingFn: sortByDates
    },
    {
      accessorFn: (forward) => forward.liquidityProvider,
      id: 'liquidityProvider',
      header: 'Liquidty Provider'
    },
    {
      accessorFn: (forward) => forward.status,
      id: 'status',
      header: 'Status'
    }
  ];

  return (
    <Page className="forwards-page-container" title="Forwards" subtitle="View Grain forwards">
      <div className="forwards-action-bar">
        <CreateForwardPeriodicSpreadDialog />
        <ReportForwardDialog
          onSubmitCallback={fetchForwards}
          forwards={forwards.filter((forward) => forward.status === ForwardStatus.Error)}
        />
        <ReportManualForwardsDialog />
      </div>
      <SectionCard title="Forwards" icon={<Forward />} iconBackgroundColor={colors.secondaryYellow} loading={isLoading}>
        <BaseTable columns={columns} data={forwards} paginated />
      </SectionCard>
    </Page>
  );
}
