import React, { useCallback, useEffect, useState } from 'react';
import './BaseDataMapper.scss';
import BaseTable, { BaseTableColumnDef } from '../table/BaseTable';
import { asDateString } from '../utils/formatting';
import useExcelParser, { type ParseExcelResponse } from './hooks/use-excel-parser';
import HorizontalSelector from '../horizontal-selector/HorizontalSelector';
import { Column, ColumnCellAccessor, DataSet, getRowsCols, Row } from '@grain/excel-parser';
import Spinner from '../spinner/Spinner';
import BaseFieldsMapper, { FieldsGroup } from '../mapping/BaseFieldsMapper';

export const MAPPING_TIMEOUT = 180_000; // 180 seconds

function formattedAccessor(id: string): ColumnCellAccessor {
  return (row: Row) => {
    const value = row[id];
    if (value instanceof Date) {
      return asDateString(value);
    }
    return value;
  };
}

export default function BaseDataMapper<K>({
  formId,
  file,
  onParseFailed,
  onParseTimeout,
  fieldGroups,
  fileUrl,
  mapFile,
  onMapperLoading,
  loading,
  allowAdvancedSettings
}: BaseDataMapperProps<K>) {
  const [rows, setRows] = useState<Row[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [workBook, setWorkBook] = useState<DataSet>({} as DataSet);
  const [sheets, setSheets] = useState<string[]>([]);
  const [highlightedColumn, setHighlightedColumn] = useState<Column>();
  const [currentSheet, setCurrentSheet] = useState<string>('');

  const [parseWorker, killWorker] = useExcelParser();

  const reportParseError = useCallback(
    (e: Error) => {
      onParseFailed(e);
    },
    [onParseFailed]
  );

  useEffect(() => {
    if (!currentSheet || !workBook) return;

    try {
      const { rows: newRows, columns: newColumns } = getRowsCols(workBook, currentSheet, formattedAccessor);
      setRows(newRows.filter((r) => !!r.length));
      setColumns(newColumns);
    } catch (e) {
      reportParseError(e);
    }
  }, [currentSheet, workBook, reportParseError]);

  const readFile = useCallback(
    async (f: File) => {
      const end = setTimeout(async () => {
        await killWorker();
        onParseTimeout();
      }, MAPPING_TIMEOUT);

      const file = await f.arrayBuffer();
      const data: ParseExcelResponse = await parseWorker(file);

      if (data instanceof Error) {
        reportParseError(data);
        clearTimeout(end);
        return;
      }

      setWorkBook(data.Sheets);
      setSheets(data.SheetNames);
      setCurrentSheet(data.SheetNames[0]);
      onMapperLoading(false);
      clearTimeout(end);
    },
    [killWorker, onMapperLoading, onParseTimeout, parseWorker, reportParseError]
  );

  useEffect(() => {
    readFile(file);
  }, [readFile, file]);
  const sheetSelector =
    sheets?.length > 1 ? <HorizontalSelector selected={currentSheet} options={sheets} onSelected={setCurrentSheet} /> : undefined;

  columns.forEach((c) => {
    (c as BaseTableColumnDef<K>).className = c.id === highlightedColumn?.id ? 'highlighted' : '';
  });

  return (
    <div className="base-data-mapper-container">
      <BaseFieldsMapper<K>
        formId={formId}
        fieldGroups={fieldGroups}
        mapFile={mapFile}
        columns={columns}
        fileUrl={fileUrl}
        currentSheet={currentSheet}
        onColumnHover={(column) => setHighlightedColumn(column)}
        allowAdvancedSettings={allowAdvancedSettings}
      />
      <div className="map-data-table-container">
        {loading || !columns.length ? (
          <Spinner />
        ) : (
          <BaseTable columns={columns} data={rows} paginated paginatorSiblingElement={sheetSelector} />
        )}
      </div>
    </div>
  );
}

type BaseDataMapperProps<K> = {
  formId: string;
  file: File;
  onParseFailed: (e: Error) => void;
  onParseTimeout: () => void;
  fieldGroups: FieldsGroup<K>[];
  fileUrl: string;
  mapFile: (data: any) => Promise<void>;
  onMapperLoading: (loading: boolean) => void;
  loading?: boolean;
  allowAdvancedSettings?: boolean;
};
