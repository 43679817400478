import BaseDialog from '@grain/web-components/dialogs/BaseDialog';
import React from 'react';
import './UploadCustomersDialog.scss';
import UploadSection from '@grain/web-components/sections/UploadSection';
import { OrganizationType } from '@grain/admin-console-api-types';
import CustomersService from '../../../services/CustomersService';

export default function UploadCustomersDialog({
  triggerComponent,
  onCustomerFileUploaded,
  partnerId,
  organizationType
}: UploadCustomersDialogProps) {
  return (
    <BaseDialog trigger={triggerComponent} contentClassName="upload-dialog-container">
      <div className="upload-dialog-content-container">
        <UploadSection
          onFileUploaded={onCustomerFileUploaded}
          uploadFile={(file) => CustomersService.getInstance().uploadCustomers(partnerId, organizationType, file)}
        />
      </div>
    </BaseDialog>
  );
}

type UploadCustomersDialogProps = {
  triggerComponent: React.ReactNode;
  onCustomerFileUploaded: () => void;
  partnerId: string;
  organizationType: OrganizationType;
};
