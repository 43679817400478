import environment from '../environments/environment';

type Chatra = { q: any[] } & ((commandName: string, data: any) => void);

declare global {
  interface Window {
    Chatra: Chatra;
    ChatraID: string;
    ChatraSetup: any;
  }
}

export function initChat() {
  if (!environment.enableChat) {
    return;
  }

  window.ChatraSetup = {
    colors: {
      buttonText: '#FFFFFF',
      buttonBg: '#403B3B'
    }
  };

  window.ChatraID = environment.chatraId;
  const script = document.createElement('script');
  window.Chatra =
    window.Chatra ||
    (function (...args) {
      (window.Chatra.q = window.Chatra.q || []).push(args);
    } as Chatra);

  script.async = true;
  script.src = 'https://call.chatra.io/chatra.js';
  if (document.head) document.head.appendChild(script);
}

export function sendUserInfoToChatService<T extends { email: string; id: string }>(user: T) {
  if (window.Chatra) {
    window.Chatra('setIntegrationData', {
      email: user.email,
      userId: user.id
    });
  }
}
