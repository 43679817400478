import { useQuery } from '@tanstack/react-query';
import { queries } from '../../../../queries';

export const useCurrencyPairs = (partnerId: string) => {
  const { data, isLoading, isError } = useQuery(queries.partners.currencyPairs.list(partnerId));

  return {
    currencyPairs: data,
    isLoadingCurrencyPairs: isLoading,
    isErrorLoadingCurrencyPairs: isError
  };
};
