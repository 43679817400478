import React from 'react';
import './BasePage.scss';
import classNames from 'classnames';
import Spinner from '../spinner/Spinner';

export default function BasePage(props: BasePageProps) {
  const { className, loading, topBar, children } = props;

  return (
    <div className={classNames('base-page-container', className)}>
      {topBar}
      {loading ? <Spinner variant="bar" /> : <div className="page-content">{children}</div>}
    </div>
  );
}

type BasePageProps = {
  className?: string;
  loading?: boolean;
  topBar?: React.ReactNode;
  children: React.ReactNode;
};
