import { BaseService } from '@grain/web-utils';
import { AdminConsoleExtendedJob, AllJobsResponse, JobResponse } from '@grain/job-scheduler-types';

export default class JobSchedulerService extends BaseService {
  private static instance: JobSchedulerService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new JobSchedulerService(baseUrl, handleError);
  }

  public static getInstance(): JobSchedulerService {
    return this.instance;
  }

  async getAllJobs(): Promise<AdminConsoleExtendedJob[]> {
    const { jobs }: AllJobsResponse = await this.authenticatedCall({
      url: 'jobs',
      method: 'GET'
    });

    return jobs;
  }

  async getJobById(jobId: string): Promise<AdminConsoleExtendedJob> {
    const { job }: JobResponse = await this.authenticatedCall({
      url: `jobs/${jobId}`,
      method: 'GET'
    });
    return job;
  }

  async disableJob(jobId: string): Promise<AdminConsoleExtendedJob> {
    const { job }: JobResponse = await this.authenticatedCall({
      url: `jobs/${jobId}/disable`,
      method: 'POST'
    });
    return job;
  }

  async enableJob(jobId: string): Promise<AdminConsoleExtendedJob> {
    const { job }: JobResponse = await this.authenticatedCall({
      url: `jobs/${jobId}/enable`,
      method: 'POST'
    });
    return job;
  }

  async executeJob(jobId: string): Promise<AdminConsoleExtendedJob> {
    const { job }: JobResponse = await this.authenticatedCall({
      url: `jobs/${jobId}/execute`,
      method: 'POST'
    });
    return job;
  }

  async getTriggeredJobs(jobId: string): Promise<AdminConsoleExtendedJob[]> {
    const { jobs }: AllJobsResponse = await this.authenticatedCall({
      url: `jobs/${jobId}/manually-triggered`,
      method: 'GET'
    });

    return jobs;
  }
}
