import React from 'react';

/*
  PLEASE NOTE:
  if you want to add support for more colors you have to add the color in the tailwind.config.js file's safelist array,
  otherwise Tailwind would not be able to detect the usage of the color as it's provided dynamically and won't generate it in the final CSS file.
 */
export const PartnerSectionTitleMarker = (props: PartnerSectionTitleMarkerProps) => {
  return <div className={`w-1 h-4 bg-${props.color}`} />;
};

type PartnerSectionTitleMarkerProps = {
  color: string;
};
