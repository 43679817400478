import { useQuery } from '@tanstack/react-query';
import { queries } from 'queries';
import GrainSettingsService from 'services/GrainSettingsService';

export function useGrainConfig() {
  const { data, isError, isLoading } = useQuery({
    ...queries.settings.grainConfig,
    queryFn: () => GrainSettingsService.getInstance().getGrainConfig()
  });

  return {
    grainConfig: data,
    hasFailedLoadingGrainConfig: isError,
    isLoadingGrainConfig: isLoading
  };
}
