import { BaseService } from '@grain/web-utils';
import { EmulateCollectionFundTransferRequestBody } from '@grain/payments-service-types';

export default class PlatformsService extends BaseService {
  private static instance: PlatformsService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new PlatformsService(baseUrl, handleError);
  }

  public static getInstance(): PlatformsService {
    return this.instance;
  }

  async syncPartnerBankAccounts(organizationId: string): Promise<void> {
    await this.authenticatedCall({
      url: `platforms/transfer-mate/sync-bank-accounts`,
      method: 'POST',
      data: { organizationId }
    });
  }

  async emulateCollectionFundTransfer(organizationId: string, currency: string, amount: number): Promise<void> {
    await this.authenticatedCall<void, EmulateCollectionFundTransferRequestBody>({
      url: 'platforms/currency-cloud/emulate-collection-fund-transfer',
      method: 'POST',
      data: { organizationId, currency, amount }
    });
  }
}
