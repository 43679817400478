import React, { CSSProperties, ReactElement } from 'react';
import './Flag.scss';
import 'flag-icons/css/flag-icons.min.css';

export interface FlagProps {
  countryCode: string;
  size: CSSProperties['width'];
}

export function Flag(props: FlagProps): ReactElement {
  const { size } = props;

  return (
    <div className="flag-container">
      <span className={`flag fi fi-${props.countryCode?.toLowerCase()} fis`} style={{ height: size, width: size }} />
    </div>
  );
}
