import React, { ReactElement } from 'react';
import { flexRender, Row, Table } from '@tanstack/react-table';
import './TableBody.scss';
import classNames from 'classnames';
import { nonPaginatedPageSize, rowHeight } from './Table';

export interface TableBodyProps<TData> {
  onRowClick?: (row: Row<TData>) => void;
  loading?: boolean;
  table: Table<TData>;
}

export function TableBody<TData>(props: TableBodyProps<TData>): ReactElement {
  const { onRowClick, loading, table } = props;
  const pageSize = table.getState().pagination?.pageSize || nonPaginatedPageSize;
  const emptyRowsCount = pageSize - table.getRowModel().rows.length;

  return (
    <tbody className={classNames('table-body', { loading })}>
      {table.getRowModel().rows.map((row) => (
        <tr
          className={classNames('table-row', {
            'table-row-clickable': onRowClick
          })}
          key={row.id}
          onClick={onRowClick && (() => onRowClick(row))}
          onMouseEnter={() => {
            table.setHoveredRows((old) => ({
              ...old,
              [row.id]: true
            }));
          }}
          onMouseLeave={() => {
            table.setHoveredRows((old) => ({
              ...old,
              [row.id]: false
            }));
          }}
        >
          {row.getVisibleCells().map((cell) => (
            <td className="table-cell" key={cell.id} data-testid={`${cell.column.id}`}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}

      {table.getRowModel().rows.length < pageSize && (
        <tr className="empty-table-row" style={{ height: `calc(${rowHeight} * ${emptyRowsCount})` }}>
          <td
            colSpan={table.getAllColumns().length}
            style={{
              width: '100%',
              height: `calc(${rowHeight} * ${emptyRowsCount})`
            }}
          />
        </tr>
      )}
    </tbody>
  );
}
