import { Partner } from './partner';
import { ApiKey } from './api-key';

export enum OrganizationType {
  Live = 'Live',
  Sandbox = 'Sandbox',
  Demo = 'Demo'
}

export interface Organization {
  id: string;
  name: string;
  key?: string;
  apiKeys?: ApiKey[];
  type: OrganizationType;
  partner: Partner;
  partnerId?: string;
}
