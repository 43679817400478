import { mergeQueryKeys } from '@lukemorales/query-key-factory';
import { paymentBeneficiariesQueries } from './paymentBeneficiariesQueries';
import { settingsQueries } from './settings';
import { treasuryQueries } from './treasury';
import { partnersQueries } from './partners';
import { partnerQueries } from './partner';

export const queries = {
  ...mergeQueryKeys(paymentBeneficiariesQueries),
  ...mergeQueryKeys(partnerQueries),
  partners: partnersQueries,
  settings: settingsQueries,
  treasury: treasuryQueries
};
