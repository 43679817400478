import { BaseDropdownOption } from '@grain/web-components/forms/BaseDropdown';
import { POALIM_SUPPORTED_CURRENCIES } from '@grain/rate-utils';
import { FieldsGroup, GRAIN_PREFIX } from '@grain/web-components/mapping/BaseFieldsMapper';
import { BreakpointType } from '@grain/web-components/layout/use-window-dimensions';
import { MappingTransactionType } from '@grain/core-types';

const ADDITIONAL_CURRENCY_OPTIONS: BaseDropdownOption[] = POALIM_SUPPORTED_CURRENCIES.map((currency) => ({
  value: `${GRAIN_PREFIX}${currency}`,
  text: currency
}));

const DATE_FIELDS_MAPPING: FieldsGroup<MappingTransactionType> = {
  fields: [
    { text: 'Issue Date', name: 'issuedAt', calculable: false },
    {
      text: 'Payment Actual Date',
      name: 'paidAt',
      calculable: false,
      supportUnknown: true
    },
    {
      text: 'Payment Due Date',
      name: 'dueAt',
      calculable: false,
      supportUnknown: true
    },
    {
      text: 'Cancel Date',
      name: 'cancelledAt',
      calculable: false,
      supportUnknown: true
    }
  ],
  title: 'Dates',
  className: 'double'
};

const CURRENCY_FIELD = {
  text: 'Currency',
  calculable: false,
  additionalOptions: ADDITIONAL_CURRENCY_OPTIONS
};
const FOREIGN_CURRENCY_FIELDS_MAPPING: FieldsGroup<MappingTransactionType> = {
  fields: [
    { ...CURRENCY_FIELD, name: 'toCurrency' },
    {
      text: 'Amount',
      name: 'toCurrencyAmount',
      calculable: true,
      supportUnknown: true
    },
    {
      text: 'Amount_USD',
      name: 'toCurrencyAmountUSD',
      calculable: true,
      supportUnknown: true
    }
  ],
  title: 'Foreign Currency'
};

const BASE_CURRENCY_FIELDS_MAPPING: FieldsGroup<MappingTransactionType> = {
  fields: [
    {
      ...CURRENCY_FIELD,
      name: 'fromCurrency',
      doubleBreakpoint: BreakpointType.Tablet
    },
    {
      text: 'Amount on Issue Date',
      name: 'fromCurrencyIssuedAmount',
      calculable: true
    },
    {
      text: 'Amount on Pay Date',
      name: 'fromCurrencyPaidAmount',
      calculable: true
    },
    { text: 'Extra Fields', name: 'extraFields', supportUnknown: true }
  ],
  title: 'Base Currency',
  className: 'double'
};

export const PARTNER_FIELDS_MAPPING: FieldsGroup<MappingTransactionType> = {
  fields: [
    { text: 'Customer Id', name: 'externalCustomerId' },
    {
      text: 'Transaction Id',
      name: 'externalTransactionId',
      supportUnknown: true
    },
    { text: 'Customer Country', name: 'country', supportUnknown: true }
  ],
  title: 'Partner'
};

export const ALL_MAPPING_FIELD_GROUPS = [DATE_FIELDS_MAPPING, FOREIGN_CURRENCY_FIELDS_MAPPING, BASE_CURRENCY_FIELDS_MAPPING];
