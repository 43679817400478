import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Returns the entire search string (anything after ? in the URL) and remove some (or all) of the params.
 * @param callback The callback to call with the consumed search string
 * @param requiredParams The params required in order to invoke callback. They are also removed from the search string afterwards.
 */
export function useConsumableSearchString(callback: (searchString: string) => void, requiredParams?: string[]) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const searchString = searchParams.toString();
    if (searchString.length && (!requiredParams || requiredParams.every((param) => searchParams.has(param)))) {
      (requiredParams || Array.from(searchParams.keys())).forEach((param) => searchParams.delete(param));
      setSearchParams(searchParams);
      callback(searchString);
    }
  }, [callback, requiredParams, searchParams, setSearchParams]);
}
