import { GrainCurrencyPair } from '@grain/core-types';
import { useQuery } from '@tanstack/react-query';
import { queries } from 'queries';

export function useProviderStatuses(currencyPairs: GrainCurrencyPair[] | undefined) {
  const { data, isError, isLoading } = useQuery({
    ...queries.settings.providerStatuses.query(currencyPairs),
    enabled: Boolean(currencyPairs?.length),
    keepPreviousData: true,
    refetchInterval: 30_000,
    staleTime: 5_000
  });

  return {
    isErrorLoadingProviderStatuses: isError,
    isLoadingProviderStatuses: isLoading,
    providerStatuses: data
  };
}
