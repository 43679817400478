import React, { ChangeEvent, ReactElement, useState } from 'react';
import BaseInput from '@grain/web-components/forms/BaseInput';
import { GrainConfig } from '@grain/admin-console-api-types';
import EditConfigDialog from '../config/EditConfigDialog';
import GrainSettingsService from '../../services/GrainSettingsService';

export default function EditGrainConfigDialog({ initialData, onCloseAfterSuccess, triggerComponent }: EditGrainConfigDialogProps) {
  const [editedData, setEditedData] = useState<Partial<GrainConfig>>();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const onSubmit = async (newValues: Partial<GrainConfig>) => {
    await GrainSettingsService.getInstance().updateGrainConfig(newValues);
    setEditedData({});
  };

  return (
    <EditConfigDialog
      onCloseAfterSuccess={onCloseAfterSuccess}
      onSubmit={onSubmit}
      editedData={editedData}
      initialData={initialData}
      triggerComponent={triggerComponent}
    >
      <BaseInput
        name="maxOpenPositionsTotal"
        labelText="Max Open Positions Total"
        defaultValue={initialData.maxOpenPositionsTotal}
        onChange={onChange}
        hideErrorText
        small
      />
    </EditConfigDialog>
  );
}

type EditGrainConfigDialogProps = {
  initialData: Partial<GrainConfig>;
  onCloseAfterSuccess: () => void;
  triggerComponent?: ReactElement;
};
