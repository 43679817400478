import React from 'react';
import './BaseTablePaginator.scss';
import { Cell, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

export default function BaseTableCell<T>(props: BaseTableCellProps<T>) {
  const { cell, className, textAlign, width } = props;
  return (
    <td data-testid={`${cell.column.id}`} className={classNames(className, textAlign)} style={{ width }}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
}

interface BaseTableCellProps<T> extends BaseTableCellCommonProps {
  cell: Cell<T, any>;
}

export interface BaseTableCellCommonProps {
  className?: string;
  textAlign?: string;
  width?: number;
}
