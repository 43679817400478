import React, { ChangeEvent, useState } from 'react';
import { Add } from 'iconsax-react';
import BaseInput from '@grain/web-components/forms/BaseInput';
import BaseDropdown from '@grain/web-components/forms/BaseDropdown';
import FormDialog from '@grain/web-components/dialogs/FormDialog';
import { IconicButton } from '@grain/web-components/iconic-button/IconicButton';
import PartnersService from '../../services/PartnersService';
import { useNavigate } from 'react-router-dom';

const internalOptions = [
  { value: 'true', text: 'Internal' },
  { value: 'false', text: 'External' }
];
const EXPECTED_NUM_OF_PARAMETERS = 6;
const partnerDataEmptyState = { platform: 'partners', internal: true };

export default function CreatePartnerDialog(props: CreatePartnerDialogProps) {
  const { onDialogClose } = props;
  const [newData, setNewData] = useState<CreatePartnerParams>(partnerDataEmptyState);
  const navigate = useNavigate();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  const onInternalChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNewData({ ...newData, internal: value === 'true' });
  };

  const canSubmit = Object.keys(newData).length === EXPECTED_NUM_OF_PARAMETERS;

  const onSubmit = async () => {
    const createdPartner = await PartnersService.getInstance().createPartner(
      newData.platform!,
      newData.internal,
      newData.name!,
      newData.contactEmail!,
      newData.contactFirstName!,
      newData.contactLastName!
    );
    navigate(`/partners/${createdPartner.id}`);
  };

  const onClose = () => {
    setNewData(partnerDataEmptyState);
    onDialogClose();
  };

  return (
    <FormDialog
      title="Create Partner"
      trigger={<IconicButton icon={Add} text="Create Partner" />}
      canSubmit={canSubmit}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <BaseDropdown
        name="internal"
        labelText="Internal/External"
        value={newData.internal}
        options={internalOptions}
        onChange={onInternalChange}
        hideErrorText
        small
      />
      <BaseInput name="name" labelText="Name" value={newData.name || ''} onChange={onChange} hideErrorText small />
      <BaseInput name="contactEmail" labelText="Contact Email" value={newData.contactEmail || ''} onChange={onChange} hideErrorText small />
      <BaseInput
        name="contactFirstName"
        labelText="Contact First Name"
        value={newData.contactFirstName || ''}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="contactLastName"
        labelText="Contact Last Name"
        value={newData.contactLastName || ''}
        onChange={onChange}
        hideErrorText
        small
      />
    </FormDialog>
  );
}

type CreatePartnerParams = {
  platform: string;
  internal: boolean;
  name?: string;
  contactEmail?: string;
  contactFirstName?: string;
  contactLastName?: string;
};

type CreatePartnerDialogProps = {
  onDialogClose: () => void;
};
