import { BaseService } from '@grain/web-utils';
import { AdminConsoleApiAuditLog, AllAuditResponse } from '@grain/audit-logs-service-types';

export default class AuditLogsService extends BaseService {
  private static instance: AuditLogsService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new AuditLogsService(baseUrl, handleError);
  }

  public static getInstance(): AuditLogsService {
    return this.instance;
  }
  async getAllAuditLogs(payload: GetAuditLogRequestParams = {}): Promise<AdminConsoleApiAuditLog[]> {
    const { auditLogs }: AllAuditResponse = await this.authenticatedCall({
      url: 'audit-logs',
      params: payload,
      method: 'GET'
    });

    return auditLogs;
  }
}

export interface GetAuditLogRequestParams {
  organizationId?: string;
}
