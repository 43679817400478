import React from 'react';
import classNames from 'classnames';

export const Tag = (props: TagProps) => {
  const { value, className } = props;

  return (
    <div className={classNames(className, 'w-fit h-fit leading-5 rounded border border-indigo-500/100 text-indigo-500/100 px-1')}>
      {value}
    </div>
  );
};

export type TagProps = {
  value: string;
  className?: string;
};
