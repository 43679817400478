import React, { CSSProperties, ReactElement } from 'react';
import classNames from 'classnames';
import './Separator.scss';

export interface SeparatorProps {
  className?: string;
  orientation?: 'horizontal' | 'vertical';
  style?: CSSProperties;
}

export function Separator(props: SeparatorProps): ReactElement {
  const { orientation } = props;

  return (
    <hr
      aria-orientation={orientation}
      className={classNames(
        'separator',
        {
          'separator-horizontal': orientation !== 'vertical',
          'separator-vertical': orientation === 'vertical'
        },
        props.className
      )}
      role="separator"
      style={props.style}
    />
  );
}
