import React from 'react';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { AdminConsoleExtendedJob } from '@grain/job-scheduler-types';
import ConfirmationDialog from '@grain/web-components/dialogs/ConfirmationDialog';
import { DialogTrigger } from '@grain/web-components/dialogs/Dialog';

export default function ApproveJobAction(props: ApproveWithdrawalDialogProps) {
  const { job, onApprovedAction, action } = props;

  const onAccept = () => {
    onApprovedAction?.(job);
  };

  return (
    <ConfirmationDialog title={`${action} Job`} content={`${action} job ${job.name}`} onAccept={onAccept}>
      <DialogTrigger>
        <CTAButton text={action} size="small" />
      </DialogTrigger>
    </ConfirmationDialog>
  );
}

type ApproveWithdrawalDialogProps = {
  job: AdminConsoleExtendedJob;
  action: 'Enable' | 'Disable' | 'Execute';
  onApprovedAction?: (job: AdminConsoleExtendedJob) => void;
};
