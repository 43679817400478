import React, { ChangeEvent, useState } from 'react';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import BaseInput from '@grain/web-components/forms/BaseInput';
import './CreateCounterpartyDialog.scss';
import FormDialog, { FormDialogProps } from '@grain/web-components/dialogs/FormDialog';
import { EnableTransferMateRequestPayload } from '@grain/admin-console-api-types';

export default function CreateCounterpartyDialog({
  dialogContent,
  dialogTitle,
  trigger,
  onCounterpartyCreationRequested,
  onCounterpartyCreationSuccessful
}: CreateCounterpartyDialogProps) {
  const [data, setData] = useState<EnableTransferMateRequestPayload>({
    transferMatePassword: '',
    transferMateUsername: ''
  });
  const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => setData({ ...data, [name]: value });

  const canSubmit = !!data.transferMatePassword && !!data.transferMateUsername;

  const onSubmit = async () => {
    await onCounterpartyCreationRequested(data);
    await onCounterpartyCreationSuccessful?.();
  };

  return (
    <FormDialog
      title={dialogTitle}
      trigger={trigger ? trigger : <CTAButton text={dialogTitle} size="small" />}
      canSubmit={canSubmit}
      onSubmit={onSubmit}
      className="create-counterparty-dialog-container"
    >
      <div className="dialog-instructions">{dialogContent}</div>
      <BaseInput name="transferMateUsername" labelText="TransferMate username" onChange={onChange} hideErrorText small />
      <BaseInput name="transferMatePassword" labelText="TransferMate password" onChange={onChange} hideErrorText type="password" small />
    </FormDialog>
  );
}

type CreateCounterpartyDialogProps = {
  trigger?: FormDialogProps['trigger'];
  dialogContent: string;
  dialogTitle: string;
  onCounterpartyCreationRequested: (params: EnableTransferMateRequestPayload) => Promise<void>;
  onCounterpartyCreationSuccessful?: () => void;
};
