import React, { PropsWithChildren } from 'react';
import { ReactComponent as TableEmptyStateIcon } from './TableEmptyStateIcon.svg';
import './TableEmptyState.scss';

export const TableEmptyState = (props: PropsWithChildren<object>) => {
  return (
    <div className="table-empty-state-container">
      {props.children ? (
        props.children
      ) : (
        <>
          <TableEmptyStateIcon />

          <div className="table-empty-state-title">No data yet</div>
        </>
      )}
    </div>
  );
};
