import { useMutation } from '@tanstack/react-query';
import { TreasuryService } from 'services/TreasuryService';

export const useSyncTreasury = () => {
  const { error, isError, isLoading, isSuccess, mutate } = useMutation({
    mutationFn: () => TreasuryService.getInstance().syncGrainTreasuryBankAccounts()
  });

  return {
    hasSyncedTreasurySuccessfully: isSuccess,
    hasFailedSyncingTreasury: isError,
    syncTreasury: mutate,
    isSyncingTreasury: isLoading,
    syncTreasuryError: error
  };
};
