const milliToHoursMultiplier = 1000 * 60 * 60;
const milliToDaysMultiplier = milliToHoursMultiplier * 24;

export function millisecondsAsDays(milliseconds: number): number {
  return Math.round(milliseconds / milliToDaysMultiplier);
}

export function daysAsMilliseconds(days: number): number {
  return Math.round(days * milliToDaysMultiplier);
}

export function hoursAsMilliseconds(hours: number): number {
  return Math.round(hours * milliToHoursMultiplier);
}
