import React, { useRef } from 'react';
import BaseDialog, { BaseDialogHandle } from '@grain/web-components/dialogs/BaseDialog';
import UploadSection from '@grain/web-components/sections/UploadSection';
import PartnersService from '../../../services/PartnersService';

export default function UploadTransactionsDialog(props: UploadTransactionsDialogProps) {
  const { partnerId, onUploadSucceed, open, onClose } = props;
  const dialogRef = useRef<BaseDialogHandle>(null);
  const transactionsFileChosen = (file: File, fileUrl: string) => {
    onUploadSucceed(file, fileUrl);
    dialogRef.current?.hideDialog();
  };

  return (
    <BaseDialog ref={dialogRef} open={open} onClose={onClose} onCancel={onClose} contentClassName="upload-hedges-dialog-container">
      <div className="upload-dialog-content-container">
        <UploadSection
          onFileUploaded={transactionsFileChosen}
          uploadFile={(file) => {
            return PartnersService.getInstance().uploadTransactions(partnerId, file);
          }}
        />
      </div>
    </BaseDialog>
  );
}

interface UploadTransactionsDialogProps {
  open: boolean;
  partnerId: string;
  onUploadSucceed: (file: File, fileUrl: string) => void;
  onClose?: () => void;
}
