import React, { PropsWithChildren } from 'react';
import './BaseTopBar.scss';
import classNames from 'classnames';
import { UserDetailsType } from '../user/NameTag';
import UserMenu from '../user/UserMenu';
import PageBreadcrumbs from './PageBreadcrumbs';

export default function BaseTopBar(props: PropsWithChildren<TopBarProps>) {
  const { className, title, userDetails, showBreadcrumbs, children, subtitle } = props;

  return (
    <div className={classNames('base-top-bar-container', className)}>
      <div className="titles">
        {title && <div className="title">{title}</div>}
        {showBreadcrumbs && <PageBreadcrumbs />}
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
      {userDetails && <UserMenu userDetails={userDetails}>{children}</UserMenu>}
    </div>
  );
}

type TopBarProps = {
  className?: string;
  title?: string;
  subtitle?: string;
  userDetails?: UserDetailsType;
  showBreadcrumbs?: boolean;
};
