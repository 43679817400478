import BaseDialog, { BaseDialogHandle } from '@grain/web-components/dialogs/BaseDialog';
import React, { useRef } from 'react';
import './UpdateForwardPointsDialog.scss';
import UploadSection from '@grain/web-components/sections/UploadSection';
import ForwardsService from '../../services/ForwardsService';

export default function UpdateForwardPointsDialog({ onUploadSucceed, triggerComponent, onUploadStart }: UpdateForwardPointsDialogProps) {
  const dialogRef = useRef<BaseDialogHandle>(null);
  const onFileUploaded = (file: File, url: string) => {
    onUploadSucceed(file, url);
    dialogRef.current?.hideDialog();
  };

  return (
    <BaseDialog ref={dialogRef} trigger={triggerComponent} contentClassName="upload-forward-points-dialog-container">
      <div className="upload-forward-points-content-container">
        <UploadSection
          onFileUploaded={onFileUploaded}
          uploadFile={(file) => {
            onUploadStart?.();
            return ForwardsService.getInstance().uploadForwardPoints(file);
          }}
        />
      </div>
    </BaseDialog>
  );
}

interface UpdateForwardPointsDialogProps {
  onUploadStart?: () => void;
  onUploadSucceed: (file: File, jobId: string) => void;
  triggerComponent: React.ReactNode;
}
