import React, { ComponentType, ReactElement } from 'react';
import classNames from 'classnames';
import { IconProps } from 'iconsax-react';
import './IconButton.scss';

export interface IconButtonProps {
  children?: React.ReactNode;
  icon?: ComponentType<IconProps>;
  className?: string;
  onClick?: (e: any) => void;
}

export function IconButton(props: IconButtonProps): ReactElement {
  let icon;
  if (props.icon) {
    icon = <props.icon size="24px" />;
  } else {
    icon = props.children;
  }

  return (
    <button className={classNames('icon-button', props.className)} onClick={props.onClick} type="button">
      <span className="icon-button-icon">{icon}</span>
    </button>
  );
}
