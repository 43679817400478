import { Link, useMatches } from 'react-router-dom';
import React from 'react';
import classNames from 'classnames';
import './BaseMenuItem.scss';

export default function BaseMenuItem(props: BaseMenuItemProps) {
  const matches = useMatches();

  const { title, route, external, leftIcon, rightIcon, onClick, disable } = props;

  const selected = matches.some((m) => m.pathname === route);
  const leftIconChild = React.cloneElement(leftIcon, {
    className: 'left item-icon',
    variant: selected ? 'Bold' : 'Outline'
  });
  const rightIconChild = rightIcon
    ? React.cloneElement(rightIcon, {
        className: 'right item-icon',
        variant: selected ? 'Bold' : 'Outline'
      })
    : null;

  const content = (
    <>
      {leftIconChild}
      <div className="menu-item-text">{title}</div>
      {rightIconChild}
    </>
  );

  if (external) {
    return (
      <a className={classNames('menu-item-container', { disable })} href={route} onClick={onClick} target="_blank" rel="noreferrer">
        {content}
      </a>
    );
  }
  return (
    <Link className={classNames('menu-item-container', { selected, disable })} to={route} onClick={onClick}>
      {content}
    </Link>
  );
}

export type BaseMenuItemProps = {
  title: string;
  route: string;
  external?: boolean;
  leftIcon: React.ReactElement;
  rightIcon?: React.ReactElement;
  onClick?: () => void;
  disable?: boolean;
};
