import React, { ChangeEvent } from 'react';
import './BaseCheckbox.scss';

export default function BaseCheckbox(props: BaseCheckboxProps) {
  return (
    <div className="base-checkbox-container">
      <input className="checkbox-input" id={props.id} type="checkbox" checked={props.checked} onChange={props.onChange} />
      <label htmlFor="checkbox" className="checkbox-label">
        {props.text}
      </label>
    </div>
  );
}

type BaseCheckboxProps = {
  id: string;
  text: string;
  checked: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};
