export type EntityMappingForConstantValues<K> = Partial<Record<keyof K, string>>;
export type EntityMappingByIndex<K> = Partial<Record<keyof K, number>>;
export type FileMappingRequest<K> = {
  fileUrl: string;
  sheetName: string;
  mappedValues: EntityMappingByIndex<K>;
  constantValues: EntityMappingForConstantValues<K>;
  skipRows?: boolean;
};

export enum MappingErrorTypes {
  InvalidDate = 'invalid-date',
  InconsistentDate = 'inconsistent-date',
  PartialRowData = 'partial-row-data',
  ValueError = 'value-error'
}
