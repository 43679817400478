import { createColumnHelper, Table, usePagination, useTable } from '@grain/web-components/table-v2';
import { Edit, Tag, Trash } from 'iconsax-react';
import { IconButton } from '@grain/web-components/buttons/IconButton';
import React, { useState } from 'react';
import { SeparatorRow } from '@grain/web-components/separator-row/SeparatorRow';
import OfflineMarkupForm from './OfflineMarkupForm';
import { convertCurrencyPairToPairString } from '@grain/rate-utils';
import { Rates, TenorRate } from '@grain/core-types';
import BaseDialog from '@grain/web-components/dialogs/BaseDialog';
import { useCurrencyPairs } from './hooks';
import { AdminConsoleApiPartnerCurrencyPair } from '@grain/admin-console-api-types';
import { capitalizeFirstLetter } from '@grain/formatters';
import { EditCurrencyPairDialog } from './EditCurrencyPairDialog';
import ConfirmationDialog from '@grain/web-components/dialogs/ConfirmationDialog';

export const PartnerCurrencyPairsTable = (props: PartnerCurrencyPairsTableProps) => {
  const [selectedPair, setSelectedPair] = useState<AdminConsoleApiPartnerCurrencyPair | null>(null);
  const [displayedDialog, setDisplayedDialog] = useState<DialogType | null>(null);

  const { currencyPairs, isLoadingCurrencyPairs } = useCurrencyPairs(props.partnerId);
  const { paginationProps } = usePagination({
    initialPageSize: 5
  });
  const { table } = useTable(
    {
      data: currencyPairs ?? [],
      columns: getColumns({
        ...props,
        onPairRemoveIconClick: (pair) => {
          setSelectedPair(pair);
          setDisplayedDialog(DialogType.RemovePair);
        },
        onUploadMarkupIconClick: (pair) => {
          setSelectedPair(pair);
          setDisplayedDialog(DialogType.UploadMarkup);
        },
        onEditIconClick: (pair) => {
          setSelectedPair(pair);
          setDisplayedDialog(DialogType.EditPair);
        }
      })
    },
    paginationProps
  );

  const closeDialog = () => {
    setDisplayedDialog(null);
    setSelectedPair(null);
  };

  return (
    <>
      <Table loading={isLoadingCurrencyPairs} table={table} />

      {selectedPair != null && (
        <>
          {props.currencyTenorMarkup?.[convertCurrencyPairToPairString(selectedPair)] && (
            <BaseDialog open={displayedDialog === DialogType.UploadMarkup} onCancel={closeDialog}>
              <OfflineMarkupForm
                tenorRates={props.currencyTenorMarkup[convertCurrencyPairToPairString(selectedPair)]}
                updateCurrencyPairTenorMarkup={props.updateCurrencyPairTenorMarkup}
                currencyPair={convertCurrencyPairToPairString(selectedPair)}
                onSaveRequest={closeDialog}
              />
            </BaseDialog>
          )}

          <EditCurrencyPairDialog
            partnerId={props.partnerId}
            open={displayedDialog === DialogType.EditPair}
            pair={selectedPair}
            onSave={closeDialog}
            onCancel={closeDialog}
          />
          <ConfirmationDialog
            open={displayedDialog === DialogType.RemovePair}
            onOpenChange={closeDialog}
            title="Remove Currency Pair"
            content={`This will remove ${selectedPair.fromCurrency} <> ${selectedPair.toCurrency} pair from the approved currency pairs list`}
            onAccept={() => {
              props.onPairRemove?.(selectedPair);
              closeDialog();
            }}
          />
        </>
      )}
    </>
  );
};

type PartnerCurrencyPairsTableProps = {
  partnerId: string;
  onPairRemove?: (pair: AdminConsoleApiPartnerCurrencyPair) => void;
  currencyTenorMarkup?: Rates;
  updateCurrencyPairTenorMarkup: (tenorRates: TenorRate, currencyPair: string) => void;
};

enum DialogType {
  EditPair = 'editPair',
  RemovePair = 'removePair',
  UploadMarkup = 'uploadMarkup'
}

const columnHelper = createColumnHelper<AdminConsoleApiPartnerCurrencyPair>();

const getColumns = (params: PartnerCurrencyPairsTableGetColumnsParams) => {
  return [
    columnHelper.accessor('fromCurrency', {
      header: 'From Currency'
    }),
    columnHelper.accessor('toCurrency', {
      header: 'To Currency'
    }),
    columnHelper.accessor('settlementType', {
      header: 'Type',
      cell: (info) => capitalizeFirstLetter(info.getValue())
    }),
    columnHelper.display({
      id: 'actions',
      header: '',
      cell: ({ row }) => {
        const pair = row.original;
        const currencyPairString = convertCurrencyPairToPairString(pair);
        const tenorRates = params.currencyTenorMarkup?.[currencyPairString];

        return (
          <div
            style={{
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {row.getIsHovered() && (
              <SeparatorRow>
                <IconButton icon={Trash} onClick={() => params.onPairRemoveIconClick(pair)} />

                <IconButton icon={Edit} onClick={() => params.onEditIconClick(pair)} />

                {tenorRates && <IconButton icon={Tag} onClick={() => params.onUploadMarkupIconClick(pair)} />}
              </SeparatorRow>
            )}
          </div>
        );
      }
    })
  ];
};

interface PartnerCurrencyPairsTableGetColumnsParams extends PartnerCurrencyPairsTableProps {
  onPairRemoveIconClick: (pair: AdminConsoleApiPartnerCurrencyPair) => void;
  onUploadMarkupIconClick: (pair: AdminConsoleApiPartnerCurrencyPair) => void;
  onEditIconClick: (pair: AdminConsoleApiPartnerCurrencyPair) => void;
}
