import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queries } from '../queries';
import PartnersService from '../services/PartnersService';

export interface DeletePartnerUserParams {
  partnerId: string;
  userId: string;
}

export const useDeletePartnerUser = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: deletePartnerUser,
    isError: partnerUserDeletionFailed,
    isLoading: isDeletingPartnerUser,
    isSuccess: partnerUserDeletionSucceeded,
    reset: resetPartnerUserDeletionMutation,
    error: partnerUserDeletionError
  } = useMutation<void, Error, DeletePartnerUserParams>(
    (params) => PartnersService.getInstance().deletePartnerUser({ partnerId: params.partnerId, userId: params.userId }),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(queries.partners.users.list._def);
      }
    }
  );

  return {
    deletePartnerUser,
    partnerUserDeletionFailed,
    isDeletingPartnerUser,
    partnerUserDeletionSucceeded,
    resetPartnerUserDeletionMutation,
    partnerUserDeletionError
  };
};
