import { useState } from 'react';
import { OrganizationType } from '@grain/core-types';

export function useOrganizationType(): [organizationType: OrganizationType, toggleOrganizationType: () => void] {
  const [organizationType, setOrganizationType] = useState<OrganizationType>(OrganizationType.Live);

  const toggleOrganizationType = () => {
    if (organizationType === OrganizationType.Live) {
      setOrganizationType(OrganizationType.Sandbox);
    } else {
      setOrganizationType(OrganizationType.Live);
    }
  };

  return [organizationType, toggleOrganizationType];
}

export function getOrganizationTypeText(organizationType: OrganizationType): string {
  return organizationType === OrganizationType.Live ? 'Show Sandbox' : 'Show Live';
}
