import { useQueryClient } from '@tanstack/react-query';
import { queries } from '../queries';

export function useRefreshGrainConfig() {
  const queryClient = useQueryClient();

  return {
    refreshGrainConfig: () =>
      queryClient.invalidateQueries({
        ...queries.settings.grainConfig,
        exact: true
      })
  };
}
