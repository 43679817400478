import React, { useState } from 'react';
import classNames from 'classnames';
import './ExpandableMenuItem.scss';
import { ArrowRight2 } from 'iconsax-react';
import { BreakpointType, getWindowDimensions } from './use-window-dimensions';

const calcInitialState = (initialExpand?: boolean) => {
  if (initialExpand !== undefined) return initialExpand;
  return getWindowDimensions().screenWidth > BreakpointType.Mobile;
};
export default function ExpandableMenuItem<T>(props: ExpandableMenuItemProps<T>) {
  const { title, children, userRole, restrictToRoles, initialExpand } = props;
  const [expanded, setExpanded] = useState<boolean>(calcInitialState(initialExpand));

  if (restrictToRoles && userRole && !restrictToRoles.includes(userRole)) {
    return null;
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classNames('expandable-menu-item-container', { expanded })}>
      <div className="toggleable" onClick={toggleExpanded}>
        <div className="title-and-item">
          <div className="menu-item-text">{title}</div>
        </div>
        <ArrowRight2 className="expand-arrow" size={18} />
      </div>
      <div className="child-items">{children}</div>
    </div>
  );
}

type ExpandableMenuItemProps<T> = {
  title: string;
  children: React.ReactNode;
  userRole?: T;
  restrictToRoles?: T[];
  initialExpand?: boolean;
};
