import { BaseService } from '@grain/web-utils';
import { ListSpotsResponse } from '@grain/admin-console-api-types';

export default class SpotsService extends BaseService {
  private static instance: SpotsService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new SpotsService(baseUrl, handleError);
  }

  public static getInstance(): SpotsService {
    return this.instance;
  }

  async getSpotsRequest() {
    const res = await this.authenticatedCall<ListSpotsResponse>({
      url: 'spots/list',
      method: 'GET'
    });

    return res.spots;
  }
}
