import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { AdminConsoleUser } from '@grain/admin-console-api-types';
import { getExtraReducers, getUserStoreInitialState, UserState } from '@grain/web-utils';

const initialState = getUserStoreInitialState<AdminConsoleUser>();

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<UserState<AdminConsoleUser>>) => {
    getExtraReducers(builder);
  }
});

export const isAuthenticated = (state: RootState) => state.user.isAuthenticated;
export const getCurrentUser = (state: RootState) => state.user.user;

export const userSelectors = {
  isAuthenticated,
  getCurrentUser
};

export default userSlice.reducer;
export const { actions: userActions } = userSlice;

export { finishGoogleLogin, logout, getUser } from '@grain/web-utils';
