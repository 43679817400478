import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { ExportCurve } from 'iconsax-react';
import Spinner from '../spinner/Spinner';
import CTAButton from '../buttons/CTAButton';
import './FileDropzone.scss';

export function FileDropzone<T>(props: FileDropzoneProps<T>) {
  const { className, fileTypes = ['csv', 'xls', 'xlsx'], uploadFile, onFileUploaded } = props;
  const [loading, setLoading] = useState(false);
  const fileChosen = async ([file]: File[]) => {
    try {
      setLoading(true);
      const res: T = await uploadFile(file);
      onFileUploaded(file, res);
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps, open, isFocused, isDragAccept } = useDropzone({
    noClick: true,
    multiple: false,
    onDropAccepted: fileChosen
  });

  const rootClassName = classNames('upload-section', className, {
    focused: isFocused,
    'drag-accept': isDragAccept
  });

  return (
    <div {...getRootProps({ className: rootClassName })}>
      <input {...getInputProps()} />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="title">Drop here</div>
          <div className="subtitle">File type should be {fileTypes.map((type) => type.toUpperCase()).join(', ')}</div>
          <CTAButton text="Select File" leadingIcon={<ExportCurve size={20} />} onClick={open} size="small" />
        </>
      )}
    </div>
  );
}

export type FileDropzoneProps<T> = {
  className?: string;
  fileTypes?: string[];
  uploadFile: (file: File) => Promise<T>;
  onFileUploaded: (file: File, uploadResponse: T) => void;
};
