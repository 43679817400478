export interface IRatesSnapshot {
  revision: number;
  rates: Rates;
  validUntil: Date;
}

type OfflineCurrencyPair = string;

export enum Tenor {
  Spot = '0',
  Week = '7',
  TwoWeeks = '14',
  Month = '30',
  TwoMonths = '60',
  ThreeMonths = '90',
  FourMonths = '120',
  FiveMonths = '150',
  SixMonths = '180',
  SevenMonths = '210',
  EightMonths = '240',
  NineMonths = '270',
  TenMonths = '300',
  ElevenMonths = '330',
  OneYear = '360'
}

export type TenorRate = Record<Tenor, number>;
export type Rates = Record<OfflineCurrencyPair, TenorRate>;
