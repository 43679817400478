import * as FullStory from '@fullstory/browser';
import { UserVars } from '@fullstory/browser';
import AnalyticsService from './AnalyticsService';
import environment from '../environments/environment';

declare global {
  interface Window {
    _fs_ready: () => void;
  }
}

class ScreenCaptureService {
  private static instance: ScreenCaptureService;
  private isInitialized: boolean;
  private userDetails?: { id: string; properties: UserVars; pending: boolean };

  private constructor() {
    this.isInitialized = false;
  }

  public static getInstance(): ScreenCaptureService {
    if (!this.instance) {
      this.instance = new ScreenCaptureService();
    }
    return this.instance;
  }

  public initScreenCapturing() {
    if (environment.enableScreenCapturing) {
      FullStory.init({ orgId: environment.fullstoryOrgID }, (data) => {
        this.isInitialized = true;
        if (this.userDetails?.pending) {
          this.setUser(this.userDetails.id, this.userDetails.properties);
          this.userDetails.pending = false;
        }
        AnalyticsService.getInstance().logEvent('New User Session Started', {
          screenCaptureURL: data.sessionUrl
        });
      });
    }
  }

  public setUser(id: string, properties: UserVars) {
    this.userDetails = { id, properties, pending: false };
    if (this.isInitialized) {
      FullStory.identify(id);
      FullStory.setUserVars(properties);
    } else {
      this.userDetails.pending = true;
    }
  }
}

export default ScreenCaptureService;
