declare global {
  interface Window {
    grainEnvironment: any;
  }
}

window.grainEnvironment = window.grainEnvironment || {};

interface Environment {
  adminConsoleApiUrl: string;
  isProduction: boolean;
}

const env: Environment = {
  adminConsoleApiUrl: process.env.REACT_APP_ADMIN_CONSOLE_API_URL || window.grainEnvironment.ADMIN_CONSOLE_API_URL,
  isProduction: process.env.NODE_ENV === 'production' || window.grainEnvironment.NODE_ENV === 'production'
};

export default env;
