import {
  AllForwardsResponse,
  BookedForward,
  CreateForwardPeriodicSpreadRequestParams,
  ForwardBookByIdentifierRequest,
  ForwardBookResponse,
  ForwardQuoteResponse,
  ForwardQuoteWithReferenceIdentifier,
  PartnerForwardQuoteRequest
} from '@grain/admin-console-api-types';
import { BaseService } from '@grain/web-utils';
import { EnrichedForward, ListForwardRequest, ManualForwardReportRequest } from '@grain/liquidity-service-types';

export default class ForwardsService extends BaseService {
  private static instance: ForwardsService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new ForwardsService(baseUrl, handleError);
  }

  public static getInstance(): ForwardsService {
    return this.instance;
  }

  async getForwardQuote(params: PartnerForwardQuoteRequest): Promise<ForwardQuoteWithReferenceIdentifier> {
    const { quote }: ForwardQuoteResponse = await this.authenticatedCall({
      url: 'forwards/quote',
      method: 'GET',
      params
    });
    return quote;
  }

  async bookForward(data: ForwardBookByIdentifierRequest): Promise<BookedForward> {
    const { forward }: ForwardBookResponse = await this.authenticatedCall({
      url: 'forwards/book-by-identifier',
      method: 'POST',
      data
    });
    return forward;
  }

  async reportForward(data: ManualForwardReportRequest): Promise<void> {
    await this.authenticatedCall({
      url: 'forwards/report',
      method: 'POST',
      data
    });
  }

  async uploadManualForwardsBooked(file: File): Promise<string> {
    const data = new FormData();
    data.append('file', file);
    const { url } = await this.authenticatedCall<{ url: string }>({
      url: `forwards/upload-manual-forward-updates`,
      data,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return url;
  }

  async getAllForwards(params: ListForwardRequest): Promise<EnrichedForward[]> {
    const { forwards }: AllForwardsResponse = await this.authenticatedCall({
      url: 'forwards',
      method: 'GET',
      params
    });

    return forwards;
  }

  async createOrUpdateForwardPeriodicSpread(data: CreateForwardPeriodicSpreadRequestParams): Promise<void> {
    await this.authenticatedCall({
      url: 'forwards/spreads',
      method: 'POST',
      data
    });
  }

  async uploadForwardPoints(file: File): Promise<string> {
    const data = new FormData();
    data.append('file', file);
    data.append('lastModified', file.lastModified.toString());
    const { url } = await this.authenticatedCall<{ url: string }>({
      url: `forwards/upload-forward-points`,
      data,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return url;
  }
}
