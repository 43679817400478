import { BaseService } from './BaseService';

export default class BaseUsersService extends BaseService {
  protected static instance: BaseUsersService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new BaseUsersService(baseUrl, handleError);
  }

  public static getInstance(): BaseUsersService {
    return this.instance;
  }

  async getUserMeRequest<UserType>(): Promise<UserType> {
    const res: UserResponse<UserType> = await this.authenticatedCall({
      url: 'users/me'
    });
    return res.user;
  }

  async verifyUserEmailRequest<UserType>(userId: string): Promise<UserType> {
    const res: UserResponse<UserType> = await this.authenticatedCall({
      url: `users/${userId}/verify-email`,
      method: 'PUT'
    });
    return res.user;
  }

  async unsubscribeUserRequest(userId: string): Promise<void> {
    await this.authenticatedCall({
      url: `users/${userId}/unsubscribe`,
      method: 'PUT'
    });
  }

  async deleteUser(userToDeleteId: string, partnerId: string): Promise<void> {
    await this.authenticatedCall({
      url: `partners/${partnerId}/users/${userToDeleteId}`,
      method: 'DELETE'
    });
  }

  async inviteUser(payload: InviteUserParams): Promise<void> {
    await this.authenticatedCall({
      url: `partners/${payload.partnerId}/users`,
      data: payload,
      method: 'POST'
    });
  }

  async editUser(payload: EditUserParams): Promise<void> {
    await this.authenticatedCall({
      url: `partners/${payload.partnerId}/users/${payload.id}`,
      data: payload,
      method: 'PUT'
    });
  }
}

export type EditUserParams = {
  id: string;
  partnerId: string;
  firstName: string;
  lastName: string;
  role: string;
};

export type InviteUserParams = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  partnerId: string;
};

export interface UserResponse<UserType> {
  user: UserType;
}
