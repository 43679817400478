import React from 'react';
import './BaseAppLayout.scss';
import useWindowDimensions, { BreakpointType } from './use-window-dimensions';
import classNames from 'classnames';
import { Params, RouteObject, useLocation, useMatches } from 'react-router-dom';

export const removeFromLayout = (match?: Match) => !!match?.handle?.removeFromLayout;
export default function BaseAppLayout(props: BaseAppLayoutProps) {
  const { className, isAuthenticated, sideBar, children } = props;
  const { breakpointType } = useWindowDimensions();
  const matches = useMatches() as Match[];
  const location = useLocation();
  const currentMatch = matches.find((m) => m.pathname === location.pathname);
  const shouldShowSideBar = breakpointType !== BreakpointType.Mobile;

  return (
    <div className={classNames('base-app-layout-container', className)}>
      {((isAuthenticated && !removeFromLayout(currentMatch)) || !removeFromLayout(currentMatch)) && shouldShowSideBar && sideBar}
      {children}
    </div>
  );
}

type BaseAppLayoutProps = {
  className?: string;
  isAuthenticated: boolean;
  sideBar: React.ReactNode;
  children: React.ReactNode;
};

export interface Handle {
  name?: string;
  removeFromLayout?: boolean;
}
export interface Match {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: Handle;
}
export type Route = RouteObject & { handle?: Handle };
