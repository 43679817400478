import React, { useCallback, useEffect, useState } from 'react';
import { AdminConsoleApiCountryConfig } from '@grain/admin-console-api-types';
import Page from '../layout/Page';
import SectionCard from '@grain/web-components/layout/SectionCard';
import { Forbidden2 } from 'iconsax-react';
import colors from '@grain/web-components/styles/colors.scss';
import './CountryRestrictionsPage.scss';
import CountryConfigsTable from './CountryConfigsTable';
import CountryConfigService from '../../services/CountryConfigService';

export default function CountryRestrictionsPage() {
  const [countryConfigs, setCountryConfigs] = useState<AdminConsoleApiCountryConfig[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const refreshCountryConfigs = useCallback(async () => {
    setLoading(true);
    try {
      const countryConfigs = await CountryConfigService.getInstance().getCountryConfigs();
      setCountryConfigs(countryConfigs);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async function () {
      await refreshCountryConfigs();
    })();
  }, [refreshCountryConfigs]);

  return (
    <Page className="country-restrictions-page" title="Country Restrictions" subtitle="Manage Grain's Country Restrictions">
      <SectionCard
        className="country-restrictions-table-section"
        title="Country Restrictions"
        icon={<Forbidden2 />}
        iconBackgroundColor={colors.secondaryYellow}
        loading={isLoading}
      >
        <CountryConfigsTable countryConfigs={countryConfigs} refreshConfigs={refreshCountryConfigs} />
      </SectionCard>
    </Page>
  );
}
