import React from 'react';
import BaseTopBar from '@grain/web-components/layout/BaseTopBar';
import SignOutItem from '@grain/web-components/layout/SignOutItem';
import { useSelector } from 'react-redux';
import { logout, userSelectors } from '../../store/userStore';
import { useAppDispatch } from '../../store/store';

export default function TopBar(props: TopBarProps) {
  const dispatch = useAppDispatch();
  const currentUser = useSelector(userSelectors.getCurrentUser);

  const handleSignOut = () => {
    dispatch(logout());
  };

  return (
    <BaseTopBar {...props} userDetails={currentUser}>
      <SignOutItem onSignOut={handleSignOut} />
    </BaseTopBar>
  );
}

type TopBarProps = {
  title?: string;
  subtitle?: string;
};
