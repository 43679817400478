export enum CompanyStatusAuditType {
  Grain = 'grain',
  External = 'external'
}

export enum CompanyStatusAuditResult {
  NoIssuesFound = 'no-issues-found',
  FoundIssues = 'found-issues',
  Rejected = 'rejected'
}

export interface CompanyStatusAudit {
  result: CompanyStatusAuditResult;
  type: CompanyStatusAuditType;
  description: string;
  externalId?: string;
  provider?: string;
}
