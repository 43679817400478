import StatusBadge from '@grain/web-components/badges/StatusBadge';
import React from 'react';
import { CompanyStatus } from '@grain/admin-console-api-types';
import './CustomerStatusBadge.scss';

export default function CustomerStatusBadge({ customer: { status } }: CustomerStatusBadgeProps) {
  return <StatusBadge className="customer-status-badge-container" status={status} text={mapCompanyStatusToLabel(status)} />;
}

export const mapCompanyStatusToLabel: (status: CompanyStatus) => string = (status) =>
  ({
    [CompanyStatus.Rejected]: 'Rejected',
    [CompanyStatus.Approved]: 'Approved',
    [CompanyStatus.Pending]: 'Pending'
  })[status];

type CustomerStatusBadgeProps = {
  customer: {
    status: CompanyStatus;
  };
};
