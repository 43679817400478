export enum CountryRating {
  Prohibited = 'Prohibited',
  Restricted = 'Restricted',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low'
}

export interface CountryConfig {
  id: string;
  countryCode: string;
  rating: CountryRating;
  manualApproval: boolean;
}
