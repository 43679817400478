import { useQuery } from '@tanstack/react-query';
import { queries } from '../queries';
import { hoursAsMilliseconds } from '@grain/formatters';

export const usePartnerUsers = (partnerId: string) => {
  const {
    data: partnerUsers,
    isLoading,
    refetch
  } = useQuery({
    ...queries.partners.users.list(partnerId),
    staleTime: hoursAsMilliseconds(10000)
  });

  return {
    partnerUsers: partnerUsers || [],
    isLoadingPartnerUsers: isLoading,
    refetchPartnerUsers: refetch
  };
};
