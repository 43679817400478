import { useEffect, useState } from 'react';
import breakpoints from '../styles/breakpoints.scss';

export enum BreakpointType {
  LargeScreen = Number.MAX_SAFE_INTEGER,
  SmallScreen = +breakpoints.smallDesktop,
  Tablet = +breakpoints.tablet,
  Mobile = +breakpoints.mobile
}

export type WindowDimensions = {
  screenWidth: number;
  screenHeight: number;
  breakpointType: BreakpointType;
};

function getBreakpointType(screenWidth: number): BreakpointType {
  if (screenWidth >= BreakpointType.SmallScreen) {
    return BreakpointType.LargeScreen;
  }
  if (screenWidth >= BreakpointType.Tablet) {
    return BreakpointType.SmallScreen;
  }
  if (screenWidth >= BreakpointType.Mobile) {
    return BreakpointType.Tablet;
  }
  return BreakpointType.Mobile;
}

export function getWindowDimensions(): WindowDimensions {
  const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
  return {
    screenWidth,
    screenHeight,
    breakpointType: getBreakpointType(screenWidth)
  };
}

export default function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
