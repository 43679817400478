import { AdminConsoleApiPartnerCurrencyPair, HedgeSettlementType } from '@grain/admin-console-api-types';
import BaseDialog from '@grain/web-components/dialogs/BaseDialog';
import React, { useEffect, useState } from 'react';
import BaseDropdown from '@grain/web-components/forms/BaseDropdown';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { isNil } from 'lodash';
import { Separator } from '@grain/web-components/separator/Separator';
import { convertCurrencyPairToPairString } from '@grain/rate-utils';
import { useEditCurrencyPair } from './hooks';

export const EditCurrencyPairDialog = (props: EditCurrencyPairDialogProps) => {
  const { pair, partnerId, onCancel, onSave, open } = props;
  const [selectedSettlementType, setSelectedSettlementType] = useState<HedgeSettlementType>(pair.settlementType);
  const canSave = !isNil(selectedSettlementType) && selectedSettlementType !== pair.settlementType;
  const { editCurrencyPair, isEditingCurrencyPair } = useEditCurrencyPair();

  const handlePairEditRequest = async () => {
    if (!canSave) {
      return;
    }

    await editCurrencyPair({
      partnerId,
      currencyPairId: pair.id,
      settlementType: selectedSettlementType
    });
    onSave();
  };

  useEffect(() => {
    setSelectedSettlementType(pair.settlementType);
  }, [pair.settlementType]);

  return (
    <BaseDialog open={open} onCancel={onCancel}>
      <div className="w-96 h-80 flex flex-col items-center justify-between gap-4">
        <span className="mr-auto -mt-7 text-light-grey">Edit Currency Pair</span>

        <Separator />

        <BaseDropdown
          labelText="Currency Pair"
          options={[{ text: convertCurrencyPairToPairString(pair), value: pair.id }]}
          value={pair.id}
          hideErrorText
          small
          disabled
        />

        <BaseDropdown
          labelText="Settlement Type"
          value={selectedSettlementType || ''}
          options={Object.entries(HedgeSettlementType)
            .filter(([_key, value]) => value !== HedgeSettlementType.Cycle)
            .map(([key, value]) => ({ text: key, value }))}
          onChange={(e) => setSelectedSettlementType(e.target.value as HedgeSettlementType)}
          hideErrorText
          small
        />

        <CTAButton className="mr-auto" text="Save" disabled={!canSave || isEditingCurrencyPair} onClick={handlePairEditRequest} />
      </div>
    </BaseDialog>
  );
};

type EditCurrencyPairDialogProps = {
  partnerId: string;
  pair: AdminConsoleApiPartnerCurrencyPair;
  open: boolean;
  onSave: () => void;
  onCancel: () => void;
};
