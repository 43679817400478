import parseISO from 'date-fns/parseISO';
import { format as formatDate } from 'date-fns';

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?$/;

export function camelCaseToSentenceCase(value: string) {
  return capitalizeFirstLetter(value.replace(/([A-Z])/g, ' $1').toLowerCase());
}

export function camelCaseToTitleCase(value: string) {
  return capitalizeFirstLetter(value.replace(/([A-Z])/g, ' $1'));
}

export function toSentenceCase(value: string) {
  return capitalizeFirstLetter(value.replace(/-/g, ' '));
}

export function capitalizeFirstLetter(value: string) {
  return value?.length > 0 ? value[0].toUpperCase() + value.slice(1) : value;
}

export function flattenObject(obj: any, options: FlattenObjectOptions = {}, parentKey = '', result: { [key: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const newKey = parentKey ? `${parentKey}${capitalizeFirstLetter(key)}` : key;

    if (obj[key] instanceof Date) {
      result[newKey] = options.dateFormat ? formatDate(obj[key], options.dateFormat) : obj[key].toLocaleDateString();
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      flattenObject(obj[key], options, newKey, result);
    } else {
      result[newKey] = obj[key];
    }
  });

  return result;
}

export type FlattenObjectOptions = {
  dateFormat?: string;
};

export function isIsoDateString(value: any): value is string {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

// Based on: https://stackoverflow.com/a/66238542
export function convertISOStringsToDates(body: any): any {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  Object.entries(body).forEach(([key, value]) => {
    if (isIsoDateString(value)) body[key] = parseISO(value);
    else if (typeof value === 'object') convertISOStringsToDates(value);
  });

  return body;
}

export * from './time';
export * from './email';
