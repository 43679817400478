import { useQuery } from '@tanstack/react-query';
import { queries } from '../../../queries';

export const useWallets = (partnerId: string) => {
  const { data, isLoading, isError } = useQuery(queries.partners.wallets.list(partnerId));

  return {
    wallets: data,
    isLoadingWallets: isLoading,
    isErrorLoadingWallets: isError
  };
};
