import React, { ChangeEvent, useState } from 'react';
import BaseInput from '@grain/web-components/forms/BaseInput';
import {
  AdminConsoleApiPartnerConfig,
  CycleConfig,
  ForwardPurchaseStrategy,
  LiquidityProviderAccountOverride,
  PCancelConfig
} from '@grain/admin-console-api-types';
import EditConfigDialog from '../config/EditConfigDialog';
import BaseDropdown from '@grain/web-components/forms/BaseDropdown';
import './EditPartnerConfigDialog.scss';
import { MAJOR_CURRENCIES } from '@grain/rate-utils';
import { toSentenceCase } from '@grain/formatters';
import { formatLiquidityProviderAccountOptionText } from './utils';
import { useUpdatePartnerConfig } from './hooks/useUpdatePartnerConfig';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import BaseIcon from '@grain/web-components/icons/BaseIcon';

export default function EditPartnerConfigDialog(props: EditPartnerConfigDialogProps) {
  const { partnerId, initialData, onCloseAfterSuccess } = props;
  const { updatePartnerConfig } = useUpdatePartnerConfig();
  const [editedData, setEditedData] = useState<Partial<AdminConsoleApiPartnerConfig>>();
  const booleanInputOptions = [
    { value: 'false', text: 'false' },
    { value: 'true', text: 'true' }
  ];
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const onSubmit = async (newValues: Partial<AdminConsoleApiPartnerConfig>) => {
    await updatePartnerConfig({
      partnerId,
      config: newValues
    });
    setEditedData({});
  };

  return (
    <EditConfigDialog
      triggerComponent={
        <CTAButton
          appearance="secondary-grey"
          size="small"
          leadingIcon={
            <BaseIcon appearance="secondary-grey">
              <EditIcon />
            </BaseIcon>
          }
          text={<span className="font-medium">More Details</span>}
        />
      }
      onSubmit={onSubmit}
      editedData={editedData}
      initialData={initialData}
      onCloseAfterSuccess={onCloseAfterSuccess}
    >
      <BaseDropdown
        name="functionalCurrency"
        labelText="Functional Currency"
        options={MAJOR_CURRENCIES.map((currency) => ({
          value: currency,
          text: currency
        }))}
        defaultValue={initialData.functionalCurrency}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="collateralPercentage"
        labelText="Collateral Percentage"
        defaultValue={initialData.collateralPercentage}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="hedgeProposalMinutesValidDuration"
        labelText="Hedge Proposal Valid Duration in Minutes"
        defaultValue={initialData.hedgeProposalMinutesValidDuration}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="isMultiPartner"
        labelText="Is Multi Partner Enabled"
        options={booleanInputOptions}
        value={editedData?.isMultiPartner || initialData.isMultiPartner}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="maxOwnedPartners"
        labelText="Max Owned Partners"
        defaultValue={initialData.maxOwnedPartners}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="maxExposureAmountUSD"
        labelText="The maximum amount of all active hedges for the partner's organization (in USD)"
        defaultValue={initialData.maxExposureAmountUSD}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="maxSingleHedgeAmountUSD"
        labelText="The maximum amount for a single hedge (in USD)"
        defaultValue={initialData.maxSingleHedgeAmountUSD}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="maxSandboxExposureAmountUSD"
        labelText="The maximum amount of all active hedges for the partner's organization (in USD) for sandbox organization"
        defaultValue={initialData.maxSandboxExposureAmountUSD}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseInput
        name="maxSandboxSingleHedgeAmountUSD"
        labelText="The maximum amount for a single hedge (in USD) for sandbox organization"
        defaultValue={initialData.maxSandboxSingleHedgeAmountUSD}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="cycleConfig"
        labelText="Settlement Cycle"
        options={Object.values(CycleConfig).map((cycleConfig) => ({
          value: cycleConfig,
          text: toSentenceCase(cycleConfig)
        }))}
        defaultValue={initialData.cycleConfig}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="liquidityProviderOverrideAccount"
        labelText="Liquidity Provider Account"
        options={Object.values(LiquidityProviderAccountOverride).map((liquidityProviderAccountOverride) => ({
          value: liquidityProviderAccountOverride,
          text: formatLiquidityProviderAccountOptionText(liquidityProviderAccountOverride)
        }))}
        defaultValue={initialData.liquidityProviderOverrideAccount}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="forwardPurchaseStrategy"
        labelText="Forward Purchase Strategy"
        options={Object.values(ForwardPurchaseStrategy).map((forwardPurchaseStrategy) => ({
          value: forwardPurchaseStrategy,
          text: toSentenceCase(forwardPurchaseStrategy)
        }))}
        defaultValue={initialData.forwardPurchaseStrategy}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="pCancelConfig"
        labelText="P-Cancel Configuration"
        options={Object.values(PCancelConfig).map((pCancelConfig) => ({ value: pCancelConfig, text: toSentenceCase(pCancelConfig) }))}
        defaultValue={initialData.pCancelConfig}
        onChange={onChange}
        hideErrorText
        small
      />
      <BaseDropdown
        name="enableReconciliation"
        labelText="Is Reconciliation Enabled"
        options={booleanInputOptions}
        value={editedData?.enableReconciliation || initialData.enableReconciliation}
        onChange={onChange}
        hideErrorText
        small
      />
    </EditConfigDialog>
  );
}

type EditPartnerConfigDialogProps = {
  partnerId: string;
  initialData: Partial<AdminConsoleApiPartnerConfig>;
  onCloseAfterSuccess?: () => void;
};
