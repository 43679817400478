import BaseTable, { BaseTableColumnDef } from '@grain/web-components/table/BaseTable';
import { AdminConsoleApiOrganization, OrganizationType } from '@grain/admin-console-api-types';
import React, { useCallback, useState } from 'react';
import './PartnerOrganizationsTable.scss';
import TextButton from '@grain/web-components/buttons/TextButton';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import PlatformsService from '../../../services/PlatformsService';

export default function PartnerOrganizationsTable(props: OrganizationsTableProps) {
  const { organizations = [] } = props;
  const [loading, setLoading] = useState<boolean>();

  const syncOrganizationBankAccounts = useCallback(async () => {
    setLoading(true);
    try {
      await PlatformsService.getInstance().syncPartnerBankAccounts(organizations.find((o) => o.type === OrganizationType.Live)!.id);
      notifySuccess('Sync partners bank accounts finished successfully');
    } finally {
      setLoading(false);
    }
  }, [organizations]);

  const columns: BaseTableColumnDef<AdminConsoleApiOrganization>[] = [
    {
      accessorFn: (organization: AdminConsoleApiOrganization) => organization.id,
      id: 'id',
      header: 'ID'
    },
    {
      accessorFn: (organization: AdminConsoleApiOrganization) => organization.name,
      id: 'name',
      header: 'Name'
    },
    {
      accessorFn: (organization: AdminConsoleApiOrganization) => organization.type,
      id: 'type',
      header: 'Type'
    },
    {
      cell: useCallback(
        ({ row: { original: organization } }) =>
          organization.connectedToCurrencyCloud || organization.connectedToTransferMate ? (
            <TextButton text="Sync Bank Accounts" color="blue" underline onClick={syncOrganizationBankAccounts} size="small" />
          ) : null,
        [syncOrganizationBankAccounts]
      ),
      id: 'syncBankAccount',
      header: ''
    }
  ];

  return (
    <div className="partner-organizations-table-container">
      <BaseTable columns={columns} data={organizations} paginated pageSize={5} fixedLayout loading={loading} />
    </div>
  );
}

type OrganizationsTableProps = {
  organizations?: AdminConsoleApiOrganization[];
};
