import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import './Paginator.scss';

export interface PaginatorProps {
  pageIndex: number;
  onPageIndexChange?: (pageIndex: number) => void;
  totalItems?: number;
  totalPages?: number;
}

const FIRST_PAGE_INDEX = 0;

export function Paginator(props: PaginatorProps): ReactElement {
  const { onPageIndexChange, pageIndex, totalItems, totalPages } = props;

  const humanReadablePageIndex = pageIndex + 1;

  const [pageInputValue, setPageInputValue] = useState<number | string>(humanReadablePageIndex);

  useEffect(
    function syncPageInputValueOnPropChange() {
      setPageInputValue(humanReadablePageIndex);
    },
    [humanReadablePageIndex]
  );

  const LAST_PAGE_INDEX = totalPages != null ? totalPages - 1 : undefined;

  const canLast = LAST_PAGE_INDEX && pageIndex < LAST_PAGE_INDEX;
  const canNext = LAST_PAGE_INDEX == null || pageIndex < LAST_PAGE_INDEX;
  const canPrevious = pageIndex > FIRST_PAGE_INDEX;
  const canFirst = canPrevious;
  const canInput = totalPages !== 0;

  const changePageIndex = (pageIndex: number) => {
    onPageIndexChange?.(pageIndex);
    setPageInputValue(pageIndex + 1);
  };

  const handlePageInputBlur = () => {
    if (String(humanReadablePageIndex) !== String(pageInputValue)) {
      setPageInputValue(humanReadablePageIndex);
    }
  };

  const handlePageInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: rawPageInputValue } = event.target;

    setPageInputValue(rawPageInputValue);

    const pageInputValue = Number(rawPageInputValue);

    if (!Number.isInteger(pageInputValue)) {
      return;
    }

    const pageIndex = pageInputValue - 1;

    if (pageIndex >= FIRST_PAGE_INDEX && (LAST_PAGE_INDEX == null || pageIndex <= LAST_PAGE_INDEX)) {
      onPageIndexChange?.(pageIndex);
    }
  };

  return (
    <div className="paginator-container">
      <div className="paginator-controls">
        <button
          className="paginator-button"
          data-testid="first-page"
          disabled={!canFirst}
          onClick={() => changePageIndex(FIRST_PAGE_INDEX)}
          type="button"
        >
          First
        </button>
        <button
          className="paginator-button"
          data-testid="previous-page"
          disabled={!canPrevious}
          onClick={() => changePageIndex(pageIndex - 1)}
          type="button"
        >
          <ArrowLeft2 />
        </button>
        <input
          className="paginator-page-input"
          data-testid="page-number"
          disabled={!canInput}
          inputMode="numeric"
          max={totalPages}
          min={FIRST_PAGE_INDEX}
          onBlur={handlePageInputBlur}
          onChange={handlePageInputChange}
          style={{
            width: `${totalPages != null ? (totalPages.toString().length + 1) * 8 : 28}px`
          }}
          type="text"
          value={pageInputValue}
        />
        <button
          className="paginator-button"
          data-testid="next-page"
          disabled={!canNext}
          onClick={() => changePageIndex(pageIndex + 1)}
          type="button"
        >
          <ArrowRight2 />
        </button>
        <button
          className="paginator-button"
          data-testid="last-page"
          disabled={!canLast}
          // the button is enabled only when LAST_PAGE_INDEX exists.
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onClick={() => changePageIndex(LAST_PAGE_INDEX!)}
          type="button"
        >
          Last
        </button>
      </div>
      {totalItems != null ? <div className="paginator-total-items">{`${totalItems} results`}</div> : null}
    </div>
  );
}
