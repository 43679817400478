import { useMutation, useQueryClient } from '@tanstack/react-query';
import PartnersService from 'services/PartnersService';
import { queries } from '../../../../queries';
import { notifySuccess } from '@grain/web-components/notifications/toasters';

export const useEnableApiKeys = () => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, isSuccess, mutateAsync } = useMutation<void, Error, string>({
    mutationFn: (partnerId) => PartnersService.getInstance().enableLiveApiKeysForPartner(partnerId),
    onSuccess: async () => {
      notifySuccess('API Keys enabled successfully');
      await queryClient.refetchQueries(queries.partner.detail._def);
    },
    retry: 1
  });

  return {
    enabledApiKeysSuccessfully: isSuccess,
    failedToEnableApiKeys: isError,
    enableApiKeys: mutateAsync,
    isEnablingApiKeys: isLoading,
    enableApiKeysError: error
  };
};
