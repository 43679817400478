import * as _XLSX from 'xlsx';
import { ParsingOptions, WorkBook } from 'xlsx';
import { useWorker } from '@koale/useworker';
import { XLSX_READ_OPTIONS } from '@grain/excel-parser';
import { useCallback } from 'react';

// Bind variables so that they can be read by service worker
const XLSX = _XLSX;

const parseArrayBuffer = (ab: ArrayBuffer, options: ParsingOptions): ParseExcelResponse => {
  try {
    return ((globalThis as any).XLSX as typeof XLSX).read(ab, options);
  } catch (e) {
    return e instanceof Error ? e : new Error(e as any);
  }
};

export default function useExcelParser() {
  const [parseWorker, { kill: killWorker }] = useWorker(parseArrayBuffer, {
    remoteDependencies: ['https://cdn.sheetjs.com/xlsx-0.18.5/package/dist/xlsx.full.min.js']
  });

  const wrappedParseWorker = useCallback((ab: ArrayBuffer) => parseWorker(ab, XLSX_READ_OPTIONS), [parseWorker]);
  return [wrappedParseWorker, killWorker];
}

export type ParseExcelResponse = WorkBook | Error;
