import { HedgesCreatedAfterReportParams } from '@grain/admin-console-api-types';
import { BaseService } from '@grain/web-utils';
import { ReportFile } from '../components/reports/ReportsTable';
import { notifyError } from '@grain/web-components/notifications/toasters';

export default class ReportsService extends BaseService {
  private static instance: ReportsService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new ReportsService(baseUrl, handleError);
  }

  public static getInstance(): ReportsService {
    return this.instance;
  }

  async generateHedgesCreatedAfterReportsRequest(data: HedgesCreatedAfterReportParams): Promise<void> {
    return this.authenticatedCall({
      url: 'reports/generate-hedges-created-after',
      method: 'POST',
      data
    });
  }

  async generateUpcomingHedgesReport(): Promise<void> {
    return this.authenticatedCall({
      url: 'reports/generate-upcoming-hedges',
      method: 'POST'
    });
  }

  async getReportsRequest(): Promise<{ reports: ReportFile[] }> {
    return this.authenticatedCall({
      url: 'reports',
      method: 'GET'
    });
  }

  async downloadReportFile(reportFileName: string): Promise<void> {
    try {
      await this.downloadableFileCall({
        url: `reports/report-file`,
        method: 'GET',
        params: { reportFileName }
      });
    } catch (e) {
      notifyError('Report file could not be downloaded');
    }
  }
}
