import React from 'react';
import { ArrowRight2 } from 'iconsax-react';
import './PageBreadcrumbs.scss';
import { useMatches } from 'react-router-dom';
import TextButton from '../buttons/TextButton';

type RouteHandle = { name: string };

export default function PageBreadcrumbs() {
  const matches = useMatches();
  const relevantMatches = matches.filter((m) => m.handle && !!(m.handle as RouteHandle).name);
  const breadcrumbs = relevantMatches.map((match) => ({
    name: (match.handle as RouteHandle).name,
    link: match.pathname
  }));

  return (
    <div className="page-breadcrumbs-container">
      {breadcrumbs.map((breadcrumb, idx) => (
        <div className="breadcrumb" key={breadcrumb.name}>
          {idx !== 0 && <ArrowRight2 className="arrow-icon" size={12} />}
          <TextButton text={breadcrumb.name} link={breadcrumb.link} disabled={idx === breadcrumbs.length - 1} />
        </div>
      ))}
    </div>
  );
}
