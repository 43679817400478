import React from 'react';
import './BaseAutoComplete.scss';
import BaseField from './BaseField';
import { Autocomplete } from '@mui/material';
import BaseInput, { BaseInputProps } from './BaseInput';
import classNames from 'classnames';
import { BaseDropdownOption } from './BaseDropdown';
import { isNil } from '../utils/objects';

export function baseAutoCompleteOptions<T>(objects: T[] = [], fieldName: string): BaseAutoCompleteOption[] {
  const getNestedValue = (object: any, path: string): any => path.split('.').reduce((obj, key) => obj && obj[key], object);

  const objectsWithUniqueValues = [
    ...new Set(
      objects.filter((object) => !isNil(getNestedValue(object, fieldName))).map((object) => `${getNestedValue(object, fieldName)}`)
    )
  ];

  return objectsWithUniqueValues.map((fieldNameValue) => ({
    value: fieldNameValue,
    text: fieldNameValue
  }));
}

export default function BaseAutoComplete(props: BaseAutoCompleteProps) {
  const {
    name,
    labelText,
    errorMessage,
    className,
    required,
    options,
    hideErrorText,
    shadow,
    value,
    onValueChange,
    onOptionHover,
    highlightWhenEmpty,
    disabled,
    freeSolo,
    placeholder,
    ...inputProps
  } = props;

  const onChange = (_: any, newText: string) => {
    onValueChange(name, options.find((o) => o.text === newText)?.value || newText);
  };

  const selectedValue = freeSolo ? ((value || '') as string) : options.find((o) => o.value === value)?.text || '';

  const textOptions = options.map((o) => o.text);
  const selectedOption = options.find((o) => o.value === value);
  // A Hack to remove a warning autocomplete throws - https://github.com/mui/material-ui/issues/18514
  if (!selectedOption) {
    textOptions.push('');
  }

  const highlighted = highlightWhenEmpty && !selectedValue;

  const renderOption = ({ onMouseOver, className, onClick, ...props }: any, o: string) => {
    const { disabledMessage } = options.find(({ text }) => text === o) || {};

    return (
      <li
        className={classNames(className, { disabled: disabledMessage })}
        title={disabledMessage}
        onClick={disabledMessage ? undefined : onClick}
        onMouseOver={(...args) => {
          onMouseOver?.(...args);
          onOptionHover?.(o);
        }}
        {...props}
      >
        {o}
      </li>
    );
  };

  return (
    <div className={classNames('base-auto-complete-container', { highlighted })}>
      <BaseField
        name={name}
        labelText={labelText}
        errorMessage={errorMessage}
        required={required}
        shadow={shadow}
        hideErrorText={hideErrorText}
      >
        <Autocomplete
          inputValue={freeSolo ? selectedValue : undefined}
          freeSolo={freeSolo}
          disablePortal
          disableClearable
          options={textOptions}
          value={selectedValue}
          filterSelectedOptions={!selectedValue}
          renderInput={(params) => (
            <BaseInput
              hideErrorText
              internalTextFieldParams={params}
              errorMessage={errorMessage}
              placeholder={placeholder}
              {...inputProps}
            />
          )}
          renderOption={renderOption}
          className={classNames('field-element base-auto-complete', className)}
          onChange={onChange}
          onInputChange={freeSolo ? onChange : undefined}
          onClose={() => onOptionHover?.()}
          disabled={disabled}
        />
      </BaseField>
    </div>
  );
}

export type BaseAutoCompleteOption = BaseDropdownOption & {
  /**
   * If given, the option will be disabled and an alt with this message will be displayed.
   * If not given (by default), the option is selectable
   */
  disabledMessage?: string;
};

interface BaseAutoCompleteProps extends BaseInputProps {
  name: string;
  options: BaseAutoCompleteOption[];
  defaultValue?: string;
  onValueChange: (name: string, value: string) => void;
  onOptionHover?: (option?: string) => void;
  highlightWhenEmpty?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  freeSolo?: boolean;
}
