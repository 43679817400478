import React, { PropsWithChildren, ReactElement } from 'react';
import { SeparatorRow } from '../separator-row/SeparatorRow';

export type ButtonRowProps = PropsWithChildren<object>;

export function ButtonRow(props: ButtonRowProps): ReactElement {
  return (
    <SeparatorRow className="button-row" separatorHeight="20px">
      {props.children}
    </SeparatorRow>
  );
}
