import React, { useEffect } from 'react';
import BaseAppLayout from '@grain/web-components/layout/BaseAppLayout';
import MenuItemsList from './MenuItemsList';
import BaseSideBar from '@grain/web-components/layout/BaseSideBar';
import { useSelector } from 'react-redux';
import { getUser, userSelectors } from '../../store/userStore';
import './AppLayout.scss';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch } from '../../store/store';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { TooltipProvider } from '@grain/web-components/tooltip/Tooltip';

export default function AppLayout() {
  const isAuthenticated = useSelector(userSelectors.isAuthenticated);

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async function () {
      if (isAuthenticated) {
        await dispatch(getUser());
      }
    })();
  }, [isAuthenticated, dispatch]);

  return (
    <BaseAppLayout className="app-layout-container" isAuthenticated={isAuthenticated} sideBar={<BaseSideBar items={<MenuItemsList />} />}>
      <TooltipProvider>
        <Outlet />
      </TooltipProvider>

      <ToastContainer />
    </BaseAppLayout>
  );
}
