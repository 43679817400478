import { AdminConsoleApiOrganization, LiquidityProviderAccountOverride, OrganizationType } from '@grain/admin-console-api-types';
import { toSentenceCase } from '@grain/formatters';

export function isConnectedToBankingVendor(organizations: AdminConsoleApiOrganization[]) {
  return organizations.some(({ connectedToTransferMate }) => connectedToTransferMate);
}

export function hasLiveApiKeys(organizations: AdminConsoleApiOrganization[]) {
  const liveOrg = organizations.find((org) => org.type === OrganizationType.Live);
  return !!liveOrg?.hasApiKeys;
}

export function formatLiquidityProviderAccountOptionText(liquidityProviderAccountOverride: LiquidityProviderAccountOverride): string {
  return liquidityProviderAccountOverride === LiquidityProviderAccountOverride.None
    ? 'Grain'
    : toSentenceCase(liquidityProviderAccountOverride);
}
