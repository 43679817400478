import BaseAutoComplete, { baseAutoCompleteOptions } from '../forms/BaseAutoComplete';
import FiltersForm from './FiltersForm';
import React, { Dispatch, SetStateAction } from 'react';

interface ManagedFiltersFormProps {
  options: Array<Record<string, any>>;
  filters: { label: string; key: string }[];
  filtersData: Record<string, any>;
  setFiltersData: Dispatch<SetStateAction<Record<string, any>>>;
  onFilterClicked: (initial?: boolean, filters?: Record<string, any>) => Promise<void> | void;
}

const ManagedFiltersForm = (props: ManagedFiltersFormProps) => {
  const { options, filters, filtersData, setFiltersData, onFilterClicked } = props;

  const onChangeBaseAutoComplete = (dropDownName: string, dropDownNameSelectedValue: string) => {
    setFiltersData({
      ...filtersData,
      [dropDownName]: dropDownNameSelectedValue
    });
  };

  const reset = async () => {
    setFiltersData({});
    await onFilterClicked();
  };

  const filter = async () => {
    await onFilterClicked(false, filtersData);
  };

  const resetDisabled = Object.values(filtersData).filter((value) => !!value).length === 0;

  return (
    <FiltersForm onFilter={filter} onReset={reset} resetDisabled={resetDisabled}>
      {filters.map(({ label, key }) => (
        <BaseAutoComplete
          labelText={label}
          name={key}
          value={filtersData[key]}
          options={baseAutoCompleteOptions<Record<string, any>>(options, key)}
          onValueChange={onChangeBaseAutoComplete}
          hideErrorText
          small
          key={key}
        />
      ))}
    </FiltersForm>
  );
};

export default ManagedFiltersForm;
