import { GetHedgesRequestQueryParams, Hedge } from '@grain/admin-console-api-types';
import { BaseService } from '@grain/web-utils';

export const DEFAULT_PAGE_SIZE = 25;
export default class HedgesService extends BaseService {
  private static instance: HedgesService;

  public static initialize(baseUrl: string, handleError: (err: Error) => void): void {
    this.instance = new HedgesService(baseUrl, handleError);
  }

  public static getInstance(): HedgesService {
    return this.instance;
  }

  async getHedgesRequest(params: GetHedgesRequestQueryParams = {}): Promise<{ hedges: Hedge[]; totalCount: number }> {
    const res: HedgesResponse = await this.authenticatedCall({
      url: 'hedges',
      method: 'GET',
      params: { ...params, perPage: DEFAULT_PAGE_SIZE }
    });

    return { hedges: res.hedges, totalCount: res.totalCount };
  }
}

export interface HedgesResponse {
  hedges: Hedge[];
  totalCount: number;
}
