import React, { ChangeEvent, useState } from 'react';
import classNames from 'classnames';
import MultiStepsDialog from '@grain/web-components/dialogs/MultiStepsDialog';
import DialogBaseStep from '@grain/web-components/dialogs/DialogBaseStep';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { ImportCurve } from 'iconsax-react';
import BaseIcon from '@grain/web-components/icons/BaseIcon';
import CurrencyAmountInput from '@grain/web-components/CurrencyAmountInput/CurrencyAmountInput';
import DialogSuccessStep from '@grain/web-components/dialogs/DialogSuccessStep';
import { asAmount } from '@grain/web-components/utils/formatting';
import './PennyTestDialog.scss';
import { usePaymentBeneficiaries } from '../../hooks';
import { PaymentBeneficiaryType } from '@grain/core-types';
import { KeyValuePair } from '@grain/web-components/key-value-pair/KeyValuePair';

export default function PennyTestDialog(props: PennyTestDialogProps) {
  const { paymentBeneficiaries, isLoadingPaymentBeneficiaries } = usePaymentBeneficiaries(props.partnerId, [props.paymentBeneficiaryType]);
  const uniqueCurrencies = [...new Set(paymentBeneficiaries?.map((beneficiary) => beneficiary.currency))];

  const [pennyTestAmount, setPennyTestAmount] = useState<number>();
  const [pennyTestCurrency, setPennyTestCurrency] = useState(uniqueCurrencies[0]);
  const paymentBeneficiaryName = paymentBeneficiaries?.find((pb) => pb.currency === pennyTestCurrency)?.nickname;
  const isExecutionButtonDisabled = !pennyTestAmount || props.isExecuting;

  const onPennyTestAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPennyTestAmount(value === '' ? undefined : +value);
  };

  const onPennyTestConfirm = async () => {
    await props.onConfirm(props.partnerId, pennyTestAmount!, pennyTestCurrency);
  };

  const clearDialog = () => {
    setPennyTestAmount(undefined);
    props.onClose();
  };

  let dialogContent;
  if (!props.isSuccess && !props.isFailure) {
    dialogContent = (
      <DialogBaseStep
        className="penny-test-base-dialog"
        formId="penny-test-dialog"
        button={
          <CTAButton
            leadingIcon={
              <BaseIcon appearance={isExecutionButtonDisabled ? 'grey' : 'light'}>
                <ImportCurve />
              </BaseIcon>
            }
            text="Execute"
            loading={props.isExecuting}
            disabled={isExecutionButtonDisabled}
            onClick={onPennyTestConfirm}
          />
        }
      >
        <div className="text-primary text-lg mb-9">Select the amount you want to transfer</div>
        <CurrencyAmountInput
          name="amount"
          currencyName="currency"
          labelText="Amount"
          value={pennyTestAmount || ''}
          currency={pennyTestCurrency}
          currenciesOptions={uniqueCurrencies}
          disabled={props.isExecuting}
          onChange={onPennyTestAmountChange}
          onCurrencyChange={(_, currency) => setPennyTestCurrency(currency)}
        />

        <KeyValuePair className="mt-10" title="Beneficiary Account" value={paymentBeneficiaryName || ''} />
      </DialogBaseStep>
    );
  } else if (props.isSuccess) {
    dialogContent = (
      <DialogSuccessStep
        title="Transaction in Process"
        summary={[
          { text: 'Beneficiary Account', value: paymentBeneficiaryName! },
          {
            text: 'Amount',
            value: `${asAmount(pennyTestAmount!)} ${pennyTestCurrency} `
          }
        ]}
        imageUrl="/images/web-components/withdrawal_success.gif"
      />
    );
  }

  return (
    <MultiStepsDialog
      open={props.open}
      contentClassName={classNames('penny-test-base-dialog-content', {
        error: !!props.error
      })}
      triggerAlt={!paymentBeneficiaries?.length ? 'Payment beneficiary is not configured' : ''}
      leftHeader="Penny test"
      clearDialog={clearDialog}
      loading={isLoadingPaymentBeneficiaries}
      error={props.error?.message}
      onCancel={clearDialog}
      onClose={clearDialog}
    >
      {dialogContent}
    </MultiStepsDialog>
  );
}

type PennyTestDialogProps = {
  open: boolean;
  partnerId: string;
  paymentBeneficiaryType: PaymentBeneficiaryType;
  isExecuting: boolean;
  isSuccess: boolean;
  isFailure: boolean;
  error?: Error | null;
  onConfirm: (partnerId: string, amount: number, currency: string) => Promise<void>;
  onClose: () => void;
};
