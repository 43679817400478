import React, { ChangeEvent, useEffect, useState } from 'react';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import BaseInput from '@grain/web-components/forms/BaseInput';
import FormDialog from '@grain/web-components/dialogs/FormDialog';
import PartnersService from '../../../services/PartnersService';
import { AdminConsoleApiForwardPercentageByCountry } from '@grain/admin-console-api-types';
import BaseIcon from '@grain/web-components/icons/BaseIcon';
import { ReactComponent as EditIcon } from 'icons/edit.svg';

export default function ForwardPercentageByCountryDialog({
  partnerId,
  onForwardPercentageChanged,
  forwardPercentage
}: ForwardPercentageByCountryDialogProps) {
  const [newData, setNewData] = useState<ForwardPercentageByCountry>({});

  useEffect(() => {
    if (forwardPercentage) {
      setNewData({
        country: forwardPercentage.country,
        percentage: forwardPercentage.percentage
      });
    }
  }, [forwardPercentage]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  const validate = () => {
    if (newData?.country?.length !== 2) {
      return "Country should displayed by it's 2 digit code";
    }

    if (Number.isNaN(Number(newData.percentage!))) {
      return 'Percentage must be a valid number';
    }

    if (newData?.percentage && (newData.percentage < 0 || newData.percentage > 100)) {
      return 'Percentage values should be between 0-100';
    }

    return '';
  };

  const onSubmit = async () => {
    if (forwardPercentage) {
      await PartnersService.getInstance().updateForwardPercentageCountry(partnerId, forwardPercentage.id, newData.percentage!);
    } else {
      await PartnersService.getInstance().createForwardPercentageCountry(partnerId, newData!);
    }

    setNewData({});
    await onForwardPercentageChanged();
  };

  const onClose = () => {
    setNewData({});
  };

  const canSubmit = !!newData.country && !!newData.percentage;
  const mainProps = {
    onChange,
    required: true,
    hideErrorText: true,
    small: true
  };

  return (
    <FormDialog
      title={`${forwardPercentage ? 'Update' : 'Create'} Forward Percentage By Country`}
      trigger={
        forwardPercentage ? (
          <BaseIcon appearance="grey">
            <EditIcon />
          </BaseIcon>
        ) : (
          <CTAButton text="Add Forward Percentage" size="small" />
        )
      }
      canSubmit={canSubmit}
      validate={validate}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <BaseInput name="country" labelText="Country" value={newData.country || ''} disabled={!!forwardPercentage} {...mainProps} />
      <BaseInput name="percentage" labelText="Percentage" value={newData.percentage || ''} {...mainProps} />
    </FormDialog>
  );
}

type ForwardPercentageByCountryDialogProps = {
  partnerId: string;
  forwardPercentage?: AdminConsoleApiForwardPercentageByCountry;
  onForwardPercentageChanged: () => void;
};

type ForwardPercentageByCountry = {
  country?: string;
  percentage?: number;
};
