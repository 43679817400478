import { PaymentType } from '@grain/core-types';

export function paymentTypeDisplayName(paymentType?: PaymentType): string {
  switch (paymentType) {
    case PaymentType.Swift:
      return 'Swift';
    case PaymentType.ACH:
      return 'ACH';
    case PaymentType.SEPA:
      return 'SEPA';
    case PaymentType.Wire:
      return 'Wire';
    case PaymentType.EFT:
      return 'EFT';
    case PaymentType.FPS:
      return 'FPS';
    default:
      return 'Unknown';
  }
}
