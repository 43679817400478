import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import '../forms/BaseInput.scss';
import BaseInput, { BaseInputProps } from '../forms/BaseInput';
import CurrencyFlagSelector from '../CurrencyFlagSelector/CurrencyFlagSelector';
import './CurrencyAmountInput.scss';

export default function CurrencyAmountInput(props: CurrencyAmountInputProps) {
  const {
    name,
    labelText,
    value,
    onChange,
    validationError,
    currenciesOptions,
    currency,
    onCurrencyChange,
    helpText,
    currencyName,
    disableCurrencyChange,
    ...restInputProps
  } = props;

  return (
    <BaseInput
      className="currency-amount-input"
      name={name}
      labelText={labelText}
      value={value}
      onChange={onChange}
      innerLabel
      hideErrorText
      type="number"
      {...restInputProps}
      endAdornment={
        <InputAdornment position="start">
          <div className={`help-text ${validationError ? 'error' : ''}`}>{helpText}</div>
          <CurrencyFlagSelector
            name={currencyName}
            currency={currency}
            options={currenciesOptions}
            onCurrencyChange={onCurrencyChange}
            disabled={disableCurrencyChange}
          />
        </InputAdornment>
      }
    />
  );
}

export interface CurrencyAmountInputProps extends BaseInputProps {
  currencyName: string;
  onCurrencyChange?: (name: string, value: string) => void;
  currency: string;
  currenciesOptions: string[];
  helpText?: string;
  validationError?: boolean;
  disableCurrencyChange?: boolean;
}
